import React from 'react';
import { Navigate } from 'react-router-dom';
// import { useAppSelector } from '@app/hooks/reduxHooks';
import { WithChildrenProps } from '@app/types/generalTypes';
import { readExpiresToken, readToken } from '@app/services/localStorage.service';

const RequireAuth: React.FC<WithChildrenProps> = ({ children }) => {
  // const token = useAppSelector((state) => state.auth.token);
  const token = readToken();
  const expiresToken = readExpiresToken();
  const expired = () => {
    // if (!token){
    //   return true;
    // }else{
    //   if(new Date().getTime() > new Date(expiresToken).getTime()){
    //     console.log("Token Expired!");
    //   }
    //   return new Date().getTime() > new Date(expiresToken).getTime();
    // }
    return !token;
  };

  return !expired() ? <>{children}</> : <Navigate to="/auth/login" replace />;
};

export default RequireAuth;
