import { graphic } from 'echarts';
import { BASE_COLORS } from '../constants';
import { ITheme } from '../types';
import { shadeColor } from '@app/utils/themeUtils';

const chartColors = {
  chartTooltipLabel: '#6a7985',
  chartColor1: '#339CFD',
  chartColor1Tint: '#339CFD', // update
  chartColor2: '#dc88f5',
  chartColor2Tint: '#dc88f5', // update
  chartColor3: '#FFB765',
  chartColor3Tint: '#FFB765', // update
  chartColor4: '#4F7D56',
  chartColor4Tint: '#4F7D56', // update
  chartColor5: '#ff3d71',
  chartColor5Tint: '#ff3d71', // update
  chartColor6: '#4B5B73',
  chartColor7: '#8F547A',
  chartColor8: '#753F3F',
  chartColor9: '#8D704F',
  chartColor10: '#A79F8E',
  chartPrimaryGradient: new graphic.LinearGradient(0, 0, 0, 1, [
    {
      offset: 0,
      color: 'rgba(51, 156, 253, 0.35)',
    },
    {
      offset: 1,
      color: 'rgba(51, 156, 253, 0)',
    },
  ]),
  chartSecondaryGradient: new graphic.LinearGradient(0, 0, 0, 1, [
    {
      offset: 0,
      color: 'rgba(255, 82, 82, 0.35)',
    },
    {
      offset: 1,
      color: 'rgba(255, 82, 82, 0)',
    },
  ]),
  chartSecondaryGradientSpecular: new graphic.LinearGradient(0, 0, 0, 1, [
    {
      offset: 0,
      color: 'rgba(255, 255, 255, 0)',
    },
    {
      offset: 1,
      color: 'rgba(255, 82, 82, 0.5)',
    },
  ]),
};

export const darkColorsTheme: ITheme = {
  tcpColor: '#8df05b',
  udpColor: '#eb7054',
  primary: '#339CFD',
  primary1: '#7568f6',
  primaryGradient: 'linear-gradient(211.49deg, #dc88f5 15.89%, #339CFD 48.97%)',
  light: '#696969',
  secondary: '#0072DD',
  error: '#FF5252',
  warning: '#FFB765',
  success: '#57D682',
  background: '#25284B',
  secondaryBackground: '#1c2137',
  secondaryBackgroundSelected: shadeColor('#1c2137', -5),
  additionalBackground: '#1D203E',
  additionalBackgroundCardInside: '#2b2f52',
  tagRavenloopColor: '#3c4068',
  advancedSearchColor: '#770d75',
  markerActualGeolocationWorldMap: '#00FF7F',
  markerGeolocationHistoryWorldMap: '#FAC898',
  collapseBackground: '#1D203E',
  timelineBackground: '#f5f5f5',
  siderBackground: '#121430',
  spinnerBase: '#339CFD',
  scroll: '#797C9A',
  border: '#ffffff',
  borderTable: '#797C9A42',
  borderCard: '#797C9A42',
  borderNft: '#797C9A',
  ravenloopColor: '#0BA1DC',
  textMain: '#ffffff',
  textMainHint: 'rgba(255, 255, 255, 0.45)',
  textLight: '#9A9B9F',
  textSuperLight: '#444',
  textSecondary: '#ffffff',
  textDark: '#404040',
  textNftLight: '#797C9A',
  textSiderPrimary: '#339CFD',
  dividerSiderSecondary: '#797C9A',
  // textSiderSecondary: '#797C9A',
  textSiderSecondary: '#ffffff',
  subText: '#a9a9a9',
  disabledColor: '#9A9B9F',
  shadow: 'rgba(0, 0, 0, 0.07)',
  boxShadow: 'none',
  boxShadowHover: 'none',
  boxShadowNft: '0px 16px 24px rgba(0, 0, 0, 0.06), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04)',
  boxShadowNftSecondary:
    '0px 10px 20px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04)',
  dashboardMapBackground: '#25284b',
  dashboardMapCircleColor: '#ACAEC1',
  dashboardMapControlDisabledBackground: '#7e7e7e',
  notificationSuccess: '#EFFFF4',
  notificationPrimary: '#D7EBFF',
  notificationWarning: '#FFF4E7',
  notificationError: '#FFE2E2',
  heading: BASE_COLORS.white,
  borderBase: '#a9a9a9',
  disabledBg: '#1c2137',
  layoutBodyBg: '#1e2142',
  layoutHeaderBg: '#1e2142',
  layoutSiderBg: '#121430',
  inputPlaceholder: 'rgba(255, 255, 255, 0.5)',
  itemHoverBg: '#1c2137',
  backgroundColorBase: '#1c2137',
  avatarBg: '#1c2137',
  alertTextColor: '#000',
  breadcrumb: '#a9a9a9',
  icon: '#a9a9a9',
  iconHover: '#ffffff',
  tagLinksGraph: '#ffffff',
  tagStatisticsGreen: '#80ef80',
  bigNumberGreen: '#05d69e',
  bigNumberYellow: '#ada968',
  tagStatisticsRed: '#e76a6a',
  bigNumberRed: '#ef486f',
  markerMailRpost: 'rgba(0,184,232,0.8)',
  invert: 1,
  tcpGraphColor: '#51cd51',
  udpGraphColor: '#4c75c2',
  purpleScoreColor: '#d66bd1',
  redScoreColor: '#ef5c5c',
  orangeScoreColor: '#efa94a',
  yellowScoreColor: '#ebff82',
  greenScoreColor: '#8aea8f',
  blueScoreColor: '#2194e7',
  grayScoreColor: '#828688',
  analystReportColor: '#EBEFFE',
  defaultReportColor: '#2b2f52',

  // leafletTileFilter: 'brightness(0.6) invert(3) contrast(3) hue-rotate(200deg) saturate(0.3) brightness(0.7)',
  // leafletTileFilter: 'hue-rotate(344deg) brightness(1.5) contrast(1.4)', //para opencti map
  leafletTileFilter:
    'brightness(0.6) invert(3) contrast(5) sepia(50%) hue-rotate(200deg) saturate(0.5) brightness(0.7)',
  finished: '#00a6e2',
  scheduled: '#e18cd7',
  uploaded: '#02C59B',
  pending: '#02C59B',
  audited: '#36bc14',
  auditing: '#f5bb51',
  stopped: '#a45454',
  killed: '#a24f81',
  titleWrapperLine: '#d2d2d2',
  tagNeonColor: '#000000',
  tagNeonBorderColor: '#ffffff',
  tagNeonBgColor: '#000000',
  ...chartColors,
  disable: '',
};

export const antDarkColorsTheme = {
  successBg: '#e6fff2',
  successBorder: '#79fcc4',
};
