import { FONT_SIZE } from '@app/styles/themes/constants';
import styled from 'styled-components';

export const HelpLayout = styled.div`
  height: 100%;

  display: flex;
  flex-direction: row;
  gap: 1rem;

  p {
    font-size: ${FONT_SIZE.md};
  }

  h1 {
    font-size: 3rem;
    text-align: center;

    margin: 2rem 0;
  }

  h2 {
    font-size: ${FONT_SIZE.xxxl};
  }
  h3 {
    font-size: ${FONT_SIZE.xxl};
  }
  h4 {
    font-size: ${FONT_SIZE.xl};
  }
  h5 {
    font-size: ${FONT_SIZE.lg};
  }

  li {
    font-size: ${FONT_SIZE.md};

    margin-bottom: 0.5rem;
  }

  ul > li > ul {
    padding-left: 2rem;
  }

  blockquote {
    border-left: 3px solid var(--primary-color);
    padding-left: 1rem;

    ul {
      padding: 0 1rem;
    }
  }

  .icon {
    font-size: ${FONT_SIZE.lg};
    margin-right: 0.5rem;
    vertical-align: text-top;
  }

  .image-block-wrapper {
    display: block;
  }

  .image {
    width: inherit;
    max-height: 30rem;
    box-shadow: 8px 8px 8px -4px var(--secondary-color);

    margin: 0 auto 1rem;

    display: block;
  }

  .no-dots {
    list-style: none;
  }

  .highlighted {
    color: var(--primary-color);

    &--purple {
      color: var(--risk-color-very-high);
    }
    &--red {
      color: var(--risk-color-high);
    }
    &--green {
      color: var(--risk-color-low);
    }
    &--yellow {
      color: var(--risk-color-medium);
    }
    &--orange {
      color: var(--risk-color-posible);
    }
    &--blue {
      color: var(--risk-color-info);
    }
    &--completed {
      color: var(--status-completed-blue);
    }
    &--uploaded {
      color: var(--status-uploaded-purple);
    }
    &--analyzed {
      color: var(--status-analyzed-green);
    }
    &--analyzing {
      color: var(--status-analyzing-yellow);
    }
    &--not-processed {
      color: var(--status-not-processed-gray);
    }
    &--error {
      color: var(--status-error-red);
    }
  }
`;
