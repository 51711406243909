import HeaderContext from '@app/hooks/HeaderContext';
import { useContext, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import HelpContent from './HelpContent/HelpContent';
import HelpTOC from './HelpTOC';
import { HelpLayout } from './Help.styles';

const Help = () => {
  const { t } = useTranslation();
  const textContainerRef = useRef<HTMLDivElement | null>(null);
  const { setHeaderBreadcrumb, headerKeyReset } = useContext(HeaderContext);

  useEffect(() => {
    setHeaderBreadcrumb([{ label: t('help.breadcrumb'), key: 'help', href: '/help' }]);
  }, [headerKeyReset, setHeaderBreadcrumb, t]);
  return (
    <HelpLayout>
      <HelpTOC textContainerRef={textContainerRef} />
      <HelpContent ref={textContainerRef} />
    </HelpLayout>
  );
};

export default Help;
