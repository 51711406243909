import React, { Suspense } from 'react';
import { LoadingRavenloop } from '@app/components/common/Loadings/LoadingRavenloop';
import { checkAccessApi } from '@app/api/access';
import { RoleType } from '@app/interfaces/interfaces';
import { useQueryHandler } from '@app/api/hooks/useQueryHandler';
import { useQuery } from '@tanstack/react-query';

type ReturnType<T> = (props: T) => JSX.Element;

interface WithAccessProps {
  role?: RoleType;
}

export const withAccess = <T extends object>(Component: React.ComponentType<T>): ReturnType<T & WithAccessProps> => {
  return (props: T & WithAccessProps) => {
    const { data, isLoading } = useQueryHandler(
      useQuery({
        queryKey: ['checkAccessApi', props.role],
        queryFn: () => checkAccessApi(props.role),
      }),
    );

    if (isLoading) {
      return <LoadingRavenloop />;
    }

    return (
      <Suspense fallback={<LoadingRavenloop />}>
        <Component {...props} />
      </Suspense>
    );
  };
};
