import React, { useEffect, useState } from 'react';
import { BellOutlined } from '@ant-design/icons';
import { Dropdown } from '@app/components/common/Dropdown/Dropdown';
import { Button } from '@app/components/common/buttons/Button/Button';
import { Badge } from '@app/components/common/Badge/Badge';
import { NotificationsOverlay } from '@app/components/header/components/notificationsDropdown/NotificationsOverlay/NotificationsOverlay';
import { notifications as fetchedNotifications, Notification, getNotificationsApi } from '@app/api/notifications.api';
import { HeaderActionWrapper } from '@app/components/header/Header.styles';
import { getNotificationsLocalStorage } from '@app/services/localStorage.service';

export const NotificationsDropdown: React.FC = () => {
  const [notifications, setNotifications] = useState<Notification[]>([]);
  const [isOpened, setOpened] = useState(false);
  const timeIntervalCalls = 1000 * 60;

  useEffect(() => {
    setNotifications(getNotificationsLocalStorage());
    getNotificationsApi();

    // LISTEN CHANGES IN STORAGE
    const handleExceptionData = () => {
      setNotifications(getNotificationsLocalStorage());
    };
    window.addEventListener('storage', handleExceptionData);

    // PERIODICALLY SEND CHECK NOTIFICATIONS CALLS TO API
    const intervalCallNotificationsApi = setInterval(() => {
      getNotificationsApi();
    }, timeIntervalCalls);
    return () => clearInterval(intervalCallNotificationsApi);
  }, []);

  return (
    <Dropdown
      trigger={['click']}
      overlay={<NotificationsOverlay notifications={notifications} setNotifications={setNotifications} />}
      onOpenChange={setOpened}
    >
      <HeaderActionWrapper>
        <Button
          type={isOpened ? 'ghost' : 'text'}
          icon={
            <Badge dot={notifications?.length > 0}>
              <BellOutlined />
            </Badge>
          }
        />
      </HeaderActionWrapper>
    </Dropdown>
  );
};
