import * as Flags from 'country-flag-icons/react/3x2';
import { hasFlag } from 'country-flag-icons';
import React from 'react';
import { allLanguagesByShortcode } from '@app/constants/languages';
2222;

export const getIconCountryFlagByShortcode = (shortcode: string, width: string): React.ReactNode => {
  if (shortcode) {
    try {
      if (hasFlag(shortcode.toUpperCase())) {
        let Flag = Flags[shortcode.toUpperCase()];
        return <Flag className="emojiFlag" style={{ width: width }} />;
      }
    } catch (err) {
      console.error(err);
      return <></>;
    }
  }
  return <></>;
};

export const getIconCountryFlagByLanguageShortcode = (shortcode: string, width: string): React.ReactNode => {
  try {
    if (shortcode && shortcode in allLanguagesByShortcode) {
      const languageData = allLanguagesByShortcode[shortcode];
      const countryShortCode = languageData?.countryCode;
      if (hasFlag(countryShortCode.toUpperCase())) {
        let Flag = Flags[countryShortCode.toUpperCase()];
        return <Flag className="emojiFlag" style={{ width: width }} />;
      }
    }
  } catch (err) {
    console.error(err);
    return <></>;
  }
  return <></>;
};
