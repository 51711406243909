import { Trans, useTranslation } from 'react-i18next';
import { transComponents } from '../HelpContent';
import { MdBugReport, MdSave } from 'react-icons/md';

const HelpSampleMetrics = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'help.analyses.sampleMetrics' });

  return (
    <>
      <h4 id="sampleMetrics">
        <Trans t={t} i18nKey="title" components={transComponents} />
      </h4>
      <p>
        <Trans t={t} i18nKey="p1" components={transComponents} />
      </p>
      <ul>
        <li>
          <Trans t={t} i18nKey="ul1.li1" components={transComponents} />
          <ul className="no-dots">
            <li>
              <span className="highlighted--red">
                <MdBugReport className="icon" />
                <Trans t={t} i18nKey="ul1.li1-1" components={transComponents} />
              </span>
              <ul>
                <li>
                  <Trans t={t} i18nKey="ul1.li1-1-1" components={transComponents} />
                </li>
              </ul>
            </li>
            <li>
              <span className="highlighted--green">
                <MdBugReport className="icon" />
                <Trans t={t} i18nKey="ul1.li1-2" components={transComponents} />
              </span>
              <ul>
                <li>
                  <Trans t={t} i18nKey="ul1.li1-2-1" components={transComponents} />
                </li>
              </ul>
            </li>
          </ul>
        </li>
        <li>
          <Trans t={t} i18nKey="ul1.li2" components={transComponents} />
          <ul className="no-dots">
            <li>
              <span className="highlighted--purple">
                <MdSave className="icon" />
                <Trans t={t} i18nKey="ul1.li2-1" components={transComponents} />
              </span>
              <ul>
                <li>
                  <Trans t={t} i18nKey="ul1.li2-1-1" components={transComponents} />
                </li>
              </ul>
            </li>
            <li>
              <span className="highlighted--red">
                <MdSave className="icon" />
                <Trans t={t} i18nKey="ul1.li2-2" components={transComponents} />
              </span>
              <ul>
                <li>
                  <Trans t={t} i18nKey="ul1.li2-2-1" components={transComponents} />
                </li>
              </ul>
            </li>
            <li>
              <span className="highlighted--orange">
                <MdSave className="icon" />
                <Trans t={t} i18nKey="ul1.li2-3" components={transComponents} />
              </span>
              <ul>
                <li>
                  <Trans t={t} i18nKey="ul1.li2-3-1" components={transComponents} />
                </li>
              </ul>
            </li>
            <li>
              <span className="highlighted--yellow">
                <MdSave className="icon" />
                <Trans t={t} i18nKey="ul1.li2-4" components={transComponents} />
              </span>
              <ul>
                <li>
                  <Trans t={t} i18nKey="ul1.li2-4-1" components={transComponents} />
                </li>
              </ul>
            </li>
            <li>
              <span className="highlighted--blue">
                <MdSave className="icon" />
                <Trans t={t} i18nKey="ul1.li2-5" components={transComponents} />
              </span>
              <ul>
                <li>
                  <Trans t={t} i18nKey="ul1.li2-5-1" components={transComponents} />
                </li>
              </ul>
            </li>
          </ul>
        </li>
      </ul>
      <p>
        <Trans t={t} i18nKey="p2" components={transComponents} />
      </p>
      <h5 id="sampleMetrics-image1">IMAGE</h5>
    </>
  );
};

export default HelpSampleMetrics;
