import { LanguageType } from '@app/interfaces/interfaces';

interface Language {
  id: number;
  name: LanguageType;
  title: string;
  countryCode: string;
}

export type PlatformLanguagesShortcodes = 'es' | 'en';

export const languages: Language[] = [
  {
    id: 1,
    name: 'en',
    title: 'English',
    countryCode: 'gb',
  },
  {
    id: 2,
    name: 'es',
    title: 'Spanish',
    countryCode: 'es',
  },
  {
    id: 3,
    name: 'de',
    title: 'German',
    countryCode: 'de',
  },
];

export const languagesByShortcode: Record<string, Language> = {
  en: {
    id: 1,
    name: 'en',
    title: 'English',
    countryCode: 'gb',
  },
  es: {
    id: 2,
    name: 'es',
    title: 'Spanish',
    countryCode: 'es',
  },
  de: {
    id: 3,
    name: 'de',
    title: 'German',
    countryCode: 'de',
  },
  fr: {
    id: 4,
    name: 'fr',
    title: 'French',
    countryCode: 'fr',
  },
  it: {
    id: 5,
    name: 'it',
    title: 'Italian',
    countryCode: 'it',
  },
  pt: {
    id: 6,
    name: 'pt',
    title: 'Portuguese',
    countryCode: 'pt',
  },
  ja: {
    id: 7,
    name: 'ja',
    title: 'Japanese',
    countryCode: 'jp',
  },
  zh: {
    id: 8,
    name: 'zh',
    title: 'Chinese',
    countryCode: 'cn',
  },
  ru: {
    id: 9,
    name: 'ru',
    title: 'Russian',
    countryCode: 'ru',
  },
  ar: {
    id: 10,
    name: 'ar',
    title: 'Arabic',
    countryCode: 'sa',
  },
  // Agrega más idiomas según sea necesario
};

export const allLanguagesByShortcode: Record<string, Language> = {
  aa: { id: 1, name: 'aa', title: 'Afar', countryCode: 'DJ' },
  ab: { id: 2, name: 'ab', title: 'Abkhazian', countryCode: 'GE' },
  ae: { id: 3, name: 'ae', title: 'Avestan', countryCode: '' },
  af: { id: 4, name: 'af', title: 'Afrikaans', countryCode: 'ZA' },
  ak: { id: 5, name: 'ak', title: 'Akan', countryCode: '' },
  am: { id: 6, name: 'am', title: 'Amharic', countryCode: 'ET' },
  an: { id: 7, name: 'an', title: 'Aragonese', countryCode: 'ES' },
  ar: { id: 8, name: 'ar', title: 'Arabic', countryCode: 'SA' },
  as: { id: 9, name: 'as', title: 'Assamese', countryCode: 'IN' },
  av: { id: 10, name: 'av', title: 'Avaric', countryCode: '' },
  ay: { id: 11, name: 'ay', title: 'Aymara', countryCode: 'BO' },
  az: { id: 12, name: 'az', title: 'Azerbaijani', countryCode: 'AZ' },
  ba: { id: 13, name: 'ba', title: 'Bashkir', countryCode: 'RU' },
  be: { id: 14, name: 'be', title: 'Belarusian', countryCode: 'BY' },
  bg: { id: 15, name: 'bg', title: 'Bulgarian', countryCode: 'BG' },
  bh: { id: 16, name: 'bh', title: 'Bihari', countryCode: 'IN' },
  bi: { id: 17, name: 'bi', title: 'Bislama', countryCode: 'VU' },
  bm: { id: 18, name: 'bm', title: 'Bambara', countryCode: 'ML' },
  bn: { id: 19, name: 'bn', title: 'Bengali', countryCode: 'BD' },
  bo: { id: 20, name: 'bo', title: 'Tibetan', countryCode: 'CN' },
  br: { id: 21, name: 'br', title: 'Breton', countryCode: 'FR' },
  bs: { id: 22, name: 'bs', title: 'Bosnian', countryCode: 'BA' },
  ca: { id: 23, name: 'ca', title: 'Catalan', countryCode: 'ES' },
  ce: { id: 24, name: 'ce', title: 'Chechen', countryCode: 'RU' },
  ch: { id: 25, name: 'ch', title: 'Chamorro', countryCode: 'GU' },
  co: { id: 26, name: 'co', title: 'Corsican', countryCode: 'FR' },
  cr: { id: 27, name: 'cr', title: 'Cree', countryCode: 'CA' },
  cs: { id: 28, name: 'cs', title: 'Czech', countryCode: 'CZ' },
  cu: { id: 29, name: 'cu', title: 'Church Slavic', countryCode: '' },
  cv: { id: 30, name: 'cv', title: 'Chuvash', countryCode: 'RU' },
  cy: { id: 31, name: 'cy', title: 'Welsh', countryCode: 'GB' },
  da: { id: 32, name: 'da', title: 'Danish', countryCode: 'DK' },
  de: { id: 33, name: 'de', title: 'German', countryCode: 'DE' },
  dv: { id: 34, name: 'dv', title: 'Divehi', countryCode: 'MV' },
  dz: { id: 35, name: 'dz', title: 'Dzongkha', countryCode: 'BT' },
  ee: { id: 36, name: 'ee', title: 'Ewe', countryCode: 'GH' },
  el: { id: 37, name: 'el', title: 'Greek', countryCode: 'GR' },
  en: { id: 38, name: 'en', title: 'English', countryCode: 'GB' },
  eo: { id: 39, name: 'eo', title: 'Esperanto', countryCode: '' },
  es: { id: 40, name: 'es', title: 'Spanish', countryCode: 'ES' },
  et: { id: 41, name: 'et', title: 'Estonian', countryCode: 'EE' },
  eu: { id: 42, name: 'eu', title: 'Basque', countryCode: 'ES' },
  fa: { id: 43, name: 'fa', title: 'Persian', countryCode: 'IR' },
  ff: { id: 44, name: 'ff', title: 'Fulah', countryCode: 'SN' },
  fi: { id: 45, name: 'fi', title: 'Finnish', countryCode: 'FI' },
  fj: { id: 46, name: 'fj', title: 'Fijian', countryCode: 'FJ' },
  fo: { id: 47, name: 'fo', title: 'Faroese', countryCode: 'FO' },
  fr: { id: 48, name: 'fr', title: 'French', countryCode: 'FR' },
  fy: { id: 49, name: 'fy', title: 'Western Frisian', countryCode: 'NL' },
  ga: { id: 50, name: 'ga', title: 'Irish', countryCode: 'IE' },
  gd: { id: 51, name: 'gd', title: 'Gaelic', countryCode: 'GB' },
  gl: { id: 52, name: 'gl', title: 'Galician', countryCode: 'ES' },
  gn: { id: 53, name: 'gn', title: 'Guarani', countryCode: 'PY' },
  gu: { id: 54, name: 'gu', title: 'Gujarati', countryCode: 'IN' },
  gv: { id: 55, name: 'gv', title: 'Manx', countryCode: '' },
  ha: { id: 56, name: 'ha', title: 'Hausa', countryCode: 'NG' },
  he: { id: 57, name: 'he', title: 'Hebrew', countryCode: 'IL' },
  hi: { id: 58, name: 'hi', title: 'Hindi', countryCode: 'IN' },
  ho: { id: 59, name: 'ho', title: 'Hiri Motu', countryCode: 'PG' },
  hr: { id: 60, name: 'hr', title: 'Croatian', countryCode: 'HR' },
  ht: { id: 61, name: 'ht', title: 'Haitian', countryCode: 'HT' },
  hu: { id: 62, name: 'hu', title: 'Hungarian', countryCode: 'HU' },
  hy: { id: 63, name: 'hy', title: 'Armenian', countryCode: 'AM' },
  hz: { id: 64, name: 'hz', title: 'Herero', countryCode: 'NA' },
  ia: { id: 65, name: 'ia', title: 'Interlingua', countryCode: '' },
  id: { id: 66, name: 'id', title: 'Indonesian', countryCode: 'ID' },
  ie: { id: 67, name: 'ie', title: 'Interlingue', countryCode: '' },
  ig: { id: 68, name: 'ig', title: 'Igbo', countryCode: 'NG' },
  ii: { id: 69, name: 'ii', title: 'Sichuan Yi', countryCode: 'CN' },
  ik: { id: 70, name: 'ik', title: 'Inupiaq', countryCode: 'US' },
  io: { id: 71, name: 'io', title: 'Ido', countryCode: '' },
  is: { id: 72, name: 'is', title: 'Icelandic', countryCode: 'IS' },
  it: { id: 73, name: 'it', title: 'Italian', countryCode: 'IT' },
  iu: { id: 74, name: 'iu', title: 'Inuktitut', countryCode: 'CA' },
  ja: { id: 75, name: 'ja', title: 'Japanese', countryCode: 'JP' },
  jv: { id: 76, name: 'jv', title: 'Javanese', countryCode: 'ID' },
  ka: { id: 77, name: 'ka', title: 'Georgian', countryCode: 'GE' },
  kg: { id: 78, name: 'kg', title: 'Kongo', countryCode: 'CD' },
  ki: { id: 79, name: 'ki', title: 'Kikuyu', countryCode: 'KE' },
  kj: { id: 80, name: 'kj', title: 'Kuanyama', countryCode: 'NA' },
  kk: { id: 81, name: 'kk', title: 'Kazakh', countryCode: 'KZ' },
  kl: { id: 82, name: 'kl', title: 'Kalaallisut', countryCode: 'GL' },
  km: { id: 83, name: 'km', title: 'Khmer', countryCode: 'KH' },
  kn: { id: 84, name: 'kn', title: 'Kannada', countryCode: 'IN' },
  ko: { id: 85, name: 'ko', title: 'Korean', countryCode: 'KR' },
  kr: { id: 86, name: 'kr', title: 'Kanuri', countryCode: 'NG' },
  ks: { id: 87, name: 'ks', title: 'Kashmiri', countryCode: 'IN' },
  ku: { id: 88, name: 'ku', title: 'Kurdish', countryCode: 'TR' },
  kv: { id: 89, name: 'kv', title: 'Komi', countryCode: 'RU' },
  kw: { id: 90, name: 'kw', title: 'Cornish', countryCode: 'GB' },
  ky: { id: 91, name: 'ky', title: 'Kyrgyz', countryCode: 'KG' },
  la: { id: 92, name: 'la', title: 'Latin', countryCode: 'VA' },
  lb: { id: 93, name: 'lb', title: 'Luxembourgish', countryCode: 'LU' },
  lg: { id: 94, name: 'lg', title: 'Ganda', countryCode: 'UG' },
  li: { id: 95, name: 'li', title: 'Limburgish', countryCode: 'NL' },
  ln: { id: 96, name: 'ln', title: 'Lingala', countryCode: 'CD' },
  lo: { id: 97, name: 'lo', title: 'Lao', countryCode: 'LA' },
  lt: { id: 98, name: 'lt', title: 'Lithuanian', countryCode: 'LT' },
  lu: { id: 99, name: 'lu', title: 'Luba-Katanga', countryCode: 'CD' },
  lv: { id: 100, name: 'lv', title: 'Latvian', countryCode: 'LV' },
  mg: { id: 101, name: 'mg', title: 'Malagasy', countryCode: 'MG' },
  mh: { id: 102, name: 'mh', title: 'Marshallese', countryCode: 'MH' },
  mi: { id: 103, name: 'mi', title: 'Maori', countryCode: 'NZ' },
  mk: { id: 104, name: 'mk', title: 'Macedonian', countryCode: 'MK' },
  ml: { id: 105, name: 'ml', title: 'Malayalam', countryCode: 'IN' },
  mn: { id: 106, name: 'mn', title: 'Mongolian', countryCode: 'MN' },
  mr: { id: 107, name: 'mr', title: 'Marathi', countryCode: 'IN' },
  ms: { id: 108, name: 'ms', title: 'Malay', countryCode: 'MY' },
  mt: { id: 109, name: 'mt', title: 'Maltese', countryCode: 'MT' },
  my: { id: 110, name: 'my', title: 'Burmese', countryCode: 'MM' },
  na: { id: 111, name: 'na', title: 'Nauru', countryCode: 'NR' },
  nb: { id: 112, name: 'nb', title: 'Norwegian Bokmål', countryCode: 'NO' },
  nd: { id: 113, name: 'nd', title: 'North Ndebele', countryCode: 'ZW' },
  ne: { id: 114, name: 'ne', title: 'Nepali', countryCode: 'NP' },
  ng: { id: 115, name: 'ng', title: 'Ndonga', countryCode: 'NA' },
  nl: { id: 116, name: 'nl', title: 'Dutch', countryCode: 'NL' },
  nn: { id: 117, name: 'nn', title: 'Norwegian Nynorsk', countryCode: 'NO' },
  no: { id: 118, name: 'no', title: 'Norwegian', countryCode: 'NO' },
  nr: { id: 119, name: 'nr', title: 'South Ndebele', countryCode: 'ZA' },
  nv: { id: 120, name: 'nv', title: 'Navajo', countryCode: 'US' },
  ny: { id: 121, name: 'ny', title: 'Chichewa', countryCode: 'MW' },
  oc: { id: 122, name: 'oc', title: 'Occitan', countryCode: 'FR' },
  oj: { id: 123, name: 'oj', title: 'Ojibwa', countryCode: 'CA' },
  om: { id: 124, name: 'om', title: 'Oromo', countryCode: 'ET' },
  or: { id: 125, name: 'or', title: 'Oriya', countryCode: 'IN' },
  os: { id: 126, name: 'os', title: 'Ossetian', countryCode: 'RU' },
  pa: { id: 127, name: 'pa', title: 'Panjabi', countryCode: 'IN' },
  pi: { id: 128, name: 'pi', title: 'Pali', countryCode: '' },
  pl: { id: 129, name: 'pl', title: 'Polish', countryCode: 'PL' },
  ps: { id: 130, name: 'ps', title: 'Pashto', countryCode: 'AF' },
  pt: { id: 131, name: 'pt', title: 'Portuguese', countryCode: 'PT' },
  qu: { id: 132, name: 'qu', title: 'Quechua', countryCode: 'PE' },
  rm: { id: 133, name: 'rm', title: 'Romansh', countryCode: 'CH' },
  rn: { id: 134, name: 'rn', title: 'Rundi', countryCode: 'BI' },
  ro: { id: 135, name: 'ro', title: 'Romanian', countryCode: 'RO' },
  ru: { id: 136, name: 'ru', title: 'Russian', countryCode: 'RU' },
  rw: { id: 137, name: 'rw', title: 'Kinyarwanda', countryCode: 'RW' },
  sa: { id: 138, name: 'sa', title: 'Sanskrit', countryCode: '' },
  sc: { id: 139, name: 'sc', title: 'Sardinian', countryCode: 'IT' },
  sd: { id: 140, name: 'sd', title: 'Sindhi', countryCode: 'PK' },
  se: { id: 141, name: 'se', title: 'Northern Sami', countryCode: 'NO' },
  sg: { id: 142, name: 'sg', title: 'Sango', countryCode: 'CF' },
  si: { id: 143, name: 'si', title: 'Sinhala', countryCode: 'LK' },
  sk: { id: 144, name: 'sk', title: 'Slovak', countryCode: 'SK' },
  sl: { id: 145, name: 'sl', title: 'Slovenian', countryCode: 'SI' },
  sm: { id: 146, name: 'sm', title: 'Samoan', countryCode: 'WS' },
  sn: { id: 147, name: 'sn', title: 'Shona', countryCode: 'ZW' },
  so: { id: 148, name: 'so', title: 'Somali', countryCode: 'SO' },
  sq: { id: 149, name: 'sq', title: 'Albanian', countryCode: 'AL' },
  sr: { id: 150, name: 'sr', title: 'Serbian', countryCode: 'RS' },
  ss: { id: 151, name: 'ss', title: 'Swati', countryCode: 'SZ' },
  st: { id: 152, name: 'st', title: 'Southern Sotho', countryCode: 'LS' },
  su: { id: 153, name: 'su', title: 'Sundanese', countryCode: 'ID' },
  sv: { id: 154, name: 'sv', title: 'Swedish', countryCode: 'SE' },
  sw: { id: 155, name: 'sw', title: 'Swahili', countryCode: 'TZ' },
  ta: { id: 156, name: 'ta', title: 'Tamil', countryCode: 'IN' },
  te: { id: 157, name: 'te', title: 'Telugu', countryCode: 'IN' },
  tg: { id: 158, name: 'tg', title: 'Tajik', countryCode: 'TJ' },
  th: { id: 159, name: 'th', title: 'Thai', countryCode: 'TH' },
  ti: { id: 160, name: 'ti', title: 'Tigrinya', countryCode: 'ER' },
  tk: { id: 161, name: 'tk', title: 'Turkmen', countryCode: 'TM' },
  tl: { id: 162, name: 'tl', title: 'Tagalog', countryCode: 'PH' },
  tn: { id: 163, name: 'tn', title: 'Tswana', countryCode: 'BW' },
  to: { id: 164, name: 'to', title: 'Tonga', countryCode: 'TO' },
  tr: { id: 165, name: 'tr', title: 'Turkish', countryCode: 'TR' },
  ts: { id: 166, name: 'ts', title: 'Tsonga', countryCode: 'ZA' },
  tt: { id: 167, name: 'tt', title: 'Tatar', countryCode: 'RU' },
  tw: { id: 168, name: 'tw', title: 'Twi', countryCode: 'GH' },
  ty: { id: 169, name: 'ty', title: 'Tahitian', countryCode: 'PF' },
  ug: { id: 170, name: 'ug', title: 'Uighur', countryCode: 'CN' },
  uk: { id: 171, name: 'uk', title: 'Ukrainian', countryCode: 'UA' },
  ur: { id: 172, name: 'ur', title: 'Urdu', countryCode: 'PK' },
  uz: { id: 173, name: 'uz', title: 'Uzbek', countryCode: 'UZ' },
  ve: { id: 174, name: 've', title: 'Venda', countryCode: 'ZA' },
  vi: { id: 175, name: 'vi', title: 'Vietnamese', countryCode: 'VN' },
  vo: { id: 176, name: 'vo', title: 'Volapük', countryCode: '' },
  wa: { id: 177, name: 'wa', title: 'Walloon', countryCode: 'BE' },
  wo: { id: 178, name: 'wo', title: 'Wolof', countryCode: 'SN' },
  xh: { id: 179, name: 'xh', title: 'Xhosa', countryCode: 'ZA' },
  yi: { id: 180, name: 'yi', title: 'Yiddish', countryCode: '' },
  yo: { id: 181, name: 'yo', title: 'Yoruba', countryCode: 'NG' },
  za: { id: 182, name: 'za', title: 'Zhuang', countryCode: 'CN' },
  zh: { id: 183, name: 'zh', title: 'Chinese', countryCode: 'CN' },
  zu: { id: 184, name: 'zu', title: 'Zulu', countryCode: 'ZA' },
};

export const languageByShortcode: Record<string, string> = {
  aa: 'Afar',
  ab: 'Abkhazian',
  ae: 'Avestan',
  af: 'Afrikaans',
  ak: 'Akan',
  am: 'Amharic',
  an: 'Aragonese',
  ar: 'Arabic',
  as: 'Assamese',
  av: 'Avaric',
  ay: 'Aymara',
  az: 'Azerbaijani',
  ba: 'Bashkir',
  be: 'Belarusian',
  bg: 'Bulgarian',
  bh: 'Bihari',
  bi: 'Bislama',
  bm: 'Bambara',
  bn: 'Bengali',
  bo: 'Tibetan',
  br: 'Breton',
  bs: 'Bosnian',
  ca: 'Catalan',
  ce: 'Chechen',
  ch: 'Chamorro',
  co: 'Corsican',
  cr: 'Cree',
  cs: 'Czech',
  cu: 'Church Slavic',
  cv: 'Chuvash',
  cy: 'Welsh',
  da: 'Danish',
  de: 'German',
  dv: 'Divehi',
  dz: 'Dzongkha',
  ee: 'Ewe',
  el: 'Greek',
  en: 'English',
  eo: 'Esperanto',
  es: 'Spanish',
  et: 'Estonian',
  eu: 'Basque',
  fa: 'Persian',
  ff: 'Fulah',
  fi: 'Finnish',
  fj: 'Fijian',
  fo: 'Faroese',
  fr: 'French',
  fy: 'Western Frisian',
  ga: 'Irish',
  gd: 'Gaelic',
  gl: 'Galician',
  gn: 'Guarani',
  gu: 'Gujarati',
  gv: 'Manx',
  ha: 'Hausa',
  he: 'Hebrew',
  hi: 'Hindi',
  ho: 'Hiri Motu',
  hr: 'Croatian',
  ht: 'Haitian',
  hu: 'Hungarian',
  hy: 'Armenian',
  hz: 'Herero',
  ia: 'Interlingua',
  id: 'Indonesian',
  ie: 'Interlingue',
  ig: 'Igbo',
  ii: 'Sichuan Yi',
  ik: 'Inupiaq',
  io: 'Ido',
  is: 'Icelandic',
  it: 'Italian',
  iu: 'Inuktitut',
  ja: 'Japanese',
  jv: 'Javanese',
  ka: 'Georgian',
  kg: 'Kongo',
  ki: 'Kikuyu',
  kj: 'Kuanyama',
  kk: 'Kazakh',
  kl: 'Kalaallisut',
  km: 'Khmer',
  kn: 'Kannada',
  ko: 'Korean',
  kr: 'Kanuri',
  ks: 'Kashmiri',
  ku: 'Kurdish',
  kv: 'Komi',
  kw: 'Cornish',
  ky: 'Kirghiz',
  la: 'Latin',
  lb: 'Luxembourgish',
  lg: 'Ganda',
  li: 'Limburgish',
  ln: 'Lingala',
  lo: 'Lao',
  lt: 'Lithuanian',
  lu: 'Luba-Katanga',
  lv: 'Latvian',
  mg: 'Malagasy',
  mh: 'Marshallese',
  mi: 'Maori',
  mk: 'Macedonian',
  ml: 'Malayalam',
  mn: 'Mongolian',
  mr: 'Marathi',
  ms: 'Malay',
  mt: 'Maltese',
  my: 'Burmese',
  na: 'Nauru',
  nb: 'Norwegian Bokmål',
  nd: 'North Ndebele',
  ne: 'Nepali',
  ng: 'Ndonga',
  nl: 'Dutch',
  nn: 'Norwegian Nynorsk',
  no: 'Norwegian',
  nr: 'South Ndebele',
  nv: 'Navajo',
  ny: 'Chichewa',
  oc: 'Occitan',
  oj: 'Ojibwa',
  om: 'Oromo',
  or: 'Oriya',
  os: 'Ossetian',
  pa: 'Panjabi',
  pi: 'Pali',
  pl: 'Polish',
  ps: 'Pashto',
  pt: 'Portuguese',
  qu: 'Quechua',
  rm: 'Romansh',
  rn: 'Kirundi',
  ro: 'Romanian',
  ru: 'Russian',
  rw: 'Kinyarwanda',
  sa: 'Sanskrit',
  sc: 'Sardinian',
  sd: 'Sindhi',
  se: 'Northern Sami',
  sg: 'Sango',
  si: 'Sinhala',
  sk: 'Slovak',
  sl: 'Slovenian',
  sm: 'Samoan',
  sn: 'Shona',
  so: 'Somali',
  sq: 'Albanian',
  sr: 'Serbian',
  ss: 'Swati',
  st: 'Southern Sotho',
  su: 'Sundanese',
  sv: 'Swedish',
  sw: 'Swahili',
  ta: 'Tamil',
  te: 'Telugu',
  tg: 'Tajik',
  th: 'Thai',
  ti: 'Tigrinya',
  tk: 'Turkmen',
  tl: 'Tagalog',
  tn: 'Tswana',
  to: 'Tonga',
  tr: 'Turkish',
  ts: 'Tsonga',
  tt: 'Tatar',
  tw: 'Twi',
  ty: 'Tahitian',
  ug: 'Uighur',
  uk: 'Ukrainian',
  ur: 'Urdu',
  uz: 'Uzbek',
  ve: 'Venda',
  vi: 'Vietnamese',
  vo: 'Volapük',
  wa: 'Walloon',
  wo: 'Wolof',
  xh: 'Xhosa',
  yi: 'Yiddish',
  yo: 'Yoruba',
  za: 'Zhuang',
  zh: 'Chinese',
  zu: 'Zulu',
};

export const shortcodeByLanguage: Record<string, string> = {
  afar: 'aa',
  abkhazian: 'ab',
  avestan: 'ae',
  afrikaans: 'af',
  akan: 'ak',
  amharic: 'am',
  aragonese: 'an',
  arabic: 'ar',
  assamese: 'as',
  avvaric: 'av',
  aymara: 'ay',
  azerbaijani: 'az',
  bashkir: 'ba',
  belarusian: 'be',
  bulgarian: 'bg',
  bihari: 'bh',
  bislama: 'bi',
  bambara: 'bm',
  bengali: 'bn',
  tibetan: 'bo',
  breton: 'br',
  bosnian: 'bs',
  catalan: 'ca',
  chechen: 'ce',
  chamorro: 'ch',
  corsican: 'co',
  cree: 'cr',
  czech: 'cs',
  'church slavic': 'cu',
  chuvash: 'cv',
  welsh: 'cy',
  danish: 'da',
  german: 'de',
  divehi: 'dv',
  dzongkha: 'dz',
  ewe: 'ee',
  greek: 'el',
  english: 'en',
  esperanto: 'eo',
  spanish: 'es',
  estonian: 'et',
  basque: 'eu',
  persian: 'fa',
  fulah: 'ff',
  finnish: 'fi',
  fijian: 'fj',
  faroese: 'fo',
  french: 'fr',
  'western frisian': 'fy',
  irish: 'ga',
  gaelic: 'gd',
  galician: 'gl',
  guarani: 'gn',
  gujarati: 'gu',
  manx: 'gv',
  hausa: 'ha',
  hebrew: 'he',
  hindi: 'hi',
  'hiri motu': 'ho',
  croatian: 'hr',
  haitian: 'ht',
  hungarian: 'hu',
  armenian: 'hy',
  herero: 'hz',
  interlingua: 'ia',
  indonesian: 'id',
  interlingue: 'ie',
  igbo: 'ig',
  'sichuan yi': 'ii',
  inupiaq: 'ik',
  ido: 'io',
  icelandic: 'is',
  italian: 'it',
  inuktitut: 'iu',
  japanese: 'ja',
  javanese: 'jv',
  georgian: 'ka',
  kongo: 'kg',
  kikuyu: 'ki',
  kuanyama: 'kj',
  kazakh: 'kk',
  kalaallisut: 'kl',
  khmer: 'km',
  kannada: 'kn',
  korean: 'ko',
  kanuri: 'kr',
  kashmiri: 'ks',
  kurdish: 'ku',
  komi: 'kv',
  cornish: 'kw',
  kirghiz: 'ky',
  latin: 'la',
  luxembourgish: 'lb',
  ganda: 'lg',
  limburgish: 'li',
  lingala: 'ln',
  lao: 'lo',
  lithuanian: 'lt',
  'luba-katanga': 'lu',
  latvian: 'lv',
  malagasy: 'mg',
  marshallese: 'mh',
  maori: 'mi',
  macedonian: 'mk',
  malayalam: 'ml',
  mongolian: 'mn',
  marathi: 'mr',
  malay: 'ms',
  maltese: 'mt',
  burmese: 'my',
  nauru: 'na',
  'norwegian bokmål': 'nb',
  'north ndebele': 'nd',
  nepali: 'ne',
  ndonga: 'ng',
  dutch: 'nl',
  'norwegian nynorsk': 'nn',
  norwegian: 'no',
  'south ndebele': 'nr',
  navajo: 'nv',
  chichewa: 'ny',
  occitan: 'oc',
  ojibwa: 'oj',
  oromo: 'om',
  oriya: 'or',
  ossetian: 'os',
  panjabi: 'pa',
  pali: 'pi',
  polish: 'pl',
  pashto: 'ps',
  portuguese: 'pt',
  quechua: 'qu',
  romansh: 'rm',
  kirundi: 'rn',
  romanian: 'ro',
  russian: 'ru',
  kinyarwanda: 'rw',
  sanskrit: 'sa',
  sardinian: 'sc',
  sindhi: 'sd',
  'northern sami': 'se',
  sango: 'sg',
  sinhala: 'si',
  slovak: 'sk',
  slovenian: 'sl',
  samoan: 'sm',
  shona: 'sn',
  somali: 'so',
  albanian: 'sq',
  serbian: 'sr',
  swati: 'ss',
  'southern sotho': 'st',
  sundanese: 'su',
  swedish: 'sv',
  swahili: 'sw',
  tamil: 'ta',
  telugu: 'te',
  tajik: 'tg',
  thai: 'th',
  tigrinya: 'ti',
  turkmen: 'tk',
  tagalog: 'tl',
  tswana: 'tn',
  tonga: 'to',
  turkish: 'tr',
  tsonga: 'ts',
  tatar: 'tt',
  twi: 'tw',
  tahitian: 'ty',
  uighur: 'ug',
  ukrainian: 'uk',
  urdu: 'ur',
  uzbek: 'uz',
  venda: 've',
  vietnamese: 'vi',
  volapük: 'vo',
  walloon: 'wa',
  wolof: 'wo',
  xhosa: 'xh',
  yiddish: 'yi',
  yoruba: 'yo',
  zhuang: 'za',
  chinese: 'zh',
  zulu: 'zu',
};
