import styled from 'styled-components';
import { Col } from 'antd';
import { FONT_SIZE } from '@app/styles/themes/constants';

/**
 * @deprecated
 * @see {@link FormTitleWrapper}
 */
export const FormTitleWrapperCol = styled(Col)`
  margin: 2vh 0;

  & > h2,
  & > h3,
  & > span {
    border-bottom: 2px solid var(--title-wrapper-line);
    font-size: 1.5vh;
    margin: 0;
  }
  &:first-letter {
    text-transform: capitalize;
  }
`;

export const FormTitleWrapper = styled.div`
  width: 100%;
  margin-bottom: 1rem;

  & > h2,
  & > h3,
  & > span {
    border-bottom: 2px solid var(--title-wrapper-line);
    font-size: ${FONT_SIZE.lg};
    margin: 0;
  }
  &:first-letter {
    text-transform: capitalize;
  }
`;
