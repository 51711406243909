import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  ResetPasswordRequest,
  login,
  logout,
  LoginRequest,
  signUp,
  SignUpRequest,
  resetPassword,
  verifySecurityCode,
  SecurityCodePayload,
  NewPasswordData,
  setNewPassword,
  recoverPassword,
} from '@app/api/auth.api';
import { setUser } from '@app/store/slices/userSlice';
import {
  deleteToken,
  deleteExpiresToken,
  deleteUser,
  persistToken,
  readToken,
  readExpiresToken,
} from '@app/services/localStorage.service';

export interface AuthSlice {
  token?: string | null;
  expiresToken?: string | null;
}

const initialState: AuthSlice = {
  token: readToken(),
  expiresToken: readExpiresToken(),
};

export const doLogin = createAsyncThunk('auth/doLogin', async (loginPayload: LoginRequest, { dispatch }) =>
  login(loginPayload).then((res) => {
    if (res.status == 'error') {
      throw 'Login failed!';
    }

    if ((res.data as unknown) === 'missing eula') {
      throw 'eula not accepted';
    }

    if ((res.data as unknown) === 'expired user') {
      throw 'expired user';
    }
    if (res.status == 'Success') {
      dispatch(setUser(res.data));
      persistToken(res.data['api-token'], res.data['expires'], res.data['current_module']);
    }

    return res.data['api-token'];
  }),
);

export const doSignUp = createAsyncThunk('auth/doSignUp', async (signUpPayload: SignUpRequest) =>
  signUp(signUpPayload),
);

export const doResetPassword = createAsyncThunk(
  'auth/doResetPassword',
  async (resetPassPayload: ResetPasswordRequest) => resetPassword(resetPassPayload),
);

export const doRecoverPassword = createAsyncThunk(
  'auth/doRecoverPassword',
  async (recoverPassPayload: { token: string | null }) => recoverPassword(recoverPassPayload),
);

export const doVerifySecurityCode = createAsyncThunk(
  'auth/doVerifySecurityCode',
  async (securityCodePayload: SecurityCodePayload) => verifySecurityCode(securityCodePayload),
);

export const doSetNewPassword = createAsyncThunk('auth/doSetNewPassword', async (newPasswordData: NewPasswordData) =>
  setNewPassword(newPasswordData),
);

export const doLogout = createAsyncThunk('auth/doLogout', (payload, { dispatch }) => {
  logout();
  deleteToken();
  deleteExpiresToken();
  deleteUser();
  dispatch(setUser(null));
});

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(doLogin.fulfilled, (state, action) => {
      state.token = action.payload;
    });
    builder.addCase(doLogout.fulfilled, (state) => {
      state.token = '';
    });
  },
});

export default authSlice.reducer;
