import { showValueIOC } from '@app/utils/utils';
import { getIconCountryFlagByShortcode } from '@app/utils/countryFlagIcons';
import { CardInside } from '@app/components/common/CardInside/CardInside';
import { FONT_SIZE_VH as FONT_SIZE } from '@app/styles/themes/constants';
import { Tooltip } from 'antd';
import React from 'react';
import { allLanguagesByShortcode, languageByShortcode } from '@app/constants/languages';

const regionNames = new Intl.DisplayNames(['en'], { type: 'region' });

export const getCountryNameByShortcode = (shortcode?: string): string | undefined => {
  if (shortcode) {
    try {
      return regionNames.of(shortcode.toUpperCase());
    } catch (err) {
      console.error(err);
      return '';
    }
  }
  return '';
};

export const getLanguageNameByShortcode = (shortcode?: string): string | undefined => {
  if (shortcode && shortcode in allLanguagesByShortcode) {
    return languageByShortcode[shortcode];
  }
  return shortcode;
};

export const getCountryValueTitleIocWithFlag = (shortcode: string): React.ReactNode => {
  let value = <span>{shortcode}</span>;
  const countryName = getCountryNameByShortcode(shortcode);
  if (countryName) {
    value = (
      <span style={{ display: 'flex', verticalAlign: 'middle' }}>
        <span> {countryName + ' (' + shortcode + ')'}</span>
        <span style={{ marginLeft: '0.5vw', display: 'flex', verticalAlign: 'middle' }}>
          {getIconCountryFlagByShortcode(shortcode, '2vh')}
        </span>
      </span>
    );
  }
  return value;
};
