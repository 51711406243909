import { httpApi } from '@app/api/http.api';
import { InfoCircleFilled, StopFilled } from '@ant-design/icons';
import React from 'react';
import {
  addNotificationApiToLocalStorage,
  notificationController,
  setNotificationsApiToLocalStorage,
} from '@app/controllers/notificationController';
import { clearNotificationsLocalStorage } from '@app/services/localStorage.service';

export interface Message {
  id: number;
  description: string;
}

export interface Mention extends Message {
  userName: string;
  userIcon: string;
  place: string;
  href: string;
}

export interface NotificationApi {
  n_id: number;
  n_text: string;
  n_type: string;
  n_date: string;
  n_status: number;

  userIcon?: React.ReactNode;
  description?: string;
  id?: number;
  date?: string;
}

export interface NotificationApi2 {
  userIcon?: React.ReactNode;
  description?: string;
  id?: number;
  date?: string;
}

export type Notification = Mention | Message | NotificationApi2;

export const getNotificationsApi = (): void => {
  httpApi
    .get('/notifications')
    .then((response: Record<string, unknown>) => {
      if (response.data.notifications) {
        setNotificationsApiToLocalStorage(response.data.notifications);
      }
    })
    .catch((e: Error) => {
      console.log(e);
    });
};

export const postDeleteAllNotificationsApi = (t: Record<string, unknown>): void => {
  httpApi
    .post('/notifications', { deleteAllNotifications: true })
    .then((response: Record<string, unknown>) => {
      if (response.data.op) {
        clearNotificationsLocalStorage();
      } else {
        notificationController.error({ message: t('notifications.errorDeletingNotifications') });
      }
    })
    .catch((e: Error) => {
      console.log(e);
      notificationController.error({ message: t('notifications.errorDeletingNotifications') });
    });
};

export const notifications = [];
// export const notifications = [
//   {
//     id: 2,
//     description: 'header.notifications.loginAttempt',
//   },
//   {
//     id: 3,
//     description: 'header.notifications.serverError',
//   },
//   {
//     id: 4,
//     description: 'header.notifications.mention',
//     userName: 'Steve Manson',
//     userIcon:
//       'https://res.cloudinary.com/lapkinthegod/image/upload/v1629187274/young-male-doctor-white-uniform_x7dcrs.jpg',
//     place: 'medical-dashboard.latestScreenings.title',
//     href: `/#latest-screenings`,
//   },
// ];
