import { ClientUser } from '@app/domain/UserModel';
import { useTranslation } from 'react-i18next';
import logoRavenloopShort from '@app/assets/logos_platform/ravenloopShort.svg';
import { useResponsive } from '@app/hooks/useResponsive';
import styled from 'styled-components';

const MobileLogo = styled.img`
  width: 16px;
  margin-right: 32px;
`;

export const ClientLogo = (props: { client?: ClientUser; isSiderCollapsed?: boolean }) => {
  const { client, isSiderCollapsed } = props;

  const { t } = useTranslation();

  const { mobileOnly } = useResponsive();

  if (!client) {
    return <div>{t('common.noClient')}</div>;
  } else if (client?.CLIENT === 'MASTER' && mobileOnly) {
    return <MobileLogo src={logoRavenloopShort} alt="Ravenloop" />;
  } else if (client?.CLIENT === 'MASTER' && isSiderCollapsed) {
    // TODO: Coger siempre el logo del cliente cuando se añada la versión pequeña, no solo con MASTER
    return <img src={logoRavenloopShort} alt="Ravenloop" width="100%" style={{ minWidth: '2rem' }} />;
  } else if (client?.IMAGE) {
    return <img src={`data:image/png;base64, ${client?.IMAGE}`} width="100%" />;
  } else {
    return <div>{client?.CLIENT}</div>;
  }
};
