import { withAccess } from '@app/hocs/withAccess';
import { lazy } from 'react';
import VmProvider from '../context/VmProvider';
import { CheckRoute } from './CheckRoute';
const RavensightDashboardPage = lazy(() => import('@app/pages/DashboardPages/Ravensight/RavensightDashboardPage'));
const RavensightUploadPage = lazy(
  () => import('@app/pages/DashboardPages/Ravensight/UploadFiles/RavensightUploadPage'),
);
const PrincipalDashAnalysisTemplate = lazy(
  () => import('../RavenSight/Analysis/Analysis/PrincipalAnalysis/GeneralReportDash/PrincipalDashAnalysisTemplate'),
);
const IOCsTableBySample = lazy(
  () => import('../RavenSight/Analysis/Analysis/PrincipalAnalysis/IOCsList/IOCsTableBySample'),
);
const FeedsIntelligenceTemplate = lazy(
  () => import('../RavenSight/Analysis/Analysis/PrincipalAnalysis/FeedsIntelligence/FeedsIntelligenceTemplate'),
);
const NetworkConnectionsDash = lazy(
  () => import('../RavenSight/Analysis/Analysis/PrincipalAnalysis/NetworkConnectionsDash/NetworkConnectionsDash'),
);
const EditExecutiveReport = lazy(
  () => import('../RavenSight/Analysis/Analysis/PrincipalAnalysis/EditExecutiveReport/EditExecutiveReport'),
);
const BinaryImagesFileSample = lazy(
  () => import('../RavenSight/Analysis/Analysis/StaticAnalysis/BinaryImagesFileSample'),
);
const MispCorrelationGraph = lazy(
  () => import('../RavenSight/Analysis/Analysis/PrincipalAnalysis/MispCorrelationDash/MispCorrelationGraph'),
);
const StixGraph = lazy(() => import('../RavenSight/Analysis/Analysis/PrincipalAnalysis/StixDash/StixGraph'));
const MitreMatrixTemplate = lazy(
  () => import('../RavenSight/Analysis/Analysis/PrincipalAnalysis/MitreMatrixDash/MitreMatrixTemplate'),
);
const StaticExecutiveSummary = lazy(
  () => import('../RavenSight/Analysis/Analysis/StaticAnalysis/ExecutiveSummary/StaticExecutiveSummary'),
);
const EntropyStaticAnalysis = lazy(
  () => import('../RavenSight/Analysis/Analysis/StaticAnalysis/Entropy/EntropyStaticAnalysis'),
);
const SignaturesStaticAnalysisTabs = lazy(
  () => import('../RavenSight/Analysis/Analysis/StaticAnalysis/Signatures/SignaturesStaticAnalysisTabs'),
);
const RadareGraph = lazy(() => import('../RavenSight/Analysis/Analysis/StaticAnalysis/RadareGraph'));
const AntivirusStaticAnalysis = lazy(
  () => import('../RavenSight/Analysis/Analysis/StaticAnalysis/Antivirus/AntivirusStaticAnalysis'),
);
const AdditionalInfoStaticAnalysis = lazy(
  () => import('../RavenSight/Analysis/Analysis/StaticAnalysis/AdditionalInformation/AdditionalInfoStaticAnalysis'),
);
const FilesObtainedStaticAnalysis = lazy(
  () => import('../RavenSight/Analysis/Analysis/StaticAnalysis/DroppedFiles/FilesObtainedStaticAnalysis'),
);
const Ja3Table = lazy(() => import('../RavenSight/Analysis/Analysis/StaticAnalysis/Ja3Table'));
const DroppedFilesTable = lazy(
  () => import('../RavenSight/Analysis/Analysis/PrincipalAnalysis/DroppedFiles/DroppedFilesTable'),
);
const StaticUrlSummary = lazy(
  () => import('../RavenSight/Analysis/Analysis/StaticAnalysis/UrlSummary/StaticUrlSummary'),
);
const UrlTrace = lazy(() => import('../RavenSight/Analysis/Analysis/StaticAnalysis/UrlTrace/UrlTrace'));
const TableItemsObtainedUrls = lazy(
  () => import('../RavenSight/Analysis/Analysis/StaticAnalysis/ItemsObtained/TableItemsObtainedUrls'),
);
const SourceCodeUrl = lazy(() => import('../RavenSight/Analysis/Analysis/StaticAnalysis/SourceCodeUrl/SourceCodeUrl'));
const CertificateAnalysis = lazy(
  () => import('../RavenSight/Analysis/Analysis/StaticAnalysis/CertificateAnalysis/CertificateAnalysis'),
);
const DynamicExecutiveSummary = lazy(
  () => import('../RavenSight/Analysis/Analysis/DynamicAnalysis/DynamicExecutiveSummary/DynamicExecutiveSummary'),
);
const ProcessesTabs = lazy(() => import('../RavenSight/Analysis/Analysis/DynamicAnalysis/Processes/ProcessesTabs'));
const SignaturesAnalysisTabs = lazy(
  () => import('../RavenSight/Analysis/Analysis/DynamicAnalysis/SignaturesAnalysisTabs/SignaturesAnalysisTabs'),
);
const NetworkConnections = lazy(
  () => import('../RavenSight/Analysis/Analysis/DynamicAnalysis/NetworkConnections/NetworkConnections'),
);
const FileOperationsSandboxesTabs = lazy(
  () => import('../RavenSight/Analysis/Analysis/DynamicAnalysis/FileOperations/FileOperationsSandboxesTabs'),
);
const MemoryProcessesSandboxesTabs = lazy(
  () => import('../RavenSight/Analysis/Analysis/DynamicAnalysis/MemoryProcesses/MemoryProcessesSandboxesTabs'),
);
const NetworkConnectionsPcapDash = lazy(
  () =>
    import('../RavenSight/Analysis/Analysis/PrincipalAnalysis/NetworkConnectionsPcapDash/NetworkConnectionsPcapDash'),
);
const AnalysisReportPcapDash = lazy(
  () => import('../RavenSight/Analysis/Analysis/PrincipalAnalysis/AnalysisReportPcapDash/AnalysisReportPcapDash'),
);
const ReportApkDash = lazy(
  () => import('../RavenSight/Analysis/Analysis/StaticAnalysis/AnalysisReportApkDash/ReportApkDash'),
);
const ReportQuarkDash = lazy(
  () => import('../RavenSight/Analysis/Analysis/StaticAnalysis/AnalysisReportApkDash/ReportQuarkDash'),
);
const ImageAnalysisDash = lazy(
  () => import('../RavenSight/Analysis/Analysis/IntelligenceAnalysis/ImageAnalysis/ImageAnalysisDash'),
);
const TextAnalysisDash = lazy(
  () => import('../RavenSight/Analysis/Analysis/IntelligenceAnalysis/TextAnalysis/TextAnalysisDash'),
);
const FacesGridAnalysis = lazy(
  () => import('../RavenSight/Analysis/Analysis/IntelligenceAnalysis/FacesData/FacesGridAnalysis'),
);
const TweetsTable = lazy(() => import('../RavenSight/Analysis/Analysis/IntelligenceAnalysis/TweetsData/TweetsTable'));
const OperationsGraph = lazy(
  () => import('../RavenSight/Analysis/Analysis/IntelligenceAnalysis/OperationsData/OperationsGraph'),
);
const RunningAnalysisSandboxes = lazy(
  () => import('../RavenSight/Analysis/Analysis/RunningAnalysisSandboxes/RunningAnalysisSandboxes'),
);
const ServicesContainer = lazy(
  () => import('../RavenSight/Analysis/Analysis/DynamicAnalysis/Services/ServicesContainer'),
);
const AssociatedSamplesTable = lazy(
  () => import('../RavenSight/Analysis/Analysis/PrincipalAnalysis/AssociatedSamples/AssociatedSamples'),
);
const IOCElementPageBySample = lazy(
  () => import('../RavenSight/Analysis/Analysis/PrincipalAnalysis/IOC/IocElementPageBySample'),
);
const StaticAnalysisTabDynamic = lazy(
  () => import('../RavenSight/Analysis/Analysis/DynamicAnalysis/StaticAnalysis/StaticAnalysisTabDynamic'),
);
const VideoAnalysis = lazy(
  () => import('../RavenSight/Analysis/Analysis/IntelligenceAnalysis/VideoAnalysis/VideoAnalysis'),
);
const MailSummaryTemplate = lazy(
  () => import('../RavenSight/Analysis/Analysis/StaticAnalysis/MailSummary/MailSummaryTemplate'),
);
const NetworkHopsTemplate = lazy(
  () => import('../RavenSight/Analysis/Analysis/StaticAnalysis/NetworkHops/NetworkHopsTemplate'),
);
const ForensicDataTemplate = lazy(
  () => import('../RavenSight/Analysis/Analysis/StaticAnalysis/ForensicData/ForensicDataTemplate'),
);
const InvestigationPanelPage = lazy(() => import('@app/pages/DashboardPages/Investigation/InvestigationPanelPage'));
const AnalysisElementPage = lazy(() => import('@app/pages/Analyses/AnalysisElementPage'));
const NetworkStatisticsDashboardPage = lazy(
  () => import('@app/pages/DashboardPages/NetworkStatisticsDashboard/NetworkStatisticsDashboardPage'),
);
const RavensightAnalysisListPage = lazy(
  () => import('@app/pages/DashboardPages/Ravensight/RavensightAnalysisListPage'),
);
const VmViewerPage = lazy(() => import('../../pages/vm/VmViewerPage'));

const RavensightDashboard = withAccess(RavensightDashboardPage);
const RavensightAnalysisList = withAccess(RavensightAnalysisListPage);
const InvestigationPanel = withAccess(InvestigationPanelPage);
const AnalysisElement = withAccess(AnalysisElementPage);
const VMViewer = withAccess(VmViewerPage);

const ExecutiveIocs = withAccess(IOCsTableBySample);
const RunningVms = withAccess(RunningAnalysisSandboxes);
const ExecutiveIntelligence = withAccess(FeedsIntelligenceTemplate);
const ExecutiveNetwortkConnections = withAccess(NetworkConnectionsDash);
const ExecutiveAnalystFeedback = withAccess(EditExecutiveReport);
const ExecutiveAssociatedSamples = withAccess(AssociatedSamplesTable);
const ExecutiveMispCorrelation = withAccess(MispCorrelationGraph);
const ExecutiveStixGraph = withAccess(StixGraph);
const MitreMatrix = withAccess(MitreMatrixTemplate);
const ExecutiveDroppedFiles = withAccess(DroppedFilesTable);
const AnalysisPcap = withAccess(AnalysisReportPcapDash);
const NetworkConnectionsPcap = withAccess(NetworkConnectionsPcapDash);

const StaticBinaryImages = withAccess(BinaryImagesFileSample);
const ExecutiveSummary = withAccess(StaticExecutiveSummary);
const EntropyStaticTab = withAccess(EntropyStaticAnalysis);
const SignaturesAnalysis = withAccess(SignaturesAnalysisTabs);
const RadareGraphTab = withAccess(RadareGraph);
const AntivirusStaticAnalysisTab = withAccess(AntivirusStaticAnalysis);
const AdditionalInfoStaticAnalysisTab = withAccess(AdditionalInfoStaticAnalysis);
const StaticFilesObtained = withAccess(FilesObtainedStaticAnalysis);
const StaticJa3 = withAccess(Ja3Table);
const StaticMailDetails = withAccess(MailSummaryTemplate);
const StaticNetworkHops = withAccess(NetworkHopsTemplate);
const StaticForensicData = withAccess(ForensicDataTemplate);
const StaticUrl = withAccess(StaticUrlSummary);
const StaticUrlTrace = withAccess(UrlTrace);
const StaticObtainedUrls = withAccess(TableItemsObtainedUrls);
const StaticSourceCodeUrl = withAccess(SourceCodeUrl);
const StaticCertificateAnalysis = withAccess(CertificateAnalysis);
const StaticSignaturesUrl = withAccess(SignaturesStaticAnalysisTabs);
const StaticApkSsummary = withAccess(ReportApkDash);
const StaticReportQuark = withAccess(ReportQuarkDash);

const DynamicExecutiveSummaryTab = withAccess(DynamicExecutiveSummary);
const ProcessesTabsTab = withAccess(ProcessesTabs);
const SignaturesAnalysisTabsTab = withAccess(SignaturesStaticAnalysisTabs);
const NetworkConnectionsTab = withAccess(NetworkConnections);
const FileOperationsSandboxes = withAccess(FileOperationsSandboxesTabs);
const MemoryProcessesSandboxes = withAccess(MemoryProcessesSandboxesTabs);
const DynamicStaticAnalysis = withAccess(StaticAnalysisTabDynamic);
const DynamicServices = withAccess(ServicesContainer);

const IntelligenceGraph = withAccess(OperationsGraph);
const IntelligenceImageAnalysisDash = withAccess(ImageAnalysisDash);
const IntelligenceTextAnalysisDash = withAccess(TextAnalysisDash);
const IntelligenceFaces = withAccess(FacesGridAnalysis);
const IntelligenceTweets = withAccess(TweetsTable);
const IntelligenceVideo = withAccess(VideoAnalysis);

const rsightRouter = [
  {
    path: '',
    element: <RavensightDashboard />,
  },
  {
    path: 'analyses',
    children: [
      {
        path: '',
        element: <RavensightAnalysisList />,
      },
      {
        path: ':sampleId',
        element: <VmProvider />,
        children: [
          {
            path: '',
            element: <AnalysisElement />,
            children: [
              {
                path: '',
                element: <PrincipalDashAnalysisTemplate />,
              },
              {
                path: '',
                element: <CheckRoute />,
                children: [
                  {
                    path: ':analysisSection',
                    children: [
                      {
                        path: 'running-vms',
                        element: <RunningVms />,
                      },
                      {
                        path: 'executive-iocs',
                        element: <ExecutiveIocs />,
                      },
                      {
                        path: 'executive-iocs', //TODO: Creo que no hay iocs más que en el ejecutivo, se puede llamar "iocs"
                        element: <ExecutiveIocs />,
                      },
                      {
                        path: 'ioc/:iocId', //TODO: Si executive-iocs pasa a ser iocs, colgar esta ruta como children directamente con el id
                        element: <IOCElementPageBySample />,
                      },
                      {
                        path: 'intelligence',
                        element: <ExecutiveIntelligence />,
                      },
                      {
                        path: 'network-connections',
                        element: <ExecutiveNetwortkConnections />,
                      },
                      {
                        path: 'misp-correlation',
                        element: <ExecutiveMispCorrelation />,
                      },
                      {
                        path: 'stix-graph',
                        element: <ExecutiveStixGraph />,
                      },
                      {
                        path: 'analyst-feedback',
                        element: <ExecutiveAnalystFeedback />,
                      },
                      {
                        path: 'associated-samples',
                        element: <ExecutiveAssociatedSamples />,
                      },
                      {
                        path: 'executive-mitre-matrix',
                        element: <MitreMatrix />,
                      },
                      {
                        path: 'dropped-files',
                        element: <ExecutiveDroppedFiles />,
                      },
                      {
                        path: 'pcap-analysis',
                        element: <AnalysisPcap />,
                      },
                      {
                        path: 'pcap-network-connections',
                        element: <NetworkConnectionsPcap />,
                      },
                      {
                        path: 'executive-summary',
                        element: <ExecutiveSummary />,
                      },
                      {
                        path: 'binary-images',
                        element: <StaticBinaryImages />,
                      },
                      {
                        path: 'entropy',
                        element: <EntropyStaticTab />,
                      },
                      {
                        path: 'signatures-dynamic',
                        element: <SignaturesAnalysis />,
                      },
                      {
                        path: 'radare',
                        element: <RadareGraphTab />,
                      },
                      {
                        path: 'antivirus',
                        element: <AntivirusStaticAnalysisTab />,
                      },
                      {
                        path: 'additional-info',
                        element: <AdditionalInfoStaticAnalysisTab />,
                      },
                      {
                        path: 'static-mitre-matrix',
                        element: <MitreMatrix tab="static" />,
                      },
                      {
                        path: 'files-obtained',
                        element: <StaticFilesObtained />,
                      },
                      {
                        path: 'ja3',
                        element: <StaticJa3 />,
                      },
                      {
                        path: 'mail-details',
                        element: <StaticMailDetails />,
                      },
                      {
                        path: 'network-hops',
                        element: <StaticNetworkHops />,
                      },
                      {
                        path: 'forensic-data',
                        element: <StaticForensicData />,
                      },
                      {
                        path: 'url-summary',
                        element: <StaticUrl />,
                      },
                      {
                        path: 'url-trace',
                        element: <StaticUrlTrace />,
                      },
                      {
                        path: 'url-obtained',
                        element: <StaticObtainedUrls />,
                      },
                      {
                        path: 'source',
                        element: <StaticSourceCodeUrl />,
                      },
                      {
                        path: 'certificate-analysis',
                        element: <StaticCertificateAnalysis />,
                      },
                      {
                        path: 'apk-summary',
                        element: <StaticApkSsummary />,
                      },
                      {
                        path: 'report-quark',
                        element: <StaticReportQuark />,
                      },
                      {
                        path: 'dynamic-summary',
                        element: <DynamicExecutiveSummaryTab />,
                      },
                      {
                        path: 'processes',
                        element: <ProcessesTabsTab />,
                      },
                      {
                        path: 'signatures',
                        element: <SignaturesAnalysisTabsTab />,
                      },
                      {
                        path: 'dynamic-network-connections',
                        element: <NetworkConnectionsTab />,
                      },
                      {
                        path: 'file-operations',
                        element: <FileOperationsSandboxes />,
                      },
                      {
                        path: 'memory',
                        element: <MemoryProcessesSandboxes />,
                      },
                      {
                        path: 'mitre-matrix',
                        element: <MitreMatrix />,
                      },
                      {
                        path: 'services',
                        element: <DynamicServices />,
                      },
                      {
                        path: 'static-analysis',
                        element: <DynamicStaticAnalysis />,
                      },
                      {
                        path: 'image-analysis',
                        element: <IntelligenceImageAnalysisDash />,
                      },
                      {
                        path: 'text-analysis',
                        element: <IntelligenceTextAnalysisDash />,
                      },
                      {
                        path: 'operations',
                        element: <IntelligenceGraph />,
                      },
                      {
                        path: 'objects',
                        element: <IntelligenceVideo />,
                      },
                      {
                        path: 'faces',
                        element: <IntelligenceFaces />,
                      },
                      {
                        path: 'tweets',
                        element: <IntelligenceTweets />,
                      },
                    ],
                  },
                ],
              },
            ],
          },
          {
            path: 'vm/:vmId',
            element: <VMViewer />,
          },
          {
            path: 'ioc/:iocId', //TODO: Revisar, creo que este no se usa
            element: <IOCElementPageBySample />,
          },
        ],
      },
    ],
  },
  {
    path: 'upload',
    element: <RavensightUploadPage />,
  },
  {
    path: 'investigation',
    element: <InvestigationPanel />,
  },
  {
    path: 'network-statistics',
    element: <NetworkStatisticsDashboardPage />,
  },
];

export default rsightRouter;
