import { httpApi } from '@app/api/http.api';
import { languagesByShortcode } from '@app/constants/languages';
import { APIResponse, AnalisysRsightJSONData, AnalysisRpostMailData } from '@app/interfaces/apiInterfaces';
import { AnalysisRsightSBScore, AnalysisRsightScore, Sandboxes } from '@app/interfaces/interfaces';
import { readLanguage } from '@app/services/localStorage.service';

//TODO: Traer la interfaz a la carpeta interfaces
export const getAnalysisRsightJsonDataBySampleId = async (
  sampleId: string | undefined,
): Promise<AnalisysRsightJSONData> => {
  const language = readLanguage();
  const args = {
    sample_id: sampleId,
    language: language && languagesByShortcode?.[language]?.title?.toLowerCase(),
  };
  const { data } = await httpApi.get('/rsight/sample/json', { params: args });
  return data;
};

//TODO: Traer la interfaz a la carpeta interfaces
export const getAnalysisRpostMailDataBySampleId = async (
  sampleId: string | undefined,
): Promise<AnalysisRpostMailData> => {
  const language = readLanguage();
  const args = {
    sample_id: sampleId,
    language: language && languagesByShortcode?.[language]?.title?.toLowerCase(),
  };
  const { data } = await httpApi.get('/rsight/sample/mail_data', { params: args });
  return data;
};

//TODO: Traer la interfaz a la carpeta interfaces
export const getAnalysisRsightScoresBySampleId = async (
  sampleId: string | number | undefined,
): Promise<AnalysisRsightScore> => {
  const args = { sample_id: sampleId };
  const { data } = await httpApi.get('/rsight/sample/scores', { params: args });
  return data;
};

//TODO: Traer la interfaz a la carpeta interfaces
export const getAnalysisRsightSandboxesScoresBySampleId = async (
  sampleId: string | number | undefined,
): Promise<{ scores: AnalysisRsightSBScore[] }> => {
  const args = { sample_id: sampleId };
  const { data } = await httpApi.get('/rsight/sample/sandboxes-scores', { params: args });
  return data;
};

//TODO: Traer la interfaz a la carpeta interfaces
export const getAnalysisRsightSandboxesAvailable = async (): Promise<{ sandboxes_names: Sandboxes[] }> => {
  const { data } = await httpApi.get('/rsight/sample/sandboxes-available');
  return data;
};

export const getAnalystFeedback = async (
  sampleID: string | undefined,
  feedbackData: { analyst_feedback_select: string; analyst_feedback_text: string },
): Promise<any> => {
  const { data } = await httpApi.put(`/rsight/sample/${sampleID}/analyst-feedback`, feedbackData);
  return data;
};
