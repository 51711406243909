import { graphic } from 'echarts';
import { BASE_COLORS } from '../constants';
import { ITheme } from '../types';
import { shadeColor } from '@app/utils/themeUtils';

const chartColors = {
  chartTooltipLabel: '#6A7985',
  chartColor1: '#01509A',
  chartColor1Tint: '#2983D8',
  chartColor2: '#35A0DC',
  chartColor2Tint: '#67C5FA',
  chartColor3: '#FFB155',
  chartColor3Tint: '#FFA800',
  chartColor4: '#31A652',
  chartColor4Tint: '#89DCA0',
  chartColor5: '#FF5252',
  chartColor5Tint: '#FFC1C1',
  chartColor6: '#4B5B73',
  chartColor7: '#8F547A',
  chartColor8: '#753F3F',
  chartColor9: '#8D704F',
  chartColor10: '#A79F8E',
  chartPrimaryGradient: new graphic.LinearGradient(0, 0, 0, 1, [
    {
      offset: 0,
      color: 'rgba(0, 110, 211, 0.5)',
    },
    {
      offset: 1,
      color: 'rgba(255, 225, 255, 0)',
    },
  ]),
  chartSecondaryGradient: new graphic.LinearGradient(0, 0, 0, 1, [
    {
      offset: 0,
      color: 'rgba(255, 82, 82, 0.5)',
    },
    {
      offset: 1,
      color: 'rgba(255, 255, 255, 0)',
    },
  ]),
  chartSecondaryGradientSpecular: new graphic.LinearGradient(0, 0, 0, 1, [
    {
      offset: 0,
      color: 'rgba(255, 255, 255, 0)',
    },
    {
      offset: 1,
      color: 'rgba(255, 82, 82, 0.5)',
    },
  ]),
};

export const lightColorsTheme: ITheme = {
  tcpColor: '#599a3a',
  udpColor: '#b64932',
  primary: '#01509A',
  primary1: '#7568f6',
  primaryGradient: 'linear-gradient(211.49deg, #006CCF 15.89%, #00509A 48.97%)',
  light: '#C5D3E0',
  secondary: '#0085FF',
  error: '#FF5252',
  warning: '#FFB155',
  success: '#30AF5B',
  background: BASE_COLORS.white,
  secondaryBackground: '#dfdfe5',
  secondaryBackgroundSelected: shadeColor('#F8FBFF', -5),
  additionalBackground: '#ffffff',
  additionalBackgroundCardInside: '#ffffff',
  tagRavenloopColor: '#218ece',
  advancedSearchColor: '#af32ad',
  markerActualGeolocationWorldMap: '#00FF7F',
  markerGeolocationHistoryWorldMap: '#FFAC1C',
  collapseBackground: 'rgb(0, 108, 207)',
  timelineBackground: '#F8FBFF',
  siderBackground: '#ffffff',
  spinnerBase: '#f42f25',
  scroll: '#c5d3e0',
  border: '#cce1f4',
  borderTable: '#79819A42',
  borderCard: '#79819A42',
  borderNft: '#79819A',
  ravenloopColor: '#0BA1DC',
  textMain: '#404040',
  textMainHint: 'rgba(0, 0, 0, 0.45)',
  textLight: '#9A9B9F',
  textSuperLight: '#BEC0C6',
  textSecondary: BASE_COLORS.white,
  textDark: '#404040',
  textNftLight: '#79819A',
  textSiderPrimary: '#FFB765',
  textSiderSecondary: '#ffffff',
  dividerSiderSecondary: '#ffffff',
  subText: 'rgba(0, 0, 0, 0.45)',
  disabledColor: '#0000006e',
  shadow: 'rgba(0, 0, 0, 0.07)',
  boxShadow: '0 2px 8px 0 rgba(0, 0, 0, 0.07)',
  boxShadowHover: '0 4px 16px 0 rgba(0, 0, 0, 0.2)',
  boxShadowNft: '0px 16px 24px rgba(0, 0, 0, 0.06), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04)',
  boxShadowNftSecondary:
    '0px 10px 20px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04)',
  dashboardMapBackground: '#EAF5FF',
  dashboardMapCircleColor: '#9BC2E7',
  dashboardMapControlDisabledBackground: '#c5d3e0',
  notificationSuccess: '#EFFFF4',
  notificationPrimary: '#D7EBFF',
  notificationWarning: '#FFF4E7',
  notificationError: '#FFE2E2',
  heading: '#13264d',
  borderBase: '#bec0c6',
  disabledBg: '#c5d3e0',
  layoutBodyBg: '#f8fbff',
  layoutHeaderBg: 'transparent',
  layoutSiderBg: 'linear-gradient(261.31deg, #006ccf -29.57%, #00509a 121.11%)',
  inputPlaceholder: '#404040',
  itemHoverBg: '#f5f5f5',
  backgroundColorBase: '#F5F5F5',
  avatarBg: '#ccc',
  alertTextColor: BASE_COLORS.white,
  breadcrumb: 'rgba(0, 0, 0, 0.45)',
  icon: '#a9a9a9',
  iconHover: 'rgba(0, 0, 0, 0.75)',
  tagLinksGraph: 'black',
  tagStatisticsGreen: '#176f17',
  bigNumberGreen: '#07946f',
  bigNumberYellow: '#8d8a31',
  tagStatisticsRed: '#a40505',
  bigNumberRed: '#c02247',
  markerMailRpost: 'rgba(4,101,119,0.8)',
  tcpGraphColor: '#1c9b1c',
  udpGraphColor: '#1e50b4',
  purpleScoreColor: '#9b3f9a',
  redScoreColor: '#b02828',
  orangeScoreColor: '#ab8538',
  yellowScoreColor: '#8a9b3d',
  greenScoreColor: '#4fab52',
  blueScoreColor: '#347db0',
  grayScoreColor: '#838486',
  analystReportColor: '#FFFFFF',
  defaultReportColor: '#FFFFFF',
  invert: 0,
  leafletTileFilter: '',
  finished: '#0a7da8',
  scheduled: '#932c87',
  uploaded: '#049378',
  pending: '#049378',
  audited: '#2c7e17',
  auditing: '#a97b2f',
  stopped: '#8B0000',
  killed: '#853261',
  titleWrapperLine: '#0b22459c',
  tagNeonColor: '#000000',
  tagNeonBorderColor: '#ffffff',
  tagNeonBgColor: '#000000',
  ...chartColors,
  disable: '',
};
