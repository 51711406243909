import React, { useContext } from 'react';
import { Col, Row } from 'antd';
import { NotificationsDropdown } from '../components/notificationsDropdown/NotificationsDropdown';
import { ProfileDropdown } from '../components/profileDropdown/ProfileDropdown/ProfileDropdown';
import { HeaderFullscreen } from '../components/HeaderFullscreen/HeaderFullscreen';
import * as S from '../Header.styles';
import HeaderContext from '@app/hooks/HeaderContext';
import { HeaderBreadcrumb, MenuHeaderSeparator } from '@app/components/header/HeaderBreadcrumb';
import { FONT_SIZE } from '@app/styles/themes/constants';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { MdHelpOutline } from 'react-icons/md';
import { accessLevel } from '@app/domain/accessLevel';
import { useAppSelector } from '@app/hooks/reduxHooks';
import { selectUserRole } from '@app/store/slices/userSlice';
import { userRoles } from '@app/domain/rolesModel';

const StyledLink = styled(Link)`
  color: inherit;
  font-size: ${FONT_SIZE.xxxl};

  .icon {
    vertical-align: text-bottom;
  }
`;
interface DesktopHeaderProps {
  isTwoColumnsLayout: boolean;
}

export const DesktopHeader: React.FC<DesktopHeaderProps> = ({ isTwoColumnsLayout }) => {
  const { headerBreadcrumb } = useContext(HeaderContext);
  const { headerNavigation } = useContext(HeaderContext);
  const userRole = useAppSelector(selectUserRole) || userRoles.user;

  const leftSide = (
    <S.SearchColumn xl={20} xxl={20} style={{ height: '100%' }}>
      <Row gutter={[0, 10]} style={{ height: '100%' }} wrap={false}>
        {headerBreadcrumb && (
          <Col style={{ height: '100%' }}>
            <HeaderBreadcrumb items={headerBreadcrumb} />
          </Col>
        )}
        {headerBreadcrumb && headerNavigation && (
          <Col style={{ height: '100%' }}>
            <MenuHeaderSeparator>|</MenuHeaderSeparator>
          </Col>
        )}
        {headerNavigation && (
          <Col flex={'auto'} style={{ height: '100%' }}>
            {headerNavigation}
          </Col>
        )}
      </Row>
    </S.SearchColumn>
  );

  return (
    <Row justify="space-between" align="middle" style={{ height: '100%' }} wrap={false}>
      {leftSide}

      <S.ProfileColumn xl={4} xxl={4} $isTwoColumnsLayout={isTwoColumnsLayout} style={{ height: '100%' }}>
        <NotificationsDropdown />
        <S.HeaderDropdownWrapper>
          <ProfileDropdown />
        </S.HeaderDropdownWrapper>
        {accessLevel.CONFIDENTIAL.includes(userRole) ? ( // TODO: Remove access level when help page completes
          <StyledLink to={'/help'}>
            <MdHelpOutline className="icon" />
          </StyledLink>
        ) : (
          <></>
        )}
        <HeaderFullscreen />
      </S.ProfileColumn>
    </Row>
  );
};
