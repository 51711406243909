import { useQueryHandler } from '@app/api/hooks/useQueryHandler';
import { useQuery } from '@tanstack/react-query';
import { getAnalysisRsightJsonDataBySampleId } from '../requests/analyses.api';
import { rsightJsonDataAdapter } from '../adapters/rsightJsonData.adapter';
import { AnalisysRsightJSONData } from '@app/interfaces/apiInterfaces';
import { useTranslation } from 'react-i18next';

export const useRsightJsonBySampleId = (sampleId: string | undefined) => {
  const { t } = useTranslation();
  const initialData: AnalisysRsightJSONData = {
    json_sample: undefined,
    sample_data: undefined,
  };

  return useQueryHandler(
    useQuery({
      queryKey: ['getAnalysisRsightJsonDataBySampleId', sampleId],
      queryFn: () => getAnalysisRsightJsonDataBySampleId(sampleId),
      select: (data) => rsightJsonDataAdapter(data),
      initialData: initialData,
      initialDataUpdatedAt: 1608412420052,
      staleTime: 600000, //10 mins
    }),
    {
      errorMessage: t('common.loadingDataError'),
    },
  );
};
