import Icon, {
  FileFilled,
  MailFilled,
  FileZipFilled,
  DribbbleCircleFilled,
  BugFilled,
  CrownFilled,
  LockFilled,
  CodeFilled,
  UserOutlined,
  SearchOutlined,
  FileSearchOutlined,
  RadarChartOutlined,
  VideoCameraFilled,
  SoundFilled,
  TwitterCircleFilled,
  AndroidFilled,
} from '@ant-design/icons';
import { BsAndroid } from 'react-icons/bs';
import { Tooltip, Row, Col } from 'antd';
import React from 'react';
import parse from 'html-react-parser';
import { EmptyDefaultTag } from '@app/components/common/TagsRavenloop/EmptyDefaultTag';
import { themeObject } from '@app/styles/themes/themeVariables';
import { TagScore } from '@app/components/common/TagNeon/TagScore';
import { AVListItem, AnalysisRsightSBScore, SampleTypes, Sandboxes, ThemeType } from '@app/interfaces/interfaces';
import { JSONSampleSource, MultiAV, MultiAVItem, SourceSandbox, TraceContent } from '@app/interfaces/apiInterfaces';
import { TFunction } from 'react-i18next';
import { BASE_COLORS } from '@app/styles/themes/constants';
import { getReactIconSandboxByName } from '@app/utils/IconsDictionaries/sandboxesIcon';

export const getVerdictIcon = (
  isAnalyst: boolean,
  verdict: string,
  type: string | undefined,
  sampleName: string | undefined,
  finalScore?: number,
  widthIcon: string,
  t: TFunction<'translation', undefined>,
): React.ReactNode => {
  var result = <EmptyDefaultTag title="No verdict" />;
  if (isAnalyst) {
    const icon = getIconRavensightAnalyst();
    result = (
      <Tooltip
        destroyTooltipOnHide={true}
        placement="top"
        trigger="hover"
        title={calculateVerdictRavensight(t, verdict, 'analyst')}
      >
        <i style={{ fontSize: widthIcon }}>{icon}</i>
      </Tooltip>
    );
  } else {
    var icon = getIconTypeRavensight(type, sampleName);
    var verdictRavensight = calculateVerdictRavensight(t, verdict, 'platform', t);
    var scoreRavensight = calculateScoreVerdictRavensight(finalScore, t);
    var colorIconRavensight = calculateColorIconRavensight(finalScore);
    var textVerdict = verdictRavensight;
    if (textVerdict && textVerdict !== '') {
      textVerdict += '<br/>';
    }
    textVerdict += scoreRavensight;
    result = (
      <Tooltip destroyTooltipOnHide={true} placement="top" trigger="hover" title={<span>{parse(textVerdict)}</span>}>
        <i style={{ color: colorIconRavensight, fontSize: widthIcon }}>{icon}</i>
      </Tooltip>
    );
  }
  return result;
};

export const getVerdictIconText = (
  isAnalyst: boolean,
  verdict: string,
  type: string,
  sampleName: string,
  finalScore: number,
  widthIcon: string,
  t: TFunction<'translation', undefined>,
): React.ReactNode => {
  var result = <EmptyDefaultTag title="No verdict" />;
  if (isAnalyst) {
    const icon = getIconRavensightAnalyst();
    result = (
      <Tooltip
        destroyTooltipOnHide={true}
        placement="top"
        trigger="hover"
        title={calculateVerdictRavensight(t, verdict, 'analyst')}
      >
        <i style={{ fontSize: widthIcon }}>{icon}</i>
      </Tooltip>
    );
  } else {
    var icon = getIconTypeRavensight(type, sampleName);
    var verdictRavensight = calculateVerdictRavensight(t, verdict, 'platform');
    var scoreRavensight = calculateScoreVerdictRavensight(finalScore, t);
    var colorIconRavensight = calculateColorIconRavensight(finalScore);
    var textVerdict = verdictRavensight;
    if (textVerdict && textVerdict !== '') {
      textVerdict += '<br/>';
    }
    textVerdict += scoreRavensight;
    result = (
      <>
        <Tooltip destroyTooltipOnHide={true} placement="top" trigger="hover" title={<span>{parse(textVerdict)}</span>}>
          <i style={{ color: colorIconRavensight, fontSize: widthIcon }}>{icon}</i>
        </Tooltip>
        <span style={{ marginLeft: '10px' }}>{sampleName}</span>
      </>
    );
  }
  return result;
};

export const calculateVerdictRavensight = (
  t: TFunction<'translation', undefined>,
  verdict: string,
  vtype: string = '',
): string => {
  let response_ver = '';
  let vtype_text = '';

  if (vtype == 'analyst') {
    vtype_text = t('ravensight.analystVerdict');
  } else {
    vtype_text = t('ravensight.confidenceLevelVerdict');
  }

  if (verdict) {
    if (verdict.toLowerCase() == 'firm') {
      response_ver = vtype_text + t('ravensight.firm');
    } else if (verdict.toLowerCase() == 'highly suspicious') {
      response_ver = vtype_text + t('ravensight.highlySuspicious');
    } else if (verdict.toLowerCase() == 'possible') {
      response_ver = vtype_text + t('ravensight.possible');
    } else if (verdict.toLowerCase() == 'suspicious') {
      response_ver = vtype_text + t('ravensight.suspicious');
    } else if (verdict.toLowerCase() == 'inconclusive') {
      response_ver = vtype_text + t('ravensight.inconclusive');
    } else if (verdict.toLowerCase() == 'clean') {
      response_ver = vtype_text + t('ravensight.clean');
    } else {
      response_ver = vtype_text + t('ravensight.notAnalyzed');
    }
  }
  return response_ver;
};

export const calculateScoreVerdictRavensight = (verdict: number, t: TFunction<'translation', undefined>): string => {
  let response_ver = '';
  let vtype_text = t('ravensight.platformVerdict');
  // let vtype_text = "Platform Verdict: "

  response_ver = vtype_text + calculateScoreTextRavensight(verdict, t);
  return response_ver;
};

export const calculateScoreTextRavensight = (
  verdict: number | undefined | null,
  t: TFunction<'translation', undefined>,
): string => {
  let response_ver = '';
  if (verdict || verdict === 0) {
    // if (verdict >= 5) {
    //     response_ver = t('ravensight.critic')
    // } else if (verdict == 4) {
    //     response_ver = t('ravensight.malicious')
    // } else if (verdict == 3) {
    //     response_ver = t('ravensight.likelyMalicious')
    // } else if (verdict == 2) {
    //     response_ver = t('ravensight.suspicious')
    // } else if (verdict == 1) {
    //     response_ver = t('ravensight.clean')
    // } else {
    //     response_ver = t('ravensight.inconclusive')
    // }
    if (verdict >= 4) {
      response_ver = t('ravensight.malicious');
    } else if (verdict >= 2) {
      response_ver = t('ravensight.suspicious');
    } else {
      response_ver = t('ravensight.harmless');
    }
  } else {
    response_ver = t('ravensight.unscored');
  }
  return response_ver;
};

// export const calculateScoreTextToGaugeChartRavensight = (score: number, t: Record<string, unknown>): string => {
//     let response_ver = ""
//     if (score) {
//         if (score >= 5) {
//             response_ver = t('ravensight.critic')
//         } else if (score >= 4) {
//             response_ver = t('ravensight.high')
//         } else if (score >= 2) {
//             response_ver = t('ravensight.moderate')
//         } else if (score >= 1) {
//             response_ver = t('ravensight.low')
//         } else {
//             response_ver = t('ravensight.harmless')
//         }
//     } else {
//         response_ver = t('ravensight.harmless')
//     }
//     return response_ver
// }

export const calculateColorScoreVerdictRavensight = (
  score: number | string | undefined | null,
  theme: ThemeType,
): string => {
  if (score !== 0 && !score) {
    // score = 0;
    return theme ? themeObject[theme].grayScoreColor : BASE_COLORS.lightgrey;
  }
  if (typeof score === 'string') score = parseInt(score);

  if (score >= 4) {
    return theme ? themeObject[theme].redScoreColor : '#ef5c5c';
  } else if (score >= 2 && score <= 3) {
    return theme ? themeObject[theme].yellowScoreColor : '#ebff82';
  } else {
    return theme ? themeObject[theme].greenScoreColor : '#8aea8f';
  }
};

export const calculateAnomaliesColorScore = (score: number | string, theme: ThemeType): string => {
  if (score !== 0 && !score) {
    return theme ? themeObject[theme].grayScoreColor : BASE_COLORS.lightgrey;
  }
  if (typeof score === 'string') score = parseInt(score);
  if (score >= 6) {
    return theme ? themeObject[theme].purpleScoreColor : '#d66bd1';
  }
  if (score == 5) {
    return theme ? themeObject[theme].redScoreColor : '#ef5c5c';
  }
  if (score == 4) {
    return theme ? themeObject[theme].orangeScoreColor : '#efa94a';
  }
  if (score == 3) {
    return theme ? themeObject[theme].yellowScoreColor : '#ebff82';
  }
  if (score == 2) {
    return theme ? themeObject[theme].greenScoreColor : '#8aea8f';
  }
  return theme ? themeObject[theme].blueScoreColor : '#2194e7';
};

export const showTextScore = (score: number): string => {
  if (!score) {
    score = 0;
  }
  return score + ' / 5';
};

export const getIconRavensightAnalyst = (): React.ReactNode => {
  const icon = <UserOutlined />;
  return icon;
};

export const getIconTypeRavensight = (stype: string, sample_name: string): React.ReactNode => {
  let icon = <FileFilled />;
  if (stype) {
    if (stype == 'FILE') {
      if (['eml', 'msg'].includes(sample_name.slice(sample_name.length - 3).toLowerCase())) {
        icon = <MailFilled />;
      } else if (
        ['zip', 'rar', '.7z', '.gz', 'tar', 'rar', 'r01', 'arj'].includes(
          sample_name.slice(sample_name.length - 3).toLowerCase(),
        )
      ) {
        icon = <FileZipFilled />;
      } else if (
        ['mpg', 'mp2', 'mpe', 'mpv', 'ogg', 'mp4', 'm4p', 'm4v', 'avi', 'wmv', 'mov', '.qt', 'flv', 'swf'].includes(
          sample_name.slice(sample_name.length - 3).toLowerCase(),
        )
      ) {
        icon = <VideoCameraFilled />;
      } else if (['.webm', '.mpeg', 'avchd'].includes(sample_name.slice(sample_name.length - 5).toLowerCase())) {
        icon = <VideoCameraFilled />;
      } else if (
        ['.aif', '.cda', '.mid', 'midi', '.mp3', '.mpa', '.ogg', '.wav', '.wma', '.wpl'].includes(
          sample_name.slice(sample_name.length - 4).toLowerCase(),
        )
      ) {
        icon = <SoundFilled />;
      } else if (['.apk'].includes(sample_name.slice(sample_name.length - 4).toLowerCase())) {
        icon = <BsAndroid className={'anticon'} />;
      } else {
        icon = <FileFilled />;
      }
    } else {
      if (sample_name.includes('twitter.com/')) {
        icon = <TwitterCircleFilled />;
      } else {
        icon = <DribbbleCircleFilled />;
      }
    }
  }
  return icon;
};

export const calculateColorIconRavensight = (score: string): string => {
  let score_sent = parseInt(score);
  // if (score_sent >= 4) {
  //     return "#ef486f";
  // } else if (score_sent >= 2) {
  //     return "#ffd166";
  // } else if (score_sent > 0) {
  //     return "#05d69e";
  // } else {
  //     return "hsl(195, 83%, 38%)";
  // }
  return calculateColorScoreVerdictRavensight(score_sent);
};

export const isIncludesStringTag = (stringTag: string, tags: string[]): boolean => {
  let result = false;
  if (stringTag) {
    stringTag = stringTag.toLowerCase();
    tags.forEach((tag) => {
      if (stringTag.includes(tag)) {
        result = true;
        return result;
      }
    });
  }
  return result;
};

export const getIconRavensightMalware = (category: string): React.ReactNode => {
  let isIncludedTag = isIncludesStringTag(category, ['malware']);
  let colorIcon = 'gray';
  if (isIncludedTag) {
    colorIcon = 'darkred';
  }
  let icon = (
    <Tooltip destroyTooltipOnHide={true} placement="top" trigger="hover" title="malware">
      <BugFilled style={{ color: colorIcon }} />
    </Tooltip>
  );
  return icon;
};

export const getIconsRavensightAVAnalysis = (
  record: Record<string, unknown>,
  width: string,
  t: Record<string, unknown>,
): React.ReactNode[] => {
  const icons = [];

  if (record['c0b47d3']) {
    const textIcon = getAvStatusText(record['c0b47d3'], t);
    const colorIcon = getSandboxStatusColor(record['c0b47d3']);
    const icon = (
      <Tooltip
        destroyTooltipOnHide={true}
        placement="top"
        trigger="hover"
        title={parse(textIcon)}
        // className={spinningConditionalClassname(record["c0b47d3"])}
      >
        <BugFilled spin={spinningConditionalBoolean(record['c0b47d3'])} style={{ fontSize: width, color: colorIcon }} />
      </Tooltip>
    );
    icons.push(icon);
  } else {
    const colorIcon = 'grey';
    const textIcon = t('rsight.notProcessed');
    const icon = (
      <Tooltip
        destroyTooltipOnHide={true}
        placement="top"
        trigger="hover"
        title={parse(textIcon)}
        // className={spinningConditionalClassname(record["c0b47d3"])}
        // className={"spinning_element"}
      >
        <BugFilled spin={spinningConditionalBoolean(record['c0b47d3'])} style={{ fontSize: width, color: colorIcon }} />
      </Tooltip>
    );
    icons.push(icon);
  }

  if (true) {
    const colorIcon = getSandboxStatusColor(record['staticanalysis']);
    const textIcon = getSandboxStatusText(
      t('rsight.staticAnalysis'),
      record['staticanalysis'],
      record['staticanalysis_verdict'],
      record['staticanalysis_score'],
      t,
    );
    const icon = (
      <Tooltip
        destroyTooltipOnHide={true}
        placement="top"
        trigger="hover"
        title={parse(textIcon)}
        // className={spinningConditionalClassname(record["c0b47d3"])}
      >
        <FileSearchOutlined
          spin={spinningConditionalBoolean(record['staticanalysis'])}
          style={{ fontSize: width, color: colorIcon }}
        />
      </Tooltip>
    );
    icons.push(icon);
  }

  if (record['c0b59d4']) {
    const colorIcon = getSandboxStatusColor(record['c0b59d4']);
    const textIcon = getIntelStatusText(record['c0b59d4'], t);
    const icon = (
      <Tooltip
        destroyTooltipOnHide={true}
        placement="top"
        trigger="hover"
        title={parse(textIcon)}
        // className={spinningConditionalClassname(record["c0b47d3"])}
      >
        <SearchOutlined
          spin={spinningConditionalBoolean(record['c0b59d4'])}
          style={{ fontSize: width, color: colorIcon }}
        />
      </Tooltip>
    );
    icons.push(icon);
  }
  return icons;
};

export const getIconsRavensightDynamicAnalysis = (
  record: Record<string, unknown>,
  width: string,
  sandboxNames: string[],
  t: Record<string, unknown>,
): React.ReactNode[] => {
  const icons = [];
  if (sandboxNames) {
    sandboxNames.forEach((sandboxName) => {
      if (sandboxName.toLowerCase() != 'staticanalysis') {
        const colorIcon = getSandboxStatusColor(record[sandboxName]);
        const textIcon = getSandboxStatusText(
          sandboxName,
          record[sandboxName],
          record[sandboxName + '_verdict'],
          record[sandboxName + '_score'],
          t,
        );
        const icon = (
          <Tooltip
            destroyTooltipOnHide={true}
            placement="top"
            trigger="hover"
            title={<span>{parse(textIcon)}</span>}
            // className={spinningConditionalClassname(record["c0b47d3"])}
          >
            {sandboxName.toString().toUpperCase() == 'RAVENTRACE' ? (
              <Icon
                component={getReactIconSandboxByName(sandboxName)}
                spin={spinningConditionalBoolean(record[sandboxName])}
                style={{ fontSize: width, color: colorIcon }}
              />
            ) : (
              <RadarChartOutlined
                spin={spinningConditionalBoolean(record[sandboxName])}
                style={{ fontSize: width, color: colorIcon }}
              />
            )}
          </Tooltip>
        );
        icons.push(icon);
      }
    });
  }
  return icons;
};

export const getIconsRavensightDynamicAnalysis2 = (
  record: Record<string, unknown>,
  width: string,
  t: Record<string, unknown>,
): React.ReactNode[] => {
  const icons = [];
  const sandboxNames = ['invisible', 'cape', 'staticanalysis', 'vmray', 'hatching'];
  if (sandboxNames) {
    sandboxNames.forEach((sandboxName) => {
      if (sandboxName.toLowerCase() != 'staticanalysis') {
        const colorIcon = getSandboxStatusColor(record[sandboxName]);
        const textIcon = getSandboxStatusText(
          sandboxName,
          record[sandboxName],
          record[sandboxName + '_verdict'],
          record[sandboxName + '_score'],
          t,
        );
        const icon = (
          <Tooltip
            destroyTooltipOnHide={true}
            placement="top"
            trigger="hover"
            title={<span>{parse(textIcon)}</span>}
            // className={spinningConditionalClassname(record["c0b47d3"])}
          >
            <RadarChartOutlined
              spin={spinningConditionalBoolean(record[sandboxName])}
              style={{ fontSize: width, color: colorIcon }}
            />
          </Tooltip>
        );
        icons.push(icon);
      }
    });
  }
  return icons;
};

export const getIconRavensightVirus = (category: string): React.ReactNode => {
  let isIncludedTag = isIncludesStringTag(category, ['virus', 'clamav', 'avira', 'windefender', 'eset']);
  let colorIcon = 'gray';
  if (isIncludedTag) {
    colorIcon = 'darkred';
  }
  let icon = (
    <Tooltip destroyTooltipOnHide={true} placement="top" trigger="hover" title="virus">
      <CrownFilled style={{ color: colorIcon }} />
    </Tooltip>
  );
  return icon;
};

export const getIconRavensightRansomware = (category: string): React.ReactNode => {
  let isIncludedTag = isIncludesStringTag(category, ['ransom', 'wannacry']);
  let colorIcon = 'gray';
  if (isIncludedTag) {
    colorIcon = 'darkred';
  }
  let icon = (
    <Tooltip destroyTooltipOnHide={true} placement="top" trigger="hover" title="ransomware">
      <LockFilled style={{ color: colorIcon }} />
    </Tooltip>
  );
  return icon;
};

export const getIconRavensightTrojan = (category: string): React.ReactNode => {
  let isIncludedTag = isIncludesStringTag(category, ['trojan', 'tr/', 'trojware']);
  let colorIcon = 'gray';
  if (isIncludedTag) {
    colorIcon = 'darkred';
  }
  let icon = (
    <Tooltip destroyTooltipOnHide={true} placement="top" trigger="hover" title="trojan">
      <CodeFilled style={{ color: colorIcon }} />
    </Tooltip>
  );
  return icon;
};

export const getStatusText = (status: string, t: Record<string, unknown>): string => {
  let status_text = '';
  if (status) {
    status = status.toUpperCase();
    if (status == 'FINISHED') {
      status_text = t('rsight.finished');
    } else if (status == 'UPLOADED') {
      status_text = t('rsight.uploaded');
    } else if (status == 'ANALYZED') {
      status_text = t('rsight.analyzed');
    } else if (status == 'ANALYZING') {
      status_text = t('rsight.analyzing');
    } else if (status == 'ERROR') {
      status_text = t('rsight.error');
    } else {
      status_text = status;
    }
  } else {
    status_text = status;
  }

  return status_text;
};

export const calculateColorStatus = (status: string): string => {
  if (status) {
    status = status.toUpperCase();
    if (status == 'FINISHED') {
      return '#00a6e2';
    } else if (status == 'UPLOADED') {
      return '#02C59B';
    } else if (status == 'ANALYZED') {
      return '#36bc14';
    } else if (status == 'ANALYZING') {
      return '#f5bb51';
    } else {
      return '#000000';
    }
  } else {
    return '#000000';
  }
};

export const getErrorTextStatus = (errorStatus: string, language: string): string => {
  let status_text = null;
  if (language == 'es') {
    status_text = errorStatus?.es?.message;
  } else {
    status_text = errorStatus?.en?.message;
  }
  return status_text;
};

export const getSourceColor = (source: string): string => {
  let source_color = '#fff';
  if (source) {
    if (source == 'Rsight') {
      source_color = '#FCBD40';
    } else {
      source_color = '#fff';
    }
  }
  return source_color;
};

export const spinningConditionalClassname = (status: string): string => {
  let className = '';
  if (status) {
    if (parseInt(status)) {
      if (parseInt(status) < 3) {
        className = 'spinning_element';
      }
    } else if (status != 'FINISHED' && status != 'ERROR' && status != 'INVESTIGATED') {
      className = 'spinning_element';
    }
  }
  return className;
};

export const spinningConditionalBoolean = (status: string): boolean => {
  if (status) {
    if (parseInt(status)) {
      if (parseInt(status) < 3) {
        return true;
      }
    } else if (status != 'FINISHED' && status != 'ERROR' && status != 'INVESTIGATED') {
      return true;
    }
  }
  return false;
};

export const getSandboxStatusText = (
  sandbox_name: string,
  status: string,
  verdict: string,
  score: number,
  t: Record<string, unknown>,
): string => {
  let status_pre = '';
  let status_text = '';
  if (sandbox_name) {
    if (sandbox_name.toLowerCase() == 'invisible') {
      sandbox_name = 'ravenbox';
    }
  }
  if (!verdict) {
    verdict = '-';
  }
  if (score !== 0 && !Number.isInteger(score)) {
    score = '-';
  }
  status_pre = sandbox_name.charAt(0).toUpperCase() + sandbox_name.slice(1);
  status_pre = status_pre + '<br/>' + t('rsight.status') + ': ';
  if (status) {
    if (status.toLowerCase() == 'finished') {
      status_text = t('rsight.finished');
    } else if (status.toLowerCase() == 'uploaded') {
      status_text = t('rsight.uploaded');
    } else if (status.toLowerCase() == 'analyzed') {
      status_text = t('rsight.analyzed');
    } else if (status.toLowerCase() == 'analyzing') {
      status_text = t('rsight.analyzing');
    } else if (status.toLowerCase() == 'error') {
      status_text = t('rsight.error');
    } else {
      status_text = status;
    }
    status_text = status_pre + status_text;
    status_text =
      status_text + '<br/>' + t('rsight.verdict') + ': ' + verdict + '<br/>' + t('rsight.score') + ': ' + score;
  } else {
    status_text = t('rsight.notAnalyzed');
    status_text = status_pre + status_text;
  }
  return status_text;
};

export const getIntelStatusText = (status: string, t: Record<string, unknown>): string => {
  let status_pre = '';
  let status_text = '';
  status_pre = t('rsight.intel') + '<br/>' + t('rsight.status') + ': ';
  if (status == 'UPLOADED') {
    status_text = t('rsight.queried');
  } else if (status == 'INVESTIGATING') {
    status_text = t('rsight.investigating');
  } else if (status == 'INVESTIGATED') {
    status_text = t('rsight.investigated');
  } else {
    status_text = t('rsight.error');
  }
  return status_pre + status_text;
};

export const getAvStatusText = (status: string, t: Record<string, unknown>): string => {
  let status_pre = '';
  let status_text = '';
  status_pre = t('rsight.avAnalysis') + '<br/>' + t('rsight.status') + ': ';

  if (parseInt(status) == 0) {
    status_text = t('rsight.uploaded');
  } else if (parseInt(status) == 1) {
    status_text = t('rsight.analyzing');
  } else if (parseInt(status) == 2) {
    status_text = t('rsight.analyzed');
  } else if (parseInt(status) >= 3) {
    status_text = t('rsight.finished');
  } else {
    status_text = t('rsight.error');
  }
  return status_pre + status_text;
};

export const getSandboxStatusColor = (status: string): string => {
  let color = null;
  if (status == 'UPLOADED' || parseInt(status) == 0) {
    color = '#9c89b8';
  } else if (status == 'ANALYZING' || status == 'INVESTIGATING' || parseInt(status) == 1 || parseInt(status) == 2) {
    color = '#f5bb51';
  } else if (status == 'ANALYZED') {
    color = '#05d69e';
  } else if (status == 'FINISHED' || parseInt(status) == 3 || status == 'INVESTIGATED') {
    color = '#00a6e2';
  } else if (status == 'ERROR' || parseInt(status) == 4) {
    color = '#f9530b';
  } else {
    color = '#9e9e9e';
  }
  return color;
};

export const getTlpText = (tlp?: string, t: TFunction<'translation', undefined>): string => {
  let status_text = '';
  const previus_text = 'TLP: ';
  if (!tlp) {
    return previus_text + t('tlp.white');
  }
  tlp = tlp.toLowerCase();
  if (tlp == 'white') {
    status_text = previus_text + t('tlp.white');
  } else if (tlp == 'green') {
    status_text = previus_text + t('tlp.green');
  } else if (tlp == 'amber') {
    status_text = previus_text + t('tlp.amber');
  } else if (tlp == 'red') {
    status_text = previus_text + t('tlp.red');
  } else {
    return previus_text + t('tlp.white');
  }
  return status_text;
};

export const getTypeSampleColor = (type?: string): string => {
  let color = null;
  if (!type) {
    return '#ffffff';
  }
  type = type.toLowerCase();
  if (type == 'file') {
    color = '#ab982b';
  } else if (type == 'url') {
    color = '#368da8';
  } else {
    return '#ffffff';
  }
  return color;
};

export const getTlpColor = (tlp: string | undefined): string => {
  let color = null;
  if (!tlp) {
    return '#ffffff';
  }
  tlp = tlp.toLowerCase();
  if (tlp == 'white') {
    color = '#ffffff';
  } else if (tlp == 'green') {
    color = '#37984a';
  } else if (tlp == 'amber') {
    color = '#d78a4b';
  } else if (tlp == 'red') {
    color = '#ce3b3b';
  } else {
    return '#ffffff';
  }
  return color;
};

export const calculateFeedbackStatusTextRavensight = (t: TFunction, feedbackStatus?: string): string => {
  let response_ver = '';

  if (feedbackStatus) {
    feedbackStatus = feedbackStatus.toString().toUpperCase();
    if (feedbackStatus == 'FIRM') {
      response_ver = t('ravensight.firm');
    } else if (feedbackStatus == 'HIGHLY SUSPICIOUS') {
      response_ver = t('ravensight.highlySuspicious');
    } else if (feedbackStatus == 'POSSIBLE') {
      response_ver = t('ravensight.possible');
    } else if (feedbackStatus == 'SUSPICIOUS') {
      response_ver = t('ravensight.suspicious');
    } else if (feedbackStatus == 'INCONCLUSIVE') {
      response_ver = t('ravensight.inconclusive');
    } else if (feedbackStatus == 'CLEAN') {
      response_ver = t('ravensight.clean');
    } else {
      response_ver = t('ravensight.inconclusive');
    }
  } else {
    response_ver = t('ravensight.inconclusive');
  }
  return response_ver;
};

export const calculateFeedbackStatusColorRavensight = (theme: ThemeType, feedbackStatus?: string): string => {
  let color = '';

  if (feedbackStatus) {
    feedbackStatus = feedbackStatus.toString().toUpperCase();
    if (feedbackStatus == 'FIRM') {
      color = theme ? themeObject[theme].purpleScoreColor : '#d66bd1';
    } else if (feedbackStatus == 'HIGHLY SUSPICIOUS') {
      color = theme ? themeObject[theme].redScoreColor : '#ef5c5c';
    } else if (feedbackStatus == 'SUSPICIOUS') {
      color = theme ? themeObject[theme].redScoreColor : '#ef5c5c';
    } else if (feedbackStatus == 'POSSIBLE') {
      color = theme ? themeObject[theme].orangeScoreColor : '#efa94a';
    } else if (feedbackStatus == 'INCONCLUSIVE') {
      color = theme ? themeObject[theme].grayScoreColor : '#828688';
    } else if (feedbackStatus == 'CLEAN') {
      color = theme ? themeObject[theme].greenScoreColor : '#8aea8f';
    } else {
      color = theme ? themeObject[theme].grayScoreColor : '#828688';
    }
  } else {
    color = theme ? themeObject[theme].grayScoreColor : '#828688';
  }
  return color;
};

export const isPcapSample = (filename: string | undefined): boolean => {
  return Boolean(filename?.toString().endsWith('.pcap'));
};

export const isApkSample = (extension?: string): boolean => {
  return extension === 'apk';
};

export const isUrlSample = (jsonSample: JSONSampleSource | undefined): boolean => {
  return jsonSample?.type?.toString().toUpperCase() === SampleTypes.URL;
};

export const isMailSample = (jsonSample: JSONSampleSource | undefined): boolean => {
  return jsonSample?.subtype?.toString().toUpperCase() === SampleTypes.MAIL;
};

export const hasSampleTraceData = (jsonSample: JSONSampleSource | undefined): boolean => {
  return !!jsonSample?.sandbox?.raventrace;
};

export const hasSampleTraceFaceRecognitionData = (traceData: TraceContent | undefined): boolean => {
  let flagFound = false;
  if (traceData) {
    const objectsRecognized = traceData?.objects?.object_recognized;

    if (objectsRecognized) {
      for (const objectRecognized of Object.values(objectsRecognized)) {
        if ((objectRecognized?.object_detection?.face_recon?.faces_detected || 0) > 0) {
          flagFound = true;
          break;
        }
      }
    }
  }
  return flagFound;
};

export const hasSampleTraceTextAnalysisData = (traceData: TraceContent | undefined): boolean => {
  if (traceData) {
    return !!traceData?.deep || !!traceData?.sentiment || !!traceData?.transcribed || !!traceData?.translated;
  }
  return false;
};

export const hasSampleRadareData = (sandboxesData: SourceSandbox | undefined): boolean => {
  return Boolean(sandboxesData?.ravensics?.radare2?.graph);
};

export const hasSampleSandboxName = (sandboxName: string, scoresList?: AnalysisRsightSBScore[]) => {
  if (scoresList && sandboxName) {
    for (let i = 0; i < scoresList.length; i++) {
      const scoreData = scoresList[i];
      if (scoreData?.['c0b9d2']?.toString().toUpperCase() == sandboxName?.toString().toUpperCase()) {
        return true;
      }
    }
  }
  return false;
};

//me la paso a ravensightveredict
export const hasSampleDynamic = (sandboxesNames?: Sandboxes[], scoresList?: AnalysisRsightSBScore[]) => {
  let result = false;
  if (sandboxesNames) {
    sandboxesNames.forEach((sandboxName) => {
      if (
        ![Sandboxes.STATICANALYSIS, Sandboxes.RAVENTRACE, Sandboxes.RAVENSICS, Sandboxes.RAVENSICSAV].includes(
          sandboxName,
        ) &&
        hasSampleSandboxName(sandboxName, scoresList)
      ) {
        result = true;
        return;
      }
    });
  }
  return result;
};

export const getAvVerdictColor = (avPositive: number, theme: ThemeType): string => {
  return avPositive > 0 ? themeObject[theme].redScoreColor : themeObject[theme].greenScoreColor;
};

export const getEnabledAvList = (multiAV: MultiAV = {}): AVListItem[] => {
  const enabledList: AVListItem[] = [];

  /**
   * @deprecated Only for legacy samples without enabled property
   */
  const pushLegacyEnabledAv = (key: string, avProps: MultiAVItem) => {
    avProps.enabled && enabledList.push({ avName: key, avProps });
  };

  for (const key in multiAV) {
    const avProps = multiAV[key];

    avProps.success ? enabledList.push({ avName: key, avProps }) : pushLegacyEnabledAv(key, avProps);
  }

  return enabledList;
};

export const getAVPositiveCount = (avList: AVListItem[] = []) => {
  let avDetections = 0;
  avList.forEach((av) => {
    if (av.avProps.infected) {
      avDetections++;
    }
  });
  return avDetections;
};
