import React from 'react';
import * as S from './Tag.styles';

export interface ITag {
  id: string;
  title: string;
  bgColor: string;
}

interface TagProps {
  title: string;
  color?: string;
  bgColor?: string;
}

export const Tag: React.FC<TagProps> = ({
  title,
  color,
  bgColor,
  width,
  maxWidth,
  display,
  otherStyle,
  ...otherProps
}) => {
  const style = {
    color: color || 'var(--white)',
    backgroundColor: bgColor || 'var(--tag-ravenloop-background-color)',
    width: width || 'fit-content',
    maxWidth: maxWidth || '100%',
    display: display || 'flex',
    ...otherStyle,
  };
  return (
    <S.TagWrapper style={style} {...otherProps}>
      {title}
    </S.TagWrapper>
  );
};
