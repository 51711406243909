import { captcha } from '@app/api/auth.api';
import { notificationController } from '@app/controllers/notificationController';
import { APIResponse } from '@app/interfaces/apiInterfaces';
import { CaptchaData } from '@app/interfaces/loginInterfaces';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

export const useCaptcha = () => {
  const [captchaData, setCaptchaData] = useState<CaptchaData | null>(null);
  const { t } = useTranslation();

  const newCaptcha = () => {
    captcha()
      .then((res: APIResponse<CaptchaData>) => {
        setCaptchaData(res.data);
      })
      .catch((err) => {
        console.log(err);
        notificationController.error({ message: t('login.loginError') });
      });
  };
  return { captchaData, newCaptcha };
};
