import { notificationController } from '@app/controllers/notificationController';
import { ApiError } from '../ApiError';
import { ApiErrorData } from '../http.api';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export const useApiErrorHandler = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const apiErrorHandler = (err: ApiError<ApiErrorData>, message?: string) => {
    if (err.options?.status === 403) {
      notificationController.error({ message: t('common.forbidden') });

      const pathname = window.location.pathname;

      navigate(`/forbidden?pathname=${pathname}`);
      return;
    }

    if (err.options?.status === 401) {
      notificationController.error({ message: t('common.unauthorized') });
      navigate('/auth/login');
      return;
    }

    const msgContent = (!!message && t(message)) || err.message || t('common.loadingDataError');

    notificationController.error({ message: msgContent });
  };

  return apiErrorHandler;
};
