// import { Image } from 'antd';
import { Card } from '@app/components/common/Card/Card';
import { Trans, useTranslation } from 'react-i18next';
import HelpIntroduction from './HelpIntroduction';
import styled from 'styled-components';
import { media } from '@app/styles/themes/constants';
import { Ref, forwardRef } from 'react';
import HelpRequirements from './HelpRequirements';
import HelpUserManual from './HelpUserManual';
import HelpSubmitSample from './HelpSubmitSample';
import HelpAnalyses from './HelpAnalyses/HelpAnalyses';
import HelpSampleReport from './HelpSampleReport';
import HelpExecutiveReport from './HelpExecutiveReport/HelpExecutiveReport';
import HelpTechnicalReport from './HelpTechnicalReport/HelpTechnicalReport';
import HelpDashboard from './HelpDashboard/HelpDashboard';
import HelpManagement from './HelpManagement/HelpManagement';

export const transComponents = {
  b: <strong />,
  high: <span className="highlighted" />,
  highred: <span className="highlighted--red" />,
  highpurple: <span className="highlighted--purple" />,
  highgreen: <span className="highlighted--green" />,
  highyellow: <span className="highlighted--yellow" />,
};

const ContentLayout = styled(Card)`
  width: 100%;
  .ant-card-body {
    width: 100%;

    overflow-y: auto;
    padding: 0 1rem;
    @media screen and (${media.lg}) {
      padding: 0 3rem;
    }

    .image-container {
      padding: 4rem 30% 0;
    }
  }

  ul {
    padding-left: 1rem;
  }
`;

const HelpContent = forwardRef((_props, ref: Ref<HTMLDivElement>) => {
  const { t } = useTranslation('translation', { keyPrefix: 'help' });
  return (
    <ContentLayout>
      <div className="image-container">{/* <Image src={adaCondensed} preview={false} /> */}</div>
      <h1>
        <Trans t={t} i18nKey="title" components={transComponents} />
      </h1>
      <div ref={ref}>
        <HelpIntroduction />
        <HelpRequirements />
        <HelpUserManual />
        <HelpSubmitSample />
        <HelpAnalyses />
        <HelpSampleReport />
        <HelpExecutiveReport />
        <HelpTechnicalReport />
        <HelpDashboard />
        <HelpManagement />
      </div>
    </ContentLayout>
  );
});

export default HelpContent;
