import React, { useEffect, useState, Suspense } from 'react';
import { Header } from '../../../header/Header';
import MainSider from '../sider/MainSider/MainSider';
import MainContent from '../MainContent/MainContent';
import { MainHeader } from '../MainHeader/MainHeader';
import * as S from './MainLayout.styles';
import { Outlet, useLocation } from 'react-router-dom';
import { useResponsive } from '@app/hooks/useResponsive';
import { setCurrentPlatformByPathname } from '@app/services/localStorage.service';
import HeaderContext from '@app/hooks/HeaderContext';
import { LoadingRavenloop } from '@app/components/common/Loadings/LoadingRavenloop';

const MainLayout = ({ children }: { children?: JSX.Element }) => {
  const [isTwoColumnsLayout, setIsTwoColumnsLayout] = useState(true);
  const [siderCollapsed, setSiderCollapsed] = useState(true);
  const [currentPlatform, setCurrentPlatform] = useState<string>(null);
  const { isDesktop } = useResponsive();
  const location = useLocation();

  const [headerBreadcrumb, setHeaderBreadcrumb] = useState(null);
  const [headerNavigation, setHeaderNavigation] = useState(null);
  const [headerKeyReset, setHeaderKeyReset] = useState(0);

  const toggleSider = () => setSiderCollapsed(!siderCollapsed);

  useEffect(() => {
    setIsTwoColumnsLayout([].includes(location.pathname) && isDesktop);
    // if (mobileOnly || tabletOnly) {
    //     setSiderCollapsed(true);
    // }
    // if (!location.pathname.startsWith("/rsight/analyses/")) {
    // }
  }, [location.pathname, isDesktop]);

  useEffect(() => {
    const platform = setCurrentPlatformByPathname(location.pathname);
    setCurrentPlatform(platform);
    setHeaderBreadcrumb(null);
    setHeaderNavigation(null);
    setHeaderKeyReset(headerKeyReset + 1);
  }, [location.pathname]);

  return (
    <S.LayoutMaster>
      <MainSider isCollapsed={siderCollapsed} setCollapsed={setSiderCollapsed} currentPlatform={currentPlatform} />
      <S.LayoutMain>
        <HeaderContext.Provider
          value={{ headerBreadcrumb, setHeaderBreadcrumb, headerNavigation, setHeaderNavigation, headerKeyReset }}
        >
          <MainHeader isTwoColumnsLayout={isTwoColumnsLayout}>
            <Header toggleSider={toggleSider} isSiderOpened={!siderCollapsed} isTwoColumnsLayout={isTwoColumnsLayout} />
          </MainHeader>
          <MainContent id="main-content" $isTwoColumnsLayout={isTwoColumnsLayout}>
            {/* Suspense is important for lazy loading in routers */}
            <Suspense fallback={<LoadingRavenloop />}>
              {!!children ? children : null}
              <Outlet />
            </Suspense>
          </MainContent>
        </HeaderContext.Provider>
      </S.LayoutMain>
    </S.LayoutMaster>
  );
};

export default MainLayout;
