import React from 'react';
import Icon, {
  UnorderedListOutlined,
  UploadOutlined,
  AimOutlined,
  FlagOutlined,
  FileSearchOutlined,
  MailOutlined,
  AuditOutlined,
  AlertOutlined,
  LineChartOutlined,
  ClusterOutlined,
  PicLeftOutlined,
  MonitorOutlined,
  SettingOutlined,
  WarningOutlined,
  FireOutlined,
  SecurityScanOutlined,
} from '@ant-design/icons';
import { getReactIconPlatformByNameAndTheme } from '@app/utils/platformsIcon';
import { TFunction } from 'i18next';
import { useAppSelector } from '@app/hooks/reduxHooks';
import { selectAvailableModules, selectClientRole, selectUserRole } from '@app/store/slices/userSlice';
import { AvailableModules } from '@app/interfaces/interfaces';
import { accessLevel } from '@app/domain/accessLevel';
import { userRoles } from '@app/domain/rolesModel';
import { datalakeIp } from '@app/api/http.api';
import { useResponsive } from '@app/hooks/useResponsive';
import { MdHelpOutline } from 'react-icons/md';

export interface SidebarNavigationItem {
  title?: string;
  key?: string;
  url?: string;
  children?: SidebarNavigationItem[];
  icon?: React.ReactNode;
  label?: string | undefined;
  disabled?: boolean;
}

export interface SidebarNavigationSeparator {
  type?: 'divider';
}

export const getSidebarNavigationByTheme = (theme: string): SidebarNavigationItem[] => {
  return [
    {
      title: 'common.ravenseer',
      key: 'rseer',
      url: '#',
      icon: <Icon component={getReactIconPlatformByNameAndTheme('ravenseer', theme)} />,
      disabled: true,
    },
    {
      title: 'common.ravensight',
      key: 'ravensight',
      url: '#',
      icon: <Icon component={getReactIconPlatformByNameAndTheme('ravensight', theme)} />,
      disabled: false,
      children: [
        {
          title: 'rsight.analysisList',
          key: 'rsight_analysis_list',
          url: 'rsight/analyses',
        },
        {
          title: 'rsight.uploadFile',
          key: 'rsight_upload_form',
          url: 'rsight/upload',
        },
      ],
    },
    {
      title: 'common.ravenwire',
      key: 'rwire',
      url: '/rwire',
      icon: <Icon component={getReactIconPlatformByNameAndTheme('ravenwire', theme)} />,
      children: [
        {
          title: 'common.dashboard',
          key: 'rwire_dashboard',
          url: '/rwire',
        },
        {
          title: 'common.iocs-list',
          key: 'iocs',
          url: '/rwire/iocs',
        },
        {
          title: 'common.events-list',
          key: 'events',
          url: '/rwire/events',
        },
      ],
    },
    {
      title: 'common.ravenscout',
      key: 'rscout',
      url: '#',
      icon: <Icon component={getReactIconPlatformByNameAndTheme('ravenscout', theme)} />,
      disabled: false,
      children: [
        {
          title: 'rscout.auditsList',
          key: 'rscout_audits',
          url: '/rscout/audits',
        },
      ],
    },
    {
      title: 'common.ravenpost',
      key: 'rpost',
      url: '#',
      icon: <Icon component={getReactIconPlatformByNameAndTheme('ravenpost', theme)} />,
      disabled: true,
    },
    {
      title: 'common.raventrace',
      key: 'rtrace',
      url: '#',
      icon: <Icon component={getReactIconPlatformByNameAndTheme('raventrace', theme)} />,
      children: [
        {
          title: 'rtrace.files',
          key: 'rtrace_files',
          url: '/rtrace/files',
        },
        {
          title: 'rtrace.uploadLink',
          key: 'rtrace_upload',
          url: '/rtrace/upload',
        },
      ],
    },
  ];
};
const getSiderBarNavigationDatalake = (
  availableModules: AvailableModules[],
  t: TFunction,
): Array<SidebarNavigationItem | SidebarNavigationSeparator> => {
  const userRole = useAppSelector(selectUserRole) || userRoles.user;
  const clientRole = useAppSelector(selectClientRole);

  const dashboardClient =
    clientRole === 'MASTER' || userRole !== 'user'
      ? {
          label: t('common.dashboardClient'),
          key: 'dashboard_client',
          url: '/dashboard/client',
          disabled: !availableModules.includes(AvailableModules.RAVENSIGHT),
        }
      : null;
  return [
    {
      type: 'divider',
    },
    {
      label: t('common.status'),
      key: 'status',
      // url: '/rwire',
      icon: <LineChartOutlined />,
      children: [
        {
          label: t('common.eventsDashboard'),
          key: 'rwire_dashboard',
          url: '/rwire',
          disabled: !availableModules?.includes(AvailableModules.RAVENWIRE),
        },
        {
          label: t('common.mailsDashboard'),
          key: 'rpost_dashboard',
          url: '/rpost',
          disabled: !availableModules?.includes(AvailableModules.RAVENPOST),
        },
        {
          label: t('common.auditsDashboard'),
          key: 'rscout_dashboard',
          url: '/rscout',
          disabled: !availableModules?.includes(AvailableModules.RAVENSCOUT),
        },
        {
          label: t('common.networkStatisticsDashboard'),
          key: 'rsight_network_statistics_dashboard',
          url: '/rsight/network-statistics',
          disabled: !availableModules?.includes(AvailableModules.RAVENSIGHT),
        },

        ...(dashboardClient ? [dashboardClient] : []),
        {
          label: t('common.generalStats'),
          key: 'general_stats',
          url: '/dashboard/general',
          disabled: !availableModules?.includes(AvailableModules.RAVENSIGHT),
        },
        {
          label: t('common.mitreDashboard'),
          key: 'mitre_stats',
          url: '/dashboard/mitre',
          disabled: !availableModules?.includes(AvailableModules.RAVENSIGHT),
        },
        {
          label: t('common.alerts'),
          key: 'alerts',
          url: '/alerts',
          icon: <AlertOutlined />,
          disabled: !availableModules?.includes(AvailableModules.RAVENSIGHT) || userRole === 'user',
        },
      ],
    },
    {
      type: 'divider',
    },
    {
      label: t('common.investigation'),
      key: 'investigation',
      // url: '/rsight/investigation',
      icon: <MonitorOutlined />,
      children: [
        {
          label: t('investigation.dashboard'),
          key: 'investigation_dashboard',
          url: 'rsight/investigation',
          disabled: !availableModules?.includes(AvailableModules.RAVENSIGHT),
        },
        {
          label: t('analysis.iocs'),
          key: 'iocs',
          url: '/iocs',
          icon: <AimOutlined />,
          disabled: !availableModules?.includes(AvailableModules.RAVENSIGHT),
        },
      ],
    },
    {
      type: 'divider',
    },
  ];
};

export const getSidebarNavigationCollapsedByTheme = (
  theme: string,
  t: TFunction,
): Array<SidebarNavigationItem | SidebarNavigationSeparator> => {
  const { mobileOnly } = useResponsive();
  const availableModules = useAppSelector(selectAvailableModules);
  const userRole = useAppSelector(selectUserRole) || userRoles.user;
  const locationHref = window.location.href;
  const isDatalakeIp = !!datalakeIp && locationHref.startsWith(datalakeIp);
  const clientRole = useAppSelector(selectClientRole);

  const dashboardClient =
    clientRole === 'MASTER' || userRole !== 'user'
      ? {
          label: t('common.dashboardClient'),
          key: 'dashboard_client',
          url: '/dashboard/client',
          disabled: !availableModules.includes(AvailableModules.RAVENSIGHT),
        }
      : null;
  const datalakeNavigationItems = getSiderBarNavigationDatalake(availableModules, t);

  if (isDatalakeIp) {
    return datalakeNavigationItems;
  } else {
    return [
      {
        type: 'divider',
      },
      {
        label: t('common.status'),
        key: 'status',
        icon: <LineChartOutlined />,
        children: [
          {
            label: t('common.eventsDashboard'),
            key: 'rwire_dashboard',
            url: '/rwire',
            disabled: !availableModules?.includes(AvailableModules.RAVENWIRE),
          },
          {
            label: t('common.mailsDashboard'),
            key: 'rpost_dashboard',
            url: '/rpost',
            disabled: !availableModules?.includes(AvailableModules.RAVENPOST),
          },
          {
            label: t('common.auditsDashboard'),
            key: 'rscout_dashboard',
            url: '/rscout',
            disabled: !availableModules?.includes(AvailableModules.RAVENSCOUT),
          },
          {
            label: t('common.networkStatisticsDashboard'),
            key: 'rsight_network_statistics_dashboard',
            url: '/rsight/network-statistics',
            disabled: !availableModules?.includes(AvailableModules.RAVENSIGHT),
          },

          ...(dashboardClient ? [dashboardClient] : []),
          {
            label: t('common.generalStats'),
            key: 'general_stats',
            url: '/dashboard/general',
            disabled: !availableModules?.includes(AvailableModules.RAVENSIGHT),
          },
          {
            label: t('common.mitreDashboard'),
            key: 'mitre_stats',
            url: '/dashboard/mitre',
            disabled: !availableModules?.includes(AvailableModules.RAVENSIGHT),
          },
          {
            label: t('common.alerts'),
            key: 'alerts',
            url: '/alerts',
            icon: <AlertOutlined />,
            disabled: !availableModules?.includes(AvailableModules.RAVENSIGHT) || userRole === 'user',
          },
        ],
      },
      {
        type: 'divider',
      },
      {
        label: t('rsight.analysis'),
        key: 'analysis',
        // url: '/rwire',
        icon: <UnorderedListOutlined />,
        children: [
          {
            label: t('rscout.audits'),
            key: 'rscout_audits',
            url: '/rscout/audits',
            icon: <AuditOutlined />,
            disabled: !availableModules?.includes(AvailableModules.RAVENSCOUT),
          },
          {
            label: t('rsight.analysis'),
            key: 'rsight_analysis_list',
            url: 'rsight/analyses',
            icon: <FileSearchOutlined />,
            disabled: !availableModules?.includes(AvailableModules.RAVENSIGHT),
          },
          {
            label: t('common.events'),
            key: 'events',
            url: '/rwire/events',
            icon: <FlagOutlined />,
            disabled: !availableModules?.includes(AvailableModules.RAVENWIRE),
          },
          // {
          //   label: t('rscout.assets'),
          //   key: 'rscout_assets',
          //   url: '/management/assets',
          //   icon: <DeploymentUnitOutlined {...getStyleProp(AvailableModules.RAVENSCOUT)} />,
          //   disabled: !availableModules?.includes(AvailableModules.RAVENSCOUT),
          // },
          {
            label: t('rpost.mails'),
            key: 'rpost_mails',
            url: '/rpost/mails',
            icon: <MailOutlined />,
            disabled: !availableModules?.includes(AvailableModules.RAVENPOST),
          },
          {
            label: t('rwire.anomalies'),
            key: 'rwireAnomalies',
            url: '/rwire/anomalies',
            icon: <WarningOutlined />,
            disabled: !availableModules?.includes(AvailableModules.RAVENWIRE),
          },
        ],
      },
      {
        type: 'divider',
      },
      {
        label: t('common.investigation'),
        key: 'investigation',
        // url: '/rsight/investigation',
        icon: <MonitorOutlined />,
        children: [
          {
            label: t('investigation.dashboard'),
            key: 'investigation_dashboard',
            url: 'rsight/investigation',
            disabled: !availableModules?.includes(AvailableModules.RAVENSIGHT),
          },
          {
            label: t('analysis.iocs'),
            key: 'iocs',
            url: '/iocs',
            icon: <AimOutlined />,
            disabled: !availableModules?.includes(AvailableModules.RAVENSIGHT),
          },
        ],
      },
      {
        type: 'divider',
      },
      {
        label: t('common.intake'),
        key: 'intake',
        // url: '/rwire',
        //icon: <AppstoreOutlined />,
        icon: <UploadOutlined />,
        children: [
          {
            label: t('uploads.uploads'),
            key: 'rsight_upload_form',
            url: 'rsight/upload',
            icon: <UploadOutlined />,
            disabled: !availableModules?.includes(AvailableModules.RAVENSIGHT),
          },
          ...(accessLevel.CONFIDENTIAL.includes(userRole)
            ? [
                {
                  label: t('ioc.feeds'),
                  key: 'feeds',
                  url: 'management/feeds',
                  icon: <PicLeftOutlined />,
                  disabled: !availableModules?.includes(AvailableModules.RAVENSIGHT),
                },
                {
                  label: t('common.probe'),
                  key: 'probe',
                  url: 'management/machines',
                  icon: <ClusterOutlined />,
                  disabled: !availableModules?.includes(AvailableModules.RAVENSIGHT),
                },
              ]
            : []),
        ],
      },
      {
        type: 'divider',
      },
      {
        label: t('common.security'),
        key: 'security',
        icon: <SecurityScanOutlined />,
        children: [
          {
            label: t('common.firewall'),
            key: 'firewall',
            url: 'firewall',
            icon: <FireOutlined />,
            disabled: !availableModules?.includes(AvailableModules.RAVENSIGHT),
          },
        ],
      },
      ...(accessLevel.ADMINISTRATION.includes(userRole)
        ? [
            {
              type: 'divider',
            } as SidebarNavigationSeparator,
            {
              label: t('management.settings'),
              key: 'settings',
              // url: '/rwire',
              //icon: <AppstoreOutlined />,
              icon: <SettingOutlined />,
              children: [
                {
                  label: t('header.access_management'),
                  key: 'accessManagement',
                  disabled: !availableModules?.includes(AvailableModules.RAVENSIGHT),
                  // url: '',
                  children: [
                    {
                      label: t('header.clients'),
                      key: 'clients',
                      url: '/management/clients',
                    },
                    {
                      label: t('header.groups'),
                      key: 'groups',
                      url: '/management/groups',
                    },
                    {
                      label: t('header.users'),
                      key: 'users/users',
                      url: '/management/users',
                    },
                    {
                      label: t('header.managers'),
                      key: 'managers',
                      url: '/management/managers',
                    },
                  ],
                },
                {
                  label: t('breadcrumbs.general'),
                  key: 'generalConfiguration',
                  disabled: !availableModules?.includes(AvailableModules.RAVENSIGHT),
                  children: [
                    ...(accessLevel.CONFIDENTIAL.includes(userRole)
                      ? [
                          {
                            label: t('management.generalManagement'),
                            key: 'generalManagement',
                            url: '/management/general',
                          },
                        ]
                      : []),

                    {
                      label: t('management.logs'),
                      key: 'logs',
                      url: '/management/logs',
                    },
                  ],
                },
                {
                  label: t('rsight.clientSettingspage'),
                  key: 'clientConfiguration',
                  url: '/management/client_settings',
                  disabled: !availableModules?.includes(AvailableModules.RAVENSIGHT),
                },
                {
                  label: t('management.rpostConfiguration'),
                  key: 'rpostSettings',
                  url: '/management/rpost-settings',
                  disabled: !availableModules?.includes(AvailableModules.RAVENPOST),
                },
                ...(accessLevel.CONFIDENTIAL.includes(userRole)
                  ? [
                      {
                        label: t('management.rsightConfiguration'),
                        key: 'rsightSettings',
                        url: '/management/rsight-settings',
                        disabled: !availableModules?.includes(AvailableModules.RAVENSIGHT),
                      },
                    ]
                  : []),
                {
                  label: t('management.rscoutConfiguration'),
                  key: 'ravenscoutConfiguration',
                  disabled: !availableModules?.includes(AvailableModules.RAVENSCOUT),
                  children: [
                    // {
                    //   label: t('management.machineConfiguration'),
                    //   key: 'machineConfiguration',
                    //   url: '/management/machines',
                    // },
                    {
                      label: t('management.vuln_list'),
                      key: 'vulnerabilitiesList',
                      url: '/vulnerabilities-list',
                    },
                    // {
                    //   label: t('playbook.playbookCreation'),
                    //   key: 'newPlaybook',
                    //   url: '/management/playbooks/new',
                    // },
                    {
                      label: t('playbook.playbooksConfiguration'),
                      key: 'playbookConfiguration',
                      url: '/management/playbooks',
                    },
                    {
                      label: t('rscout.assetsManagement'),
                      key: 'assetsManagement',
                      url: '/management/assets',
                    },
                  ],
                },
                {
                  label: t('management.vmsSettings'),
                  key: 'vmsSettings',
                  url: '/management/vms',
                  disabled: !availableModules?.includes(AvailableModules.RAVENSIGHT),
                },
              ],
            },
          ]
        : []),
      {
        type: 'divider',
      },
      ...(!!mobileOnly && accessLevel.CONFIDENTIAL.includes(userRole) // TODO: Remove access level when help page completes
        ? [
            {
              label: t('help.breadcrumb'),
              key: 'help',
              url: '/help',
              icon: <MdHelpOutline />,
            },
            {
              type: 'divider',
            } as SidebarNavigationSeparator,
          ]
        : []),
    ];
  }
};
