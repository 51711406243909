import { FormTitleWrapper } from '@app/components/common/forms/components/FormTitleWrapper/FormTitleWrapper';
import { Trans, useTranslation } from 'react-i18next';
import { transComponents } from './HelpContent';

const HelpSubmitSample = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'help.submitSample' });

  return (
    <>
      <FormTitleWrapper>
        <h3 id="submitSample">
          <Trans t={t} i18nKey="title" components={transComponents} />
        </h3>
      </FormTitleWrapper>
      <p>
        <Trans t={t} i18nKey="p1" components={transComponents} />
      </p>
      <h4 id="submit-sample-image1">IMAGE</h4>
      <p>
        <Trans t={t} i18nKey="p2" components={transComponents} />
      </p>
      <h4 id="submit-sample-image2">IMAGE</h4>
      <p>
        <Trans t={t} i18nKey="p3" components={transComponents} />
      </p>
      <h4 id="submit-sample-image3">IMAGE</h4>
      <p>
        <Trans t={t} i18nKey="p4" components={transComponents} />
      </p>
      <blockquote>
        <h4>
          <Trans t={t} i18nKey="analystLevels.default.title" components={transComponents} />
        </h4>
        <p>
          <Trans t={t} i18nKey="analystLevels.default.p1" components={transComponents} />
        </p>
        <ul>
          <li>
            <Trans t={t} i18nKey="analystLevels.default.ul1.li1" components={transComponents} />
          </li>
          <li>
            <Trans t={t} i18nKey="analystLevels.default.ul1.li2" components={transComponents} />
          </li>
          <li>
            <Trans t={t} i18nKey="analystLevels.default.ul1.li3" components={transComponents} />
          </li>
        </ul>
        <h5 id="submit-sample-image4">IMAGE</h5>
      </blockquote>
      <blockquote>
        <h4>
          <Trans t={t} i18nKey="analystLevels.intermediate.title" components={transComponents} />
        </h4>
        <p>
          <Trans t={t} i18nKey="analystLevels.intermediate.p1" components={transComponents} />
        </p>
        <h5 id="submit-sample-image5">IMAGE</h5>
      </blockquote>
      <blockquote>
        <h4>
          <Trans t={t} i18nKey="analystLevels.custom.title" components={transComponents} />
        </h4>
        <p>
          <Trans t={t} i18nKey="analystLevels.custom.p1" components={transComponents} />
        </p>
        <h5 id="submit-sample-image6">IMAGE</h5>
        <h5 id="submit-sample-image7">IMAGE</h5>
      </blockquote>
      <p>
        <Trans t={t} i18nKey="p5" components={transComponents} />
      </p>
      <h4 id="submit-sample-image8">IMAGE</h4>
      <p>
        <Trans t={t} i18nKey="p6" components={transComponents} />
      </p>
      <h4 id="submit-sample-image9">IMAGE</h4>
    </>
  );
};

export default HelpSubmitSample;
