import React, { useMemo } from 'react';
import Overlay from '../../../../common/Overlay';
import { useResponsive } from '@app/hooks/useResponsive';
import * as S from './MainSider.styles';
import { SiderLogoPlatform } from '../SiderLogoPlatform';
import SiderMenuCollapsed from '@app/components/layouts/main/sider/SiderMenuCollapsed/SiderMenuCollapsed';

import { SiderLogoClient } from '../SiderLogoClient';
import { ClientDropdown } from '@app/components/header/components/ClientDropdown/ClientDropdown';
import { SettingsOverlay } from '@app/components/header/components/settingsDropdown/settingsOverlay/SettingsOverlay/SettingsOverlay';

export interface MainSiderProps {
  isCollapsed: boolean;
  setCollapsed: (isCollapsed: boolean) => void;
}

const MainSider: React.FC<MainSiderProps> = ({ isCollapsed, setCollapsed, currentPlatform, ...props }) => {
  const { desktopOnly, mobileOnly, tabletOnly } = useResponsive();

  const isCollapsible = useMemo(() => desktopOnly && mobileOnly && tabletOnly, [desktopOnly, mobileOnly, tabletOnly]);

  const toggleSider = () => setCollapsed(!isCollapsed);

  return (
    <>
      <S.Sider
        trigger={null}
        collapsed={isCollapsed}
        collapsedWidth={mobileOnly ? 0 : 60}
        collapsible={isCollapsible}
        width={220}
        {...props}
      >
        <SiderLogoPlatform isSiderCollapsed={isCollapsed} currentPlatform={currentPlatform} toggleSider={toggleSider} />
        <S.SiderContent>
          <SiderMenuCollapsed setCollapsed={setCollapsed} />
        </S.SiderContent>

        <ClientDropdown isSiderCollapsed={isCollapsed} />
      </S.Sider>
      {mobileOnly && <Overlay onClick={toggleSider} show={!isCollapsed} />}
    </>
  );
};

export default MainSider;
