import { httpApi } from '@app/api/http.api';
// import './mocks/auth.api.mock';
import { UserModel } from '@app/domain/UserModel';
import { APIResponse } from '@app/interfaces/apiInterfaces';
import { CaptchaData } from '@app/interfaces/loginInterfaces';

export interface AuthData {
  email: string;
  password: string;
}

export interface SignUpRequest {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
}

export interface ResetPasswordRequest {
  uemail: string;
  recover: boolean;
  'captcha-hash': string;
  'captcha-text': string;
}

export interface SecurityCodePayload {
  code: string;
}

export interface NewPasswordData {
  newPassword: string;
}

export interface LoginRequest {
  username: string;
  password: string;
  eula_check?: string | boolean;
  policy_check?: string | boolean;

  // "X-API-KEY": string;
}

export interface LoginResponse {
  status: string;
  data: UserModel;
}

export interface LogoutResponse {
  success?: boolean;
  errors?: string[];
}

const api_token = { 'X-API-KEY': process.env.REACT_APP_X_API_KEY || '' };

export const login = (loginPayload: LoginRequest): Promise<LoginResponse> =>
  httpApi.post<LoginResponse>('login', { ...loginPayload }, { headers: api_token }).then(({ data }) => data);

export const logout = (): Promise<LogoutResponse> =>
  httpApi.get<LogoutResponse>('logout', { headers: api_token }).then(({ data }) => data);

export const signUp = (signUpData: SignUpRequest): Promise<undefined> =>
  httpApi.post<undefined>('signUp', { ...signUpData }).then(({ data }) => data);

export const resetPassword = (resetPasswordPayload: ResetPasswordRequest): Promise<undefined> =>
  httpApi.post<undefined>('/recover/password', { ...resetPasswordPayload }).then(({ data }) => data);

export const recoverPassword = (recoverPasswordPayload: { token: string | null }): Promise<undefined> =>
  httpApi.post<undefined>('/recover/password/confirmation', { ...recoverPasswordPayload }).then(({ data }) => data);

export const verifySecurityCode = (securityCodePayload: SecurityCodePayload): Promise<undefined> =>
  httpApi.post<undefined>('verifySecurityCode', { ...securityCodePayload }).then(({ data }) => data);

export const setNewPassword = (newPasswordData: NewPasswordData): Promise<undefined> =>
  httpApi.post<undefined>('setNewPassword', { ...newPasswordData }).then(({ data }) => data);

export const captcha = (): Promise<APIResponse<CaptchaData>> =>
  httpApi.get('get-captcha', { headers: api_token }).then(({ data }) => data);
