import { getVmData, setVmData } from '@app/services/localStorage.service';
import { Dispatch, SetStateAction, createContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet } from 'react-router';

export interface VmsContextInterface {
  vm: VmStateInterface | null;
  setVm: (data: VmStateInterface | null) => void;
  vmState: {
    setVmStatus: Dispatch<SetStateAction<string>>;
    vmStatus: string;
  };
}

export interface VmStateInterface {
  ip: string;
  sandbox: string;
  id: number;
  name: string;
  description: string;
  analysisToken?: string;
}

export const VmContext = createContext<VmsContextInterface>({
  vm: null,
  setVm: () => {},
  vmState: { setVmStatus: () => {}, vmStatus: '' },
});

const VmProvider = () => {
  const { t } = useTranslation();
  const initialData = getVmData();
  const [vm, setVm] = useState<VmStateInterface | null>(initialData);
  const [vmStatus, setVmStatus] = useState<string>(t('vm.disconnected'));

  const persistVmData = (data: VmStateInterface | null) => {
    data && setVmData(data);
    setVm(data);
  };
  return (
    <VmContext.Provider value={{ vm, setVm: persistVmData, vmState: { setVmStatus, vmStatus } }}>
      <Outlet />
    </VmContext.Provider>
  );
};

export default VmProvider;
