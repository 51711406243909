//COLOR
import iconColorRavenpost from '@app/assets/icons/platforms/color/RAVENPOST.svg';
import iconColorRavenseer from '@app/assets/icons/platforms/color/RAVENSEER.svg';
import iconColorRavenwire from '@app/assets/icons/platforms/color/RAVENWIRE.svg';
import iconColorRavensight from '@app/assets/icons/platforms/color/RAVENSIGHT.svg';
import iconColorRavenscout from '@app/assets/icons/platforms/color/RAVENSCOUT.svg';
import iconColorRaventrace from '@app/assets/icons/platforms/color/RAVENTRACE.svg';
import intelmqIcon from '@app/assets/banners/intelmq.png';
import otxIcon from '@app/assets/banners/otx.png';
import malpediaIcon from '@app/assets/banners/malpedia.png';
import shodanIcon from '@app/assets/banners/shodan.png';
//REACT COLOR
import React from 'react';
import { ReactComponent as ReactIconColorRavenpost } from '@app/assets/icons/platforms/color/RAVENPOST.svg';
import { ReactComponent as ReactIconColorRavenseer } from '@app/assets/icons/platforms/color/RAVENSEER.svg';
import { ReactComponent as ReactIconColorRavenwire } from '@app/assets/icons/platforms/color/RAVENWIRE.svg';
import { ReactComponent as ReactIconColorRavensight } from '@app/assets/icons/platforms/color/RAVENSIGHT.svg';
import { ReactComponent as ReactIconColorRavenscout } from '@app/assets/icons/platforms/color/RAVENSCOUT.svg';
import { ReactComponent as ReactIconColorRaventrace } from '@app/assets/icons/platforms/color/RAVENTRACE.svg';
//GREYSCALE
import iconGreyscaleRavenpost from '@app/assets/icons/platforms/greyscale/RAVENPOST.svg';
import iconGreyscaleRavenseer from '@app/assets/icons/platforms/greyscale/RAVENSEER.svg';
import iconGreyscaleRavenwire from '@app/assets/icons/platforms/greyscale/RAVENWIRE.svg';
import iconGreyscaleRavensight from '@app/assets/icons/platforms/greyscale/RAVENSIGHT.svg';
import iconGreyscaleRavenscout from '@app/assets/icons/platforms/greyscale/RAVENSCOUT.svg';
import iconGreyscaleRaventrace from '@app/assets/icons/platforms/greyscale/RAVENTRACE.svg';
//NEON
import iconNeonRavenpost from '@app/assets/icons/platforms/neon/RAVENPOST.svg';
import iconNeonRavenseer from '@app/assets/icons/platforms/neon/RAVENSEER.svg';
import iconNeonRavenwire from '@app/assets/icons/platforms/neon/RAVENWIRE.svg';
import iconNeonRavensight from '@app/assets/icons/platforms/neon/RAVENSIGHT.svg';
import iconNeonRavenscout from '@app/assets/icons/platforms/neon/RAVENSCOUT.svg';
import iconNeonRaventrace from '@app/assets/icons/platforms/neon/RAVENTRACE.svg';
//REACT COLOR
import { ReactComponent as ReactIconNeonRavenpost } from '@app/assets/icons/platforms/neon/RAVENPOST.svg';
import { ReactComponent as ReactIconNeonRavenseer } from '@app/assets/icons/platforms/neon/RAVENSEER.svg';
import { ReactComponent as ReactIconNeonRavenwire } from '@app/assets/icons/platforms/neon/RAVENWIRE.svg';
import { ReactComponent as ReactIconNeonRavensight } from '@app/assets/icons/platforms/neon/RAVENSIGHT.svg';
import { ReactComponent as ReactIconNeonRavenscout } from '@app/assets/icons/platforms/neon/RAVENSCOUT.svg';
import { ReactComponent as ReactIconNeonRaventrace } from '@app/assets/icons/platforms/neon/RAVENTRACE.svg';
import { Avatar, Image, Tooltip } from 'antd';

const dictIconPlatforms = {
  color: {
    ravensight: iconColorRavensight,
    ravenwire: iconColorRavenwire,
    ravenseer: iconColorRavenseer,
    ravenpost: iconColorRavenpost,
    ravenscout: iconColorRavenscout,
    raventrace: iconColorRaventrace,
    intelmq: intelmqIcon,
    otx: otxIcon,
    shodan: shodanIcon,
    malpedia: malpediaIcon,
  },
  greyscale: {
    ravensight: iconGreyscaleRavensight,
    ravenwire: iconGreyscaleRavenwire,
    ravenseer: iconGreyscaleRavenseer,
    ravenpost: iconGreyscaleRavenpost,
    ravenscout: iconGreyscaleRavenscout,
    raventrace: iconGreyscaleRaventrace,
  },
  neon: {
    ravensight: iconNeonRavensight,
    ravenwire: iconNeonRavenwire,
    ravenseer: iconNeonRavenseer,
    ravenpost: iconNeonRavenpost,
    ravenscout: iconNeonRavenscout,
    raventrace: iconNeonRaventrace,
  },
};

const dictReactIconPlatforms = {
  color: {
    ravensight: ReactIconColorRavensight,
    ravenwire: ReactIconColorRavenwire,
    ravenseer: ReactIconColorRavenseer,
    ravenpost: ReactIconColorRavenpost,
    ravenscout: ReactIconColorRavenscout,
    raventrace: ReactIconColorRaventrace,
    rsight: ReactIconColorRavensight,
  },
  neon: {
    ravensight: ReactIconNeonRavensight,
    ravenwire: ReactIconNeonRavenwire,
    ravenseer: ReactIconNeonRavenseer,
    ravenpost: ReactIconNeonRavenpost,
    ravenscout: ReactIconNeonRavenscout,
    raventrace: ReactIconNeonRaventrace,
  },
};

export const getIconPlatformByNameAndTheme = (platformName: string, theme: 'dark' | 'light'): string => {
  if (theme === 'dark') {
    return dictIconPlatforms['neon'][platformName];
  }
  return dictIconPlatforms['color'][platformName];
};

export const getIconPlatformByName = (platformName: string): string => {
  return dictIconPlatforms['color'][platformName];
};

export const getReactIconPlatformByNameAndTheme = (platformName: string, theme: 'dark' | 'light'): React.ReactNode => {
  if (theme === 'dark') {
    return dictReactIconPlatforms['neon'][platformName];
  }
  return dictReactIconPlatforms['color'][platformName];
};

export const getReactIconPlatformByName = (platformName: string): React.ReactNode => {
  return dictReactIconPlatforms['color'][platformName];
};

export const displayPlatformIcon = (platformName: string, width: string, tooltip: boolean = true): React.ReactNode => {
  const srcImageIconPlatform = getIconPlatformByName(platformName);
  if (tooltip) {
    return (
      <Tooltip placement="top" trigger="hover" title={platformName}>
        <Avatar src={<Image preview={false} src={srcImageIconPlatform} width={width} />} />
      </Tooltip>
    );
  } else {
    return <Avatar src={<Image preview={false} src={srcImageIconPlatform} width={width} />} />;
  }
};
