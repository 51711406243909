import { UserRoles } from '@app/domain/rolesModel';
import { withAccess } from '@app/hocs/withAccess';
import { lazy } from 'react';

const ClientsManagementPage = lazy(() => import('@app/pages/Management/Clients/ClientsManagement'));
const GroupsManagementPage = lazy(() => import('@app/pages/Management/Groups/GroupsManagement'));
const UsersManagementPage = lazy(() => import('@app/pages/Management/Users/UsersManagement'));
const ManagersManagementPage = lazy(() => import('@app/pages/Management/Managers/ManagersManagement'));
const NewClientPage = lazy(() => import('@app/pages/Management/Clients/NewClient/NewClientsManagement'));
const NewUserPage = lazy(() => import('@app/pages/Management/Users/NewUser/NewUsersManagement'));
const EditUserPage = lazy(() => import('@app/pages/Management/Users/EditUser/EditUsersManagement'));
const NewManagerPage = lazy(() => import('@app/pages/Management/Managers/NewManager/NewManagerManagement'));
const EditManagerPage = lazy(() => import('@app/pages/Management/Managers/EditManager/EditManagersManagement'));
const PlaybookFormPage = lazy(() => import('@app/pages/PlaybookFormPage/PlaybookFormPage'));
const LogsTable = lazy(() => import('../layouts/main/sider/Logs/LogsTable'));
const RavenpostSettings = lazy(() => import('../RavenPost/ConfigurationMenu/SettingsRavenpost'));
const EditRscoutMachine = lazy(() => import('../RavenScout/components/Management3/Machines/EditMachine'));
const NewMachine = lazy(() => import('../RavenScout/components/Management3/Machines/NewMachine'));
const RscoutConfigurationPage = lazy(() => import('../RavenScout/components/RscoutConfigurationPage'));
const VmsManagementListPage = lazy(
  () => import('@app/pages/Management/vms/VmsManagementListPage/VmsManagementListPage'),
);
const ClientSettingsPage = lazy(() => import('../RavenSight/ClientSettings/ClientSettingsPage'));
const VmProvider = lazy(() => import('../context/VmProvider'));
const VmsManagementViewerPage = lazy(() => import('@app/pages/Management/vms/VmsManagementViewPage'));
const PlaybooksSettingsPage = lazy(() => import('@app/pages/PlaybookFormPage/PlaybooksSettingsPage'));
const NewToolFormPage = lazy(() => import('@app/pages/PlaybookFormPage/NewToolFormPage'));
const AssetsManagementLayout = lazy(() => import('@app/pages/Management/Assets/AssetsManagementLayout'));
const EditAssetPage = lazy(() => import('@app/pages/Management/Assets/EditAssetPage'));
const RavenSightConfigurationPage = lazy(
  () => import('../RavenSight/RavenSightConfiguration/RavenSightConfigurationPage'),
);
const General = lazy(() => import('@app/pages/Management/General/General'));
const EditClientManagement = lazy(() => import('@app/pages/Management/Clients/EditClient/EditClientManagement'));
const IntelligenceFeeds = lazy(() => import('@app/pages/Management/IntelligenceFeeds/IntelligenceFeeds'));
const IntelligenceFeedsForm = lazy(() => import('@app/pages/Management/IntelligenceFeeds/IntelligenceFeedForm')); //?

const EditAsset = withAccess(EditAssetPage);
const ClientsManagement = withAccess(ClientsManagementPage);
const GroupsManagement = withAccess(GroupsManagementPage);
const UsersManagement = withAccess(UsersManagementPage);
const ManagersManagement = withAccess(ManagersManagementPage);
const NewClient = withAccess(NewClientPage);
const EditClient = withAccess(EditClientManagement);
const IntelligenceFeedsSettings = withAccess(IntelligenceFeeds);
const NewUser = withAccess(NewUserPage);
const EditUser = withAccess(EditUserPage);
const NewManager = withAccess(NewManagerPage);
const EditManager = withAccess(EditManagerPage);
const Logs = withAccess(LogsTable);
const RpostConfiguration = withAccess(RavenpostSettings);
const PlaybookForm = withAccess(PlaybookFormPage);
const RscoutMachines = withAccess(RscoutConfigurationPage);
const EditRscoutMachines = withAccess(EditRscoutMachine);
const NewMachines = withAccess(NewMachine);
const VMsManagementList = withAccess(VmsManagementListPage);
const VMManagementViewer = withAccess(VmsManagementViewerPage);
const ClientSettings = withAccess(ClientSettingsPage);
const PlaybooksSettings = withAccess(PlaybooksSettingsPage);
const NewToolForm = withAccess(NewToolFormPage);
const AssetsManagement = withAccess(AssetsManagementLayout);
const RavenSightConfiguration = withAccess(RavenSightConfigurationPage);
const GeneralManagementPage = withAccess(General);
const FeedsForm = withAccess(IntelligenceFeedsForm);

const managementRouter = [
  {
    path: 'general',
    element: <GeneralManagementPage role={UserRoles.SUPERADMIN} />,
  },
  {
    path: 'playbooks',
    children: [
      {
        path: '',
        element: <PlaybooksSettings />,
      },
      {
        path: 'new',
        element: <PlaybookForm />,
      },
      {
        path: 'tools/new',
        element: <NewToolForm />,
      },
    ],
  },
  {
    path: 'assets',
    children: [
      {
        path: '',
        element: <AssetsManagement />,
      },
      {
        path: 'edit/:assetId', //TODO: quitar el edit/
        element: <EditAsset />,
      },
    ],
  },
  {
    path: 'clients',
    element: <ClientsManagement />,
  },
  {
    path: 'client/new', //TODO: Meter dentro de clients (clients/new)
    element: <NewClient />,
  },
  {
    path: 'client/edit/:id', //TODO: meter dentro de clients y quitar edit (clients/:id)
    element: <EditClient />,
  },
  {
    path: 'groups',
    element: <GroupsManagement />,
  },
  {
    path: 'users',
    children: [
      {
        path: '',
        element: <UsersManagement />,
      },
      {
        path: ':username/edit', //TODO: Quitar edit
        element: <EditUser />,
      },
    ],
  },
  {
    path: 'user/new', //TODO: Meter dentro de users (users/new)
    element: <NewUser />,
  },
  {
    path: 'managers',
    element: <ManagersManagement />,
  },
  {
    path: 'manager/new', //TODO: Meter dentro de managers (managers/new)
    element: <NewManager />,
  },
  {
    path: 'manager/edit', //TODO: Meter dentro de managers (managers/:idManager) - Revisar por qué no se le pasa el id en la ruta...
    element: <EditManager />,
  },
  {
    path: 'feeds',
    children: [
      {
        path: '',
        element: <IntelligenceFeedsSettings role={UserRoles.SUPERADMIN} />,
      },
      {
        path: 'edit', //TODO: Revisar por qué no tiene el id en la ruta (se está pasando por el router?)
        element: <FeedsForm role={UserRoles.SUPERADMIN} />,
      },
    ],
  },
  {
    path: 'logs',
    element: <Logs />,
  },
  {
    path: 'rpost-settings',
    element: <RpostConfiguration />,
  },
  {
    path: 'rsight-settings',
    element: <RavenSightConfiguration role={UserRoles.SUPERADMIN} />,
  },
  {
    path: 'machines',
    children: [
      {
        path: '',
        element: <RscoutMachines role={UserRoles.SUPERADMIN} />,
      },
      {
        path: 'edit', //TODO: usar el id
        element: <EditRscoutMachines role={UserRoles.SUPERADMIN} />,
      },
      {
        path: 'new',
        element: <NewMachines role={UserRoles.SUPERADMIN} />,
      },
    ],
  },
  {
    path: 'vms',
    element: <VmProvider />,
    children: [
      {
        path: '',
        element: <VMsManagementList />,
      },
      {
        path: ':vmId',
        element: <VMManagementViewer />,
      },
    ],
  },
  {
    path: 'client_settings', //TODO: Usar guión en vez de guión bajo
    element: <ClientSettings />,
  },
];

export default managementRouter;
