import { useQueryHandler } from '@app/api/hooks/useQueryHandler';
import { useQuery } from '@tanstack/react-query';
import { getAnalysisRsightSandboxesAvailable } from '../requests/analyses.api';
import { rsightSandboxesAvailableAdapter } from '../adapters/rsightSandboxesAvailable.adapter';
import { useTranslation } from 'react-i18next';

export const useRsightSandboxesAvailable = () => {
  const { t } = useTranslation();

  return useQueryHandler(
    useQuery({
      queryKey: ['getAnalysisRsightSandboxesAvailable'],
      queryFn: getAnalysisRsightSandboxesAvailable,
      select: (data) => rsightSandboxesAvailableAdapter(data),
    }),

    {
      errorMessage: t('common.loadingDataError'),
    },
  );
};
