import styled, { css } from 'styled-components';
import { Col, Collapse, Menu, Select } from 'antd';
import { BurgerIcon } from '@app/components/common/Burger/BurgerIcon';
import { GitHubButton } from '@app/components/header/components/GithubButton/GitHubButton';
import { BORDER_RADIUS, FONT_SIZE, LAYOUT, media } from '@app/styles/themes/constants';

export const MenuHeader = styled(Menu as any)`
  border: 0;
  box-shadow: none;
  background: transparent;
  font-size: ${FONT_SIZE.xs};
  padding-top: 1vh;

  .anticon {
    font-size: ${FONT_SIZE.xs};
  }

  .ant-menu-item,
  .ant-menu-submenu {
    padding-left: 0 !important;
    padding-right: 1vw !important;
  }

  .ant-menu-item-selected::after,
  .ant-menu-submenu:hover::after,
  .ant-menu-item:hover::after {
    border-bottom: 2px solid var(--ant-primary-color) !important;
    right: 1vw;
    left: 0;
    //border-bottom: 0 !important;
  }

  .ant-menu-item-disabled {
    color: var(--text-sider-disabled-color) !important;
    a {
      color: var(--text-sider-disabled-color) !important;
    }
  }
`;

export const SelectHeader = styled(Select)`
  font-size: ${FONT_SIZE.xs};
  padding-top: 1vh;
  margin-right: 1vh;

  &.ant-select-selection-item,
  .ant-select-selection-item {
    line-height: inherit !important;
  }

  &.ant-select-selector,
  .ant-select-selector {
    height: 2vh !important;
    display: flex !important;
    align-items: center !important;
  }

  .ant-select-arrow {
    top: 70% !important;
    font-size: 1.2vh !important;
  }
`;

export const DropdownMenu = styled(Menu)`
  line-height: 1.5715;

  border-radius: ${BORDER_RADIUS};

  &.ant-dropdown-menu {
    box-shadow: var(--box-shadow);
  }
`;

export const HeaderActionWrapper = styled.div`
  cursor: pointer;

  & > .ant-btn > span[role='img'],
  .ant-badge {
    font-size: 1.25rem;

    @media only screen and (${media.md}) {
      font-size: 1.625rem;
    }
  }

  & .ant-badge {
    display: inline-block;
  }
`;

export const DropdownCollapse = styled(Collapse)`
  & > .ant-collapse-item > .ant-collapse-header {
    font-weight: 600;
    font-size: 0.875rem;

    color: var(--primary-color);

    @media only screen and (${media.md}) {
      font-size: 1rem;
    }
  }

  & > .ant-collapse-item-disabled > .ant-collapse-header {
    cursor: default;

    & > span[role='img'] {
      display: none;
    }
  }
`;

export const BurgerCol = styled(Col)`
  z-index: 999;
  display: flex;
`;

export const MobileBurger = styled(BurgerIcon)`
  width: 1.75rem;
  height: 1.75rem;
  margin-right: -0.5rem;
  color: var(--text-main-color);

  ${(props) =>
    props.isCross &&
    css`
      color: var(--text-secondary-color);
    `};
`;

export const DesktopHeader = styled(Col)`
  background-color: var(--sider-background-color);
`;

export const MobileHeader = styled(Col)`
  background-color: var(--sider-background-color);
`;

export const SearchColumn = styled(Col)`
  padding: ${LAYOUT.desktop.paddingVertical} ${LAYOUT.desktop.paddingHorizontal};
`;

interface ProfileColumn {
  $isTwoColumnsLayout: boolean;
}

export const ProfileColumn = styled(Col)<ProfileColumn>`
  @media only screen and (${media.md}) {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    ${(props) =>
      props?.$isTwoColumnsLayout &&
      css`
        background-color: var(--sider-background-color);
        padding: ${LAYOUT.desktop.paddingVertical} ${LAYOUT.desktop.paddingHorizontal};
      `}
  }
`;

export const GHButton = styled(GitHubButton)`
  display: none;

  @media only screen and (${media.lg}) {
    display: block;
  }
`;

export const HeaderDropdownWrapper = styled.div`
  padding: 0 1rem;
  display: flex;
`;
