import React, { useRef } from 'react';
import { CardProps as AntCardProps } from 'antd';
import { useResponsive } from '@app/hooks/useResponsive';
import * as S from '@app/components/common/CardInvisible/CardInvisible.styles';
import { HelpIcon } from '@app/components/common/HelpIcon/HelpIcon';
import { FONT_SIZE } from '@app/styles/themes/constants';
import { FullScreen, FullScreenHandle, useFullScreenHandle } from 'react-full-screen';
import { FullScreenExtraIcon } from '@app/components/common/FullScreenExtraIcon/FullScreenExtraIcon';
import { HeaderActionWrapper } from '@app/components/header/Header.styles';
import { Button } from '@app/components/common/buttons/Button/Button';
import { FullscreenExitOutlined, FullscreenOutlined } from '@ant-design/icons';
import { RequireFullscreen } from '@app/components/common/RequireFullscreen/RequireFullscreen';

export interface CardInvisibleProps extends AntCardProps {
  className?: string;
  padding?: string | number | [number, number];
  autoHeight?: boolean;
  justifyContent?: string;

  fullScreen?: boolean;

  display?: string | number;
  displayCard?: string | number;
  fontSizeTitle?: string | number;
  fontSizeText?: string | number;
  helpText?: string | React.Component;
  typeHelpText?: 'modal' | 'tooltip';

  $bodyStyle?: any;
  bordered?: boolean;
  title?: string | any;
}

export const CardInvisible: React.FC<CardInvisibleProps> = ({
  className,
  padding = '1vh',
  display,
  displayCard,
  size,
  autoHeight = true,
  fontSizeTitle,
  fontSizeText,
  children,
  helpText,
  typeHelpText,
  fullScreen,
  justifyContent,
  bordered = false,
  ...props
}) => {
  const { isTablet } = useResponsive();
  const screen1 = useFullScreenHandle();
  const rootRef = useRef(null);

  const CardComponent = (
    <S.CardInvisible
      ref={rootRef}
      type="inner"
      size={size ? size : isTablet ? 'default' : 'small'}
      className={fullScreen ? (className ? className + ' graphIOC-fullScreen' : 'graphIOC-fullScreen') : className}
      bordered={bordered}
      $autoHeight={autoHeight}
      $padding={padding}
      $justifyContent={justifyContent ? justifyContent : 'center'}
      $fontSizeTitle={fontSizeTitle}
      $fontSizeText={fontSizeText ? fontSizeText : FONT_SIZE.xxs}
      $display={display ? display : 'flex'}
      $displayCard={displayCard ? displayCard : 'flex'}
      bodyStyle={{
        fontSize: fontSizeText ? fontSizeText : FONT_SIZE.xxs,
        border: bordered ? null : 'none',
        justifyContent: justifyContent ? justifyContent : 'center',
        padding: padding,
        ...props.$bodyStyle,
      }}
      headStyle={{
        fontSize: fontSizeTitle ? fontSizeTitle : FONT_SIZE.xs,
        ...props.$headStyle,
      }}
      extra={
        helpText ? (
          <HelpIcon message={helpText} title={props.title} type={typeHelpText ? typeHelpText : 'tooltip'} />
        ) : fullScreen ? (
          // <FullScreenExtraIcon screen={screen1} />
          <RequireFullscreen component={rootRef}>
            {(isFullscreen) => (
              <S.FullScreenButton>
                <Button
                  type={isFullscreen ? 'ghost' : 'text'}
                  icon={isFullscreen ? <FullscreenExitOutlined /> : <FullscreenOutlined />}
                />
              </S.FullScreenButton>
            )}
          </RequireFullscreen>
        ) : (
          props.extra
        )
      }
      {...props}
    >
      {React.isValidElement(children) && fullScreen
        ? React.cloneElement(children, { isFullScreen: screen1?.active })
        : children}
    </S.CardInvisible>
  );

  return <>{CardComponent}</>;
};
