import { FormTitleWrapper } from '@app/components/common/forms/components/FormTitleWrapper/FormTitleWrapper';
import { Trans, useTranslation } from 'react-i18next';
import { transComponents } from '../HelpContent';

const HelpManagementClients = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'help.management.accessManagement.clients' });

  return (
    <>
      <FormTitleWrapper>
        <h5 id="clients">
          <Trans t={t} i18nKey="title" components={transComponents} />
        </h5>
      </FormTitleWrapper>
      <p>
        <Trans t={t} i18nKey="p1" components={transComponents} />
      </p>
      <h6 id="clients-image1">IMAGE</h6>
      <p>
        <Trans t={t} i18nKey="p2" components={transComponents} />
      </p>
      <h6 id="clients-image2">IMAGE</h6>
      <p>
        <Trans t={t} i18nKey="p3" components={transComponents} />
      </p>
      <ul>
        <li>
          <Trans t={t} i18nKey="ul1.li1" components={transComponents} />
        </li>
        <li>
          <Trans t={t} i18nKey="ul1.li2" components={transComponents} />
        </li>
        <li>
          <Trans t={t} i18nKey="ul1.li3" components={transComponents} />
        </li>
      </ul>
      <h6 id="clients-image3">IMAGE</h6>
      <p>
        <Trans t={t} i18nKey="p4" components={transComponents} />
      </p>
      <h6 id="clients-image4">IMAGE</h6>
      <p>
        <Trans t={t} i18nKey="p5" components={transComponents} />
      </p>
      <h6 id="clients-image5">IMAGE</h6>
      <p>
        <Trans t={t} i18nKey="p6" components={transComponents} />
      </p>
      <h6 id="clients-image6">IMAGE</h6>
    </>
  );
};

export default HelpManagementClients;
