import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { BaseForm } from '@app/components/common/forms/BaseForm/BaseForm';
import * as Auth from '@app/components/layouts/AuthLayout/AuthLayout.styles';
import * as S from './RecoverPassword.styles';

export const EmailNotFound = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const navigateBack = useCallback(() => navigate(-1), [navigate]);

  return (
    <Auth.FormWrapper>
      <BaseForm layout="vertical" requiredMark="optional">
        <Auth.BackWrapper onClick={navigateBack}>
          <Auth.BackIcon />
          {t('common.back')}
        </Auth.BackWrapper>
        <S.ContentWrapper>
          <Auth.FormTitle>{t('recoverPassword.noEmailFound')}</Auth.FormTitle>
          <S.VerifyEmailDescription>{t('recoverPassword.messageNoEmailFound')}</S.VerifyEmailDescription>
        </S.ContentWrapper>
      </BaseForm>
    </Auth.FormWrapper>
  );
};
