import React from 'react';
import { DesktopHeader } from './layouts/DesktopHeader';
import { MobileHeader } from './layouts/MobileHeader';
import { useResponsive } from '@app/hooks/useResponsive';
// import * as S from './Header.styles';

interface HeaderProps {
  toggleSider: () => void;
  isSiderOpened: boolean;
  isTwoColumnsLayout: boolean;
}

export const Header: React.FC<HeaderProps> = ({ toggleSider, isSiderOpened, isTwoColumnsLayout }) => {
  const { isTablet } = useResponsive();

  return isTablet ? (
    // <S.DesktopHeader>
    <DesktopHeader isTwoColumnsLayout={isTwoColumnsLayout} />
  ) : (
    // </S.DesktopHeader>
    // <S.MobileHeader>
    <MobileHeader toggleSider={toggleSider} isSiderOpened={isSiderOpened} />
    // </S.MobileHeader>
  );
};
