//? CSS que hay que pasar a styledComponents o al css global
import '@app/components/common/CardUploadForm/styles.css';

import { createBrowserRouter, RouterProvider } from 'react-router-dom';

// no lazy loading for auth pages to avoid flickering
const AuthLayout = lazy(() => import('@app/components/layouts/AuthLayout/AuthLayout'));

import { withAccess } from '@app/hocs/withAccess';
// import SignUpPage from '@app/pages/SignUpPage';
// import ForgotPasswordPage from '@app/pages/ForgotPasswordPage';
// import SecurityCodePage from '@app/pages/SecurityCodePage';
// import NewPasswordPage from '@app/pages/NewPasswordPage';
// import LockPage from '@app/pages/LockPage';

import MainLayout from '@app/components/layouts/main/MainLayout/MainLayout';
// import ProfileLayout from '@app/components/profile/ProfileLayout';
import RequireAuth from '@app/components/router/RequireAuth';
import { withLoading } from '@app/hocs/withLoading.hoc';

import { UserRoles } from '@app/domain/rolesModel';
import rsightRouter from './rsightRouter';
import rwireRouter from './rwireRouter';
import { lazy } from 'react';
import rscoutRouter from './rscoutRouter';
import dashboardRouter from './dashboardRouter';
import managementRouter from './managementRouter';
import ForgotPasswordPage from '@app/pages/ForgotPasswordPage';
import SecurityCodePage from '@app/pages/SecurityCodePage';
import NewPasswordPage from '@app/pages/NewPasswordPage';
import { EmailNotFound } from '../auth/RecoverPassword/EmailNotFound';
import { EmailFound } from '../auth/RecoverPassword/EmailFound';
import { RecoverPassword } from '../auth/RecoverPassword/RecoverPassword';
import Help from '@app/pages/Help/Help';
import ErrorBoundary from '@app/pages/ErrorBoundary';

const LoginPage = lazy(() => import('@app/pages/LoginPage'));

const RavenloopDashboardPage = lazy(() => import('@app/pages/DashboardPages/RavenloopDashboardPage'));

const IOCsListPage = lazy(() => import('@app/pages/IOCs/IOCsListPage'));
const IOCElementPage = lazy(() => import('@app/pages/IOCs/IOCElementPage'));
const RaventraceDashboardPage = lazy(() => import('@app/pages/DashboardPages/Raventrace/RaventraceDashboardPage'));
const RavenwireLayout = lazy(() => import('@app/pages/DashboardPages/Ravenwire/RavenwireLayout'));
const RavensightLayout = lazy(() => import('@app/pages/DashboardPages/Ravensight/RavensightLayout'));
const RaventraceLayout = lazy(() => import('@app/pages/DashboardPages/Raventrace/RaventraceLayout'));
const RavenscoutLayout = lazy(() => import('@app/pages/DashboardPages/Ravenscout/RavenscoutLayout'));
const RavenpostLayout = lazy(() => import('@app/pages/DashboardPages/Ravenpost/RavenpostLayout'));
const ManagementLayout = lazy(() => import('@app/pages/Management/ManagementLayout'));
const RaventraceFilesListPage = lazy(() => import('@app/pages/DashboardPages/Raventrace/RaventraceFilesListPage'));
const RavenpostDashboardPage = lazy(() => import('@app/pages/DashboardPages/Ravenpost/RavenpostDashboardPage'));
const RavenpostMailsListPage = lazy(() => import('@app/pages/DashboardPages/Ravenpost/RavenpostMailsListPage'));

const AlertsDashboardPage = lazy(() => import('@app/pages/Alerts/AlertsDashboardPage'));

const MailElementPage = lazy(() => import('@app/pages/Mail/MailElementPage'));
const VulnerabilitiesList = lazy(
  () => import('../RavenScout/Assets/management/VulnerabilitiesListTable/VulnerabilitiesList'),
);
const CreateVulnerabilityPage = lazy(
  () => import('../RavenScout/Assets/management/createVulnerability/CreateVulnerabilityPage'),
);
const EditVulnerabilityPage = lazy(
  () => import('../RavenScout/Assets/management/editVulnerabily/EditVulnerabilityPage'),
);
const NewAssetPage = lazy(() => import('@app/pages/Management/Assets/NewAssetPage'));
// const PlaybooksSettingsPage = lazy(() => import('@app/components/RavenScout/PlaybooksSettings/PlaybooksSettingsPage'));

const NoModulePage = lazy(() => import('@app/pages/NoModulePage'));
const FirewallDashboard = lazy(() => import('@app/components/Firewall/FirewallDashboard'));
const FirewallLayout = lazy(() => import('@app/pages/Firewall/FirewallLayout'));
const EditUserProfile = lazy(() => import('@app/pages/Management/Users/EditProfile/EditProfileManagement'));

const DataAlertPage = lazy(() => import('../Alerts/Dash/DataAlert/DataAlertPage'));

const ServerErrorPage = lazy(() => import('@app/pages/ServerErrorPage'));
const Error404Page = lazy(() => import('@app/pages/Error404Page'));
// const PersonalInfoPage = lazy(() => import('@app/pages/PersonalInfoPage'));
// const SecuritySettingsPage = lazy(() => import('@app/pages/SecuritySettingsPage'));
// const NotificationsPage = lazy(() => import('@app/pages/NotificationsPage'));
const Logout = lazy(() => import('./Logout'));
// const downloadCertificate = lazy(() => import('./DownloadCertificate'));
const downloadCertificate = <></>;

export const ROOT_PATH = '/';

// WITH ACCESS
const RavenloopDashboard = withAccess(RavenloopDashboardPage);
const RaventraceDashboard = withAccess(RaventraceDashboardPage);
const AlertsDashboard = withAccess(AlertsDashboardPage);
const AlertData = withAccess(DataAlertPage);

const RavenpostDashboard = withAccess(RavenpostDashboardPage);
const IOCsList = withAccess(IOCsListPage);
const RaventraceFilesList = withAccess(RaventraceFilesListPage);

const IOCElement = withAccess(IOCElementPage);
const NewAsset = withAccess(NewAssetPage); // TODO: Revisar por qué no se usa
const DownloadCertificate = withAccess(downloadCertificate);
const RavenpostMailsList = withAccess(RavenpostMailsListPage);
const MailElement = withAccess(MailElementPage);
const VulnerabilitiesListManager = withAccess(VulnerabilitiesList);
const CreateVulnerability = withAccess(CreateVulnerabilityPage);
const EditVulnerability = withAccess(EditVulnerabilityPage);

const FirewallDashboardPage = withAccess(FirewallDashboard);

// Errors
const ServerError = withLoading(ServerErrorPage);
const Error404 = withLoading(Error404Page);

// Profile
// const PersonalInfo = withLoading(PersonalInfoPage);
// const SecuritySettings = withLoading(SecuritySettingsPage);
// const Notifications = withLoading(NotificationsPage);

const AuthLayoutFallback = withLoading(AuthLayout);
const RavensightLayoutFallback = withLoading(RavensightLayout);
const RavenwireLayoutFallback = withLoading(RavenwireLayout);
const RaventraceLayoutFallback = withLoading(RaventraceLayout);
const RavenscoutLayoutFallback = withLoading(RavenscoutLayout);

const RavenpostLayoutFallback = withLoading(RavenpostLayout);
const LogoutFallback = withLoading(Logout);
const FirewallLayoutFallback = withLoading(FirewallLayout);
const ManagementLayoutFallback = withAccess(ManagementLayout);
const EditProfile = withAccess(EditUserProfile);

const router = createBrowserRouter([
  {
    path: ROOT_PATH,
    element: (
      <RequireAuth>
        <MainLayout />
      </RequireAuth>
    ),
    errorElement: (
      <RequireAuth>
        <MainLayout>
          <ErrorBoundary />
        </MainLayout>
      </RequireAuth>
    ),
    children: [
      {
        path: '',
        element: <RavenloopDashboard />,
      },
      {
        path: 'alerts',
        children: [
          {
            path: '',
            element: <AlertsDashboard />,
          },
          {
            path: ':alertId',
            element: <AlertData />,
          },
        ],
      },
      {
        path: 'iocs',
        children: [
          {
            path: '',
            element: <IOCsList />,
          },
        ],
      },
      {
        path: 'ioc/:iocId', //TODO: Meter en iocs (iocs/:iocId)
        element: <IOCElement />,
      },
      {
        path: 'rsight',
        element: <RavensightLayoutFallback />,
        children: rsightRouter,
      },
      {
        path: 'rwire',
        element: <RavenwireLayoutFallback />,
        children: rwireRouter,
      },
      {
        path: 'rtrace',
        element: <RaventraceLayoutFallback />,
        children: [
          {
            path: '',
            element: <RaventraceDashboard />,
          },
          {
            path: 'files',
            element: <RaventraceFilesList />,
          },
        ],
      },
      {
        path: 'rscout',
        element: <RavenscoutLayoutFallback />,
        children: rscoutRouter,
      },
      {
        path: 'rpost',
        element: <RavenpostLayoutFallback />,
        children: [
          {
            path: '',
            element: <RavenpostDashboard />,
          },
          {
            path: 'mails',
            element: <RavenpostMailsList />,
          },
          {
            path: 'mail/:mailId', //TODO: Meter en mails (mails/:mailId)
            element: <MailElement />,
          },
        ],
      },
      {
        path: 'dashboard',
        children: dashboardRouter,
      },
      {
        path: 'firewall',
        element: <FirewallLayoutFallback />,
        children: [
          {
            path: '',
            element: <FirewallDashboardPage />,
          },
        ],
      },
      {
        path: 'management',
        element: <ManagementLayoutFallback role={UserRoles.ADMIN} />,
        children: managementRouter,
      },
      {
        path: 'profile', //? Este se puede mantener porque es el usuario logueado
        element: <EditProfile role={UserRoles.USER} />,
      },
      {
        path: 'vulnerabilities-list', //TODO: eliminar "-list"
        children: [
          {
            path: '',
            element: <VulnerabilitiesListManager />,
          },
          {
            path: 'create', // TODO: Cambiar por "new"
            element: <CreateVulnerability />,
          },
          {
            path: 'edit/:elastic_id', //TODO: quitar "edit/" y cambiar el id a camelCase
            element: <EditVulnerability />,
          },
        ],
      },
      {
        path: 'forbidden',
        element: <NoModulePage />,
      },
      {
        path: 'server-error',
        element: <ServerError />,
      },
      {
        path: '*',
        element: <Error404 />,
      },
      {
        path: 'help',
        element: <Help />,
      },
    ],
  },
  {
    path: 'auth',
    element: <AuthLayoutFallback />,
    children: [
      {
        path: 'login',
        element: <LoginPage />,
      },
      {
        path: 'forgot-password',
        element: <ForgotPasswordPage />,
      },
      {
        path: 'security-code',
        element: <SecurityCodePage />,
      },
      {
        path: 'email-found',
        element: <EmailFound />,
      },
      {
        path: 'email-not-found',
        element: <EmailNotFound />,
      },
      {
        path: 'new-password',
        element: <NewPasswordPage />,
      },
    ],
  },
  {
    path: `/recover`,
    element: (
      <AuthLayoutFallback>
        <RecoverPassword />
      </AuthLayoutFallback>
    ),
  },
  {
    path: 'logout',
    element: <LogoutFallback />,
  },
  {
    path: 'certificate',
    element: <DownloadCertificate />,
  },
]);

export const AppRouter = () => {
  return <RouterProvider router={router} />;
};
