import styled from 'styled-components';

export const TitleLoginStyle = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 1vh;
  align-items: center;
`;

export const LogoStyle = styled.img`
  display: inline-block;
  height: 5rem;
  margin-top: -1rem;
`;

export const RavenNameStyle = styled.label`
  color: white;
  line-height: 4vh;
  font-size: 4.8vh !important;
  margin-left: 1vh;
  font-family: 'Didact Gothic', sans-serif;
`;
