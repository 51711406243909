import React from 'react';
import * as S from './Tag.styles';

export interface ITag {
  id: string;
  title: string;
  bgColor: string;
}

interface TagProps {
  title: string;
  color?: string;
  bgColor?: string;
}

export const EmptyDefaultTag: React.FC<TagProps> = ({ title, color, bgColor, width, maxWidth, ...otherProps }) => {
  const style = {
    color: color || 'var(--white)',
    backgroundColor: bgColor || 'grey',
    width: width || 'fit-content',
    maxWidth: maxWidth || '100%',
  };
  return (
    <S.TagWrapper style={style} {...otherProps}>
      {title || 'No data'}
    </S.TagWrapper>
  );
};
