import LoadingPageError from './LoadingPageError';
import { useRouteError } from 'react-router-dom';

const ErrorBoundary = () => {
  const error = useRouteError();
  console.error('Error loading page: ', error);
  return <LoadingPageError />;
};

export default ErrorBoundary;
