import { Avatar, Space, Typography } from 'antd';
import styled, { css } from 'styled-components';
import { NotificationType } from './Notification';
import { FONT_SIZE_VH as FONT_SIZE } from '@app/styles/themes/constants';

interface SpacewWrapperProps {
  type: NotificationType;
}

export const NotificationIcon = styled(Avatar)``;

export const Title = styled(Typography.Text)`
  font-size: ${FONT_SIZE.md};
  font-weight: 600;
`;

export const Description = styled(Typography.Text)`
  font-size: ${FONT_SIZE.md};
`;

export const SpaceWrapper = styled(Space)<SpacewWrapperProps>`
  background-color: var(--background-color);
  // display: flex;

  .ant-space-item:nth-child(2) {
    overflow-x: auto;
  }

  & ${Title}, span[role='img'] {
    ${(props) => {
      switch (props.type) {
        case 'error':
        case 'warning':
        case 'success':
          return css`
            color: var(--${props.type}-color);
          `;
        case 'info':
        case 'mention':
          return css`
            color: var(--primary-color);
          `;
        default:
          return '';
      }
    }}
  }

  & span[role='img'] {
    font-size: 2.25vh;
  }
`;
