import React from 'react';
import * as S from './TagNeon.styles';
import styled from 'styled-components';
import { MdClose } from 'react-icons/md';
import { FONT_SIZE_VH } from '@app/styles/themes/constants';
import { useRgbaColor } from '@app/hooks/useRgbaColor';

export interface ITagNeon {
  id: string;
  title: string;
  bgColor: string;
}

interface TagNeonProps {
  title?: string | React.ReactNode;
  color?: string;
  bgColor?: boolean;
  closable?: boolean;
  onClose?: (() => void) | undefined;
  width?: string | number;
  maxWidth?: string | number;
  height?: string | number;
  display?: string;
  otherStyle?: any;
  className?: string;
  $radius?: 'round' | 'square';
}

const CloseTag = styled.button`
  display: flex;
  background-color: transparent;
  border: none;
  cursor: pointer;
  font-size: ${FONT_SIZE_VH.xxxl};
  margin-right: -5px;
  margin-left: ${FONT_SIZE_VH.s};
`;

export const TagNeon = ({
  title,
  color,
  bgColor,
  width,
  height,
  maxWidth,
  display,
  otherStyle,
  closable = false,
  onClose,
  ...otherProps
}: TagNeonProps) => {
  const { getRgbaColor } = useRgbaColor();

  const style = {
    color: color ? getRgbaColor(color, '3') : getRgbaColor('tagNeonColor', '1'),
    border: color
      ? `1px solid ${getRgbaColor(color, '0.7')}`
      : `1px solid ${getRgbaColor('tagNeonBorderColor', '0.7')}`,
    backgroundColor: bgColor
      ? color
        ? getRgbaColor(color, '0.1')
        : getRgbaColor('tagNeonBgColor', '0.1')
      : 'transparent',
    width: width || 'fit-content',
    maxWidth: maxWidth || '100%',
    height: height || null,
    display: display || 'flex',
    borderRadius: otherProps.$radius === 'round' ? '1rem' : otherProps.$radius === 'square' ? '0' : '0.5rem',
    fontWeight: 'bold',
    ...otherStyle,
  };

  return (
    <S.TagWrapper style={style} {...otherProps}>
      {title}
      {closable && (
        <CloseTag onClick={onClose}>
          <MdClose />
        </CloseTag>
      )}
    </S.TagWrapper>
  );
};
