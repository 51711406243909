import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../store';

interface AnalysisSlice {
  items: any[];
  activeTabKey: string;
  itemsMenuExtra: any[];
  activeExtraKey: string;
}
const initialState: AnalysisSlice = {
  items: [],
  activeTabKey: '',
  itemsMenuExtra: [],
  activeExtraKey: 'principal',
};

export const analysisSlice = createSlice({
  name: 'analysis',
  initialState,
  reducers: {
    changeExtraKey: (state, action) => {
      state.activeExtraKey = action.payload;
    },
    changeTabKey: (state, action) => {
      state.activeTabKey = action.payload;
    },
    changeItems: (state, action) => {
      state.items = action.payload;
    },
    changeItemsMenuExtra: (state, action) => {
      state.itemsMenuExtra = action.payload;
    },
  },
  extraReducers: () => {},
});

export const { changeExtraKey, changeItems, changeItemsMenuExtra, changeTabKey } = analysisSlice.actions;

export const selectAnalysisTabItems = (state: RootState) => state.analysis.items;
export const selectAnalysisActiveTabKey = (state: RootState) => state.analysis.activeTabKey;
export const selectAnalysisActiveExtraKey = (state: RootState) => state.analysis.activeExtraKey;
export const selectAnalysisItemsMenuExtra = (state: RootState) => state.analysis.itemsMenuExtra;

export default analysisSlice.reducer;
