export const BORDER_RADIUS = '7px';

export const BASE_COLORS = {
  white: '#ffffff',
  black: '#000000',
  green: '#77dd77',
  orange: '#ffb155',
  gray: '#808080',
  lightgrey: '#c5d3e0',
  violet: '#ee82ee',
  lightgreen: '#89dca0',
  pink: '#ffc0cb',
  blue: '#0000ff',
  skyblue: '#35a0dc',
  red: '#ff5252',
  amber: '#ff7e00',
  bluishgreen: '#1dcfcc',
};

export const LAYOUT = {
  mobile: {
    paddingVertical: '0rem',
    paddingHorizontal: '1rem',
    headerHeight: '3rem',
    headerSliderHeight: '5rem',
    headerPadding: '1rem',
  },
  desktop: {
    paddingVertical: '0rem',
    paddingHorizontal: '1rem',
    headerHeight: '3rem',
    headerSliderHeight: '4rem',
  },
};

export const FONT_FAMILY = {
  main: 'Montserrat',
  secondary: 'Lato',
};

export const FONT_SIZE = {
  xxs: '0.75rem',
  xs: '0.875rem',
  md: '1rem',
  lg: '1.125rem',
  xl: '1.25rem',
  xxl: '1.5rem',
  xxxl: '1.625rem',
  xxxxl: '2rem',
};

export const FONT_SIZE_VW = {
  xxs: '0.5vw',
  xs: '0.675vw',
  md: '0.8vw',
  lg: '0.925vw',
  xl: '1.05vw',
  xxl: '1.3vw',
  xxxl: '1.425vw',
  xxxxl: '1.8vw',
};

export const FONT_SIZE_VH = {
  xxs: '0.65vh',
  xs: '0.8vh',
  s: '0.975vh',
  md: '1.125vh',
  lg: '1.225vh',
  xl: '1.35vh',
  xxl: '1.6vh',
  xxxl: '1.725vh',
  xxxxl: '2vh',
};

export const FONT_SIZE_BIG_NUMBER = '5vh';
export const FONT_SIZE_BIG_TEXT = '4vh';
export const FONT_SIZE_BIG_ICON = '4vh';

export const HEIGHT_TABLE_DESKTOP = 'calc(100vh - 4rem - 2vh - 4vh)';
export const HEIGHT_TABLE_SCROLL_DESKTOP = 'calc(100vh - 4rem - 2vh - 8vh)';
export const HEIGHT_TABLE_MOBILE = 'calc(100vh - 4rem - 2vh - 6vh)';
export const HEIGHT_TABLE_SCROLL_MOBILE = 'calc(100vh - 4rem - 2vh - 10vh)';
export const WIDTH_TABLE_SCROLL = 1200;
export const IMAGE_WIDTH_VH_TABLE = '10vh';
export const ICON_WIDTH_VH_TABLE = '2.25vh';
export const ICON_SMALL_WIDTH_VH_TABLE = '1.75vh';
export const ICON_SMALL_WIDTH_EM = '1em';

// export const FONT_SIZE = {
//     xxs: '0.75vw',
//     xs: '0.875vw',
//     md: '1vw',
//     lg: '1.125vw',
//     xl: '1.25vw',
//     xxl: '1.5vw',
//     xxxl: '1.625vw',
//     xxxxl: '2vw',
// };

export const FONT_WEIGHT = {
  thin: '100',
  extraLight: '200',
  light: '300',
  regular: '400',
  medium: '500',
  semibold: '600',
  bold: '700',
  extraBold: '800',
  black: '900',
};

export const BREAKPOINTS = {
  xs: 360,
  sm: 568,
  md: 768,
  lg: 992,
  xl: 1280,
  xxl: 1920,
  default: 1919,
} as const;

const getMedia = <T extends number>(breakpoint: T): `min-width: ${T}px` => `min-width: ${breakpoint}px`;

export const media = {
  xs: getMedia(BREAKPOINTS.xs),
  sm: getMedia(BREAKPOINTS.sm),
  md: getMedia(BREAKPOINTS.md),
  lg: getMedia(BREAKPOINTS.lg),
  xl: getMedia(BREAKPOINTS.xl),
  xxl: getMedia(BREAKPOINTS.xxl),
  default: getMedia(BREAKPOINTS.default),
};

export const keysRiskAlertsOrdered = ['very_high', 'high', 'alert', 'medium', 'low', 'warn', 'info'];
