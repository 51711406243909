import { Trans, useTranslation } from 'react-i18next';
import { transComponents } from './HelpContent';
import { FormTitleWrapper } from '@app/components/common/forms/components/FormTitleWrapper/FormTitleWrapper';
import { Image } from 'antd';
import image2 from '@app/assets/help/introduction-2.webp';
import image4 from '@app/assets/help/introduction-4.webp';

const HelpIntroduction = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'help.introduction' });

  return (
    <>
      <FormTitleWrapper>
        <h2 id="intro">
          <Trans t={t} i18nKey="title" components={transComponents} />
        </h2>
      </FormTitleWrapper>
      <p>
        <Trans t={t} i18nKey="p1" components={transComponents} />
      </p>
      <p>
        <Trans t={t} i18nKey="p2" components={transComponents} />
      </p>
      <h3 id="image1">IMAGE</h3>
      <p>
        <Trans t={t} i18nKey="p3" components={transComponents} />
      </p>
      <Image className="image" src={image2} preview={false} wrapperClassName="image-block-wrapper" />
      <p>
        <Trans t={t} i18nKey="p4" components={transComponents} />
      </p>
      <h3 id="image3">IMAGE</h3>
      <p>
        <Trans t={t} i18nKey="p5" components={transComponents} />
      </p>
      <Image className="image" src={image4} preview={false} wrapperClassName="image-block-wrapper" />
      <p>
        <Trans t={t} i18nKey="p6" components={transComponents} />
      </p>
    </>
  );
};

export default HelpIntroduction;
