import { Card } from '@app/components/common/Card/Card';
import { FONT_SIZE, media } from '@app/styles/themes/constants';
import { RefObject, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import styled from 'styled-components';

const TOCLayout = styled(Card)`
  display: none;
  @media screen and (${media.lg}) {
    display: block;
  }
  .ant-card-body {
    min-width: 15rem;
    max-height: 100%;
    overflow-y: auto;

    font-size: ${FONT_SIZE.md};
    padding: 2rem;
  }

  ul {
    list-style: none;
  }
`;

type TitleState = { text: string; level: number };

interface Props {
  textContainerRef: RefObject<null | HTMLElement>;
}

const HelpTOC = ({ textContainerRef }: Props) => {
  const { t } = useTranslation('translation', { keyPrefix: 'help.toc' });
  const location = useLocation();
  const [titles, setTitles] = useState<TitleState[]>([]);

  const handleClickToScroll = (id: string) => {
    if (!!textContainerRef?.current) {
      const element = textContainerRef.current.querySelector(`#${id}`);
      element?.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  };

  useEffect(() => {
    if (!!textContainerRef?.current) {
      const titlesList: TitleState[] = [];
      const hTitles = textContainerRef.current.querySelectorAll('h1, h2, h3, h4, h5, h6');
      hTitles.forEach((title) => {
        if (!!title.id) {
          titlesList.push({
            text: title.id,
            level: Number(title.nodeName.slice(1)),
          });
        }
      });
      setTitles(titlesList);
    }
  }, [textContainerRef]);

  return (
    <TOCLayout>
      <ul>
        {titles.map((title) => {
          return (
            <li key={`${title.level}${title.text}`} style={{ paddingLeft: `${title.level - 2}rem` }}>
              <Link to={`${location.pathname}#${title.text}`} onClick={() => handleClickToScroll(title.text)}>
                {t(title.text)}
              </Link>
            </li>
          );
        })}
      </ul>
    </TOCLayout>
  );
};

export default HelpTOC;
