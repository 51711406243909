import { httpApi } from '@app/api/http.api';
import { AnalysisRtraceDataInterface, AuditsRscoutApiResponse } from '@app/interfaces/interfaces';
import { readClientId } from '@app/services/localStorage.service';
import { AllAssociatedClientsApiResponse, AllAssociatedGroupsApiResponse } from '@app/api/rsight/interfaces/interfaces';

export const getManagementAllClients = (): Promise<AnalysisRtraceDataInterface> => {
  return httpApi.get('/management/clients');
};

export const editClient = async (form: any): Promise<any> => {
  const formPost = new FormData();
  formPost.append('data', JSON.stringify(form));
  formPost.append('logo', form.logo);
  const { data } = await httpApi.put(`/management/clients/edit`, formPost);
  return data;
};

export const getManagers = async (userId: number): Promise<any> => {
  const { data } = await httpApi.get(`/client/client_config/${userId}`);
  return data;
};

export const getDataClient = async (userId: number | string | undefined): Promise<any> => {
  const { data } = await httpApi.get(`/get_client_info/${userId}`);
  return data;
};

export const getAllClientsRscout = async (): Promise<{ all_clients_ret: Record<string, unknown>[] }> => {
  const args = { client_id: readClientId() };
  const { data } = await httpApi.get('/rscout/clients/associated', { params: args });
  return data;
};

export const deleteClientAPI = (client_id: number): Promise<string> => {
  // return httpApi.post('/manage/clients/' + action + '/' + client_id);
  return httpApi.post('/management/clients/disable/' + client_id);
};

export const reactivateClientAPI = (client_id: number): Promise<string> => {
  // return httpApi.post('/manage/clients/' + action + '/' + client_id);
  return httpApi.post('/management/clients/enable/' + client_id);
};

export const createNewClientAPI = async (form: any): Promise<string> => {
  const formPost = new FormData();
  formPost.append('data', JSON.stringify(form));
  formPost.append('logo', form.logo);
  const { data } = await httpApi.post('/management/clients/new', formPost);
  return data;
};

export const getAllAssociatedClients = (
  usser_id_assoc?: number | string,
): Promise<{ data: AllAssociatedClientsApiResponse }> => {
  return httpApi.post('/get/associated/clients/' + usser_id_assoc);
};

export const checkGroupDoesNotExist = (
  associated_client: number | string,
  tipo: string,
  group_name: string,
): Promise<string> => {
  return httpApi.get(`/check/group/name/${associated_client}/${tipo}/${group_name}`);
};

export const createNewGroup = (associated_client: number | string, group_name: string): Promise<string> => {
  return httpApi.post(`/create/new/group/${associated_client}/${group_name}`);
};

export const getUsersForGroup = (client_id: number): Promise<string> => {
  return httpApi.get(`/users/client/${client_id}`);
};

export const updateGroup = (
  group_id: number,
  group_name: string,
  users_for_groups: string,
  new_group: string,
): Promise<string> => {
  return httpApi.post(`/update/groups/${group_id}/${group_name}/${users_for_groups}/${new_group}`);
};

export const deleteGroup = (group_id: number): Promise<string> => {
  return httpApi.post(`/remove/group/${group_id}`);
};

export const getGroupsPerClient = (cliente: number): Promise<{ data: AllAssociatedGroupsApiResponse }> => {
  return httpApi.get(`/get/groups/per/client/${cliente}`);
};

export const checkEmail = (email: string): Promise<string> => {
  return httpApi.get(`/users/check/email/${email}`);
};

export const checkEmailAsync = async (email: string): Promise<string> => {
  const { data } = await httpApi.get(`/users/check/email/${email}`);
  return data;
};

export const createNewUser = (data: any): Promise<any> => {
  const formData = new FormData();
  formData.append('data', JSON.stringify(data));

  return httpApi.post('/users/create', formData);
};

export const deleteUser = (username: string): Promise<string> => {
  return httpApi.post(`/users/delete/${username}`);
};

export const getManagersList = (cliente: number): Promise<string> => {
  return httpApi.get(`/get/managers/${cliente}`);
};

export const createNewManagerAPI = (data: any): Promise<string> => {
  const formData = new FormData();
  formData.append('manager_information', JSON.stringify(data));

  return httpApi.post('/create/new/manager', formData);
};

export const deleteManager = (manager_id: number): Promise<string> => {
  return httpApi.post(`/delete/manager/${manager_id}`);
};

export const editUser = async (bodyData: any): Promise<string> => {
  const formData = new FormData();
  formData.append('user_information', JSON.stringify(bodyData));
  const { data } = await httpApi.post(`/users/update`, formData);

  return data;
};

export const editManager = (data: any): Promise<string> => {
  const formData = new FormData();

  formData.append('manager_information', JSON.stringify(data));

  return httpApi.post(`/update/manager`, formData);
};

export const downloadCertificate = (username: string, language: string): any => {
  return httpApi.get(`/download/certificate/${username}/${language}`).then((response) => {
    const file = new Blob([response.data], { type: 'application/text' });
    const fileUrl = URL.createObjectURL(file);
    const a = document.createElement('a');
    a.href = fileUrl;
    a.download = 'certificate.rcert';
    a.click();
  });
};


export const downloadMyCertificate = (language: string): any => {
  return httpApi.get(`/download/my-certificate/${language}`).then((response) => {
    const file = new Blob([response.data], { type: 'application/text' });
    const fileUrl = URL.createObjectURL(file);
    const a = document.createElement('a');
    a.href = fileUrl;
    a.download = 'certificate.rcert';
    a.click();
  });
};