import { FormTitleWrapper } from '@app/components/common/forms/components/FormTitleWrapper/FormTitleWrapper';
import { Trans, useTranslation } from 'react-i18next';
import { transComponents } from './HelpContent';

const HelpSampleReport = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'help.sampleReport' });

  return (
    <>
      <FormTitleWrapper>
        <h3 id="sampleReport">
          <Trans t={t} i18nKey="title" components={transComponents} />
        </h3>
      </FormTitleWrapper>
      <p>
        <Trans t={t} i18nKey="p1" components={transComponents} />
      </p>
      <h4 id="sampleReport-image1">IMAGE</h4>
    </>
  );
};

export default HelpSampleReport;
