import styled from 'styled-components';
import { Card as AntCard } from 'antd';
import { FONT_SIZE, FONT_WEIGHT, media } from '@app/styles/themes/constants';
import { normalizeProp } from '@app/utils/utils';

interface CardInternalProps {
  $padding: string | number | [number, number];
  $autoHeight: boolean;
}

export const CardInvisible = styled(AntCard)<CardInternalProps>`
  display: ${(props) => props.$displayCard && normalizeProp(props.$displayCard)};
  flex-direction: column;

  ${(props) => props.$autoHeight && 'height: 100%'};

  background: inherit;
  //border: 1px solid var(--border-card-color);
  border: ${(props) => (props.bordered ? '1px solid var(--border-card-color)' : null)};
  //border: 1px solid transparent;
  border-radius: ${(props) => props.$borderRadius || '7px'};

  .ant-card-head {
    border-radius: ${(props) => props.$borderRadius || '7px'} ${(props) => props.$borderRadius || '7px'} 0px 0px;
    border-color: transparent;

    background: transparent;
    font-weight: ${FONT_WEIGHT.bold};
    text-align: left;
    color: var(--title-ravenloop-color);

    padding: ${(props) => normalizeProp(props.$padding) || '0 1vh'};

    .ant-card-head-title {
      white-space: unset;
      overflow: unset;
      padding-bottom: 0;
      font-size: ${(props) => props.$fontSizeTitle || FONT_SIZE.xs};
      text-transform: uppercase;
    }

    @media only screen and (${media.xl}) {
      font-size: ${FONT_SIZE.lg};

      .ant-card-head-title {
        font-size: ${(props) => props.$fontSizeTitle || FONT_SIZE.xs};
        padding-bottom: 0.25rem;
      }
    }
  }

  .ant-card-body {
    box-shadow: none;
    border-radius: 0px 0px ${(props) => props.$borderRadius || '7px'} ${(props) => props.$borderRadius || '7px'};
    display: ${(props) => props.$display && normalizeProp(props.$display)};
    vertical-align: middle;
    flex-grow: 1;
    background-color: transparent;
    padding: ${(props) => props.$padding && normalizeProp(props.$padding)};
    font-size: ${(props) => props.$fontSizeText || FONT_SIZE.xxs};
    justify-content: ${(props) => props.$justifyContent && normalizeProp(props.$justifyContent)};
    overflow: hidden auto;
  }

  .ant-card-bordered {
    border-color: var(--border-card-color);
  }
`;

export const FullScreenButton = styled.div`
  cursor: pointer;

  & > .ant-btn {
    height: auto;
    padding: 0.5vh 0;
    margin: 0;
  }

  & > .ant-btn > span[role='img'],
  .ant-badge {
    font-size: 0.8rem;

    @media only screen and (${media.md}) {
      font-size: 1rem;
    }
  }

  & .ant-badge {
    display: inline-block;
  }
`;
