import React, { useContext } from 'react';
import { FONT_SIZE_VH } from '@app/styles/themes/constants';
import { Breadcrumb, Menu } from 'antd';
import { Link } from 'react-router-dom';
import { CardInvisible } from '@app/components/common/CardInvisible/CardInvisible';
import { useResponsive } from '@app/hooks/useResponsive';
import { EllipsisOutlined, MenuFoldOutlined } from '@ant-design/icons';
import { useAppSelector } from '@app/hooks/reduxHooks';
import styled from 'styled-components';
import { HeaderBreadcrumb as BreadcrumbType } from '@app/hooks/HeaderContext';

const MenuHeader = styled(Menu)`
  border: 0;
  box-shadow: none;
  background: transparent;
  font-size: ${FONT_SIZE_VH.xl};
  padding-top: 1vh;

  .anticon {
    font-size: ${FONT_SIZE_VH.xl};
  }

  .ant-menu-item,
  .ant-menu-submenu {
    padding-left: 0 !important;
    padding-right: 1vw !important;
  }

  .ant-menu-item-selected::after,
  .ant-menu-submenu:hover::after,
  .ant-menu-item:hover::after {
    border-bottom: 2px solid var(--ant-primary-color) !important;
    right: 1vw;
    left: 0;
    //border-bottom: 0 !important;
  }
`;

export const MenuHeaderSeparator = styled.div`
  font-size: ${FONT_SIZE_VH.xl};
  padding-top: 1vh;
  padding-right: 1vh;
  padding-left: 1vh;
`;

interface Props {
  items: BreadcrumbType[];
}

export const HeaderBreadcrumb = ({ items }: Props) => {
  const { mobileOnly } = useResponsive();
  const theme = useAppSelector((state) => state.theme.theme);

  if (mobileOnly) {
    return (
      <MenuHeader
        theme={theme}
        mode="horizontal"
        overflowedIndicator={<MenuFoldOutlined style={{ fontSize: '3vh' }} />}
        defaultSelectedKeys={items?.[items.length - 1]?.key}
      >
        {items.map((item) => {
          return (
            <Menu.Item key={item.key} menu={item.menu}>
              <Link to={item.href}>{item.label}</Link>
            </Menu.Item>
          );
        })}
      </MenuHeader>
    );
  } else {
    return (
      <Breadcrumb style={{ fontSize: FONT_SIZE_VH.xl, paddingTop: '1vh' }}>
        {items.map((item) => (
          <Breadcrumb.Item key={item.key} menu={item.menu}>
            <Link to={item.href}>{item.label}</Link>
          </Breadcrumb.Item>
        ))}
      </Breadcrumb>
    );
  }
};
