import { FormTitleWrapper } from '@app/components/common/forms/components/FormTitleWrapper/FormTitleWrapper';
import { Trans, useTranslation } from 'react-i18next';
import { transComponents } from '../HelpContent';

const HelpManagementGroups = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'help.management.accessManagement.groups' });

  return (
    <>
      <FormTitleWrapper>
        <h5 id="groups">
          <Trans t={t} i18nKey="title" components={transComponents} />
        </h5>
      </FormTitleWrapper>
      <p>
        <Trans t={t} i18nKey="p1" components={transComponents} />
      </p>
      <h6 id="groups-image1">IMAGE</h6>
      <p>
        <Trans t={t} i18nKey="p2" components={transComponents} />
      </p>
      <h6 id="groups-image2">IMAGE</h6>
      <p>
        <Trans t={t} i18nKey="p3" components={transComponents} />
      </p>
      <h6 id="groups-image3">IMAGE</h6>
      <p>
        <Trans t={t} i18nKey="p4" components={transComponents} />
      </p>
      <h6 id="groups-image4">IMAGE</h6>
      <p>
        <Trans t={t} i18nKey="p5" components={transComponents} />
      </p>
    </>
  );
};

export default HelpManagementGroups;
