import React, { useState } from 'react';
import * as S from './HelpIcon.styles';
import { Modal, Tooltip } from 'antd';
import parse from 'html-react-parser';

interface Props {
  message: string | React.ReactNode;
  title: string | React.ReactNode;
  type: string;
}

export const HelpIcon: React.FC<Props> = ({ message, title, type, ...props }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  if (type == 'modal') {
    return (
      <>
        <S.HelpIcon {...props} onClick={showModal} />
        <Modal title={title} open={isModalOpen} onOk={closeModal} onCancel={closeModal}>
          {typeof message === 'string' ? <p>{parse(message)}</p> : message}
        </Modal>
      </>
    );
  } else {
    return (
      <Tooltip title={typeof message === 'string' ? <p>{parse(message)}</p> : message} destroyTooltipOnHide={true}>
        <S.HelpIcon {...props} />
      </Tooltip>
    );
  }
};
