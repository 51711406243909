import React, { useContext } from 'react';
import { Col, Row } from 'antd';
import { NotificationsDropdown } from '../components/notificationsDropdown/NotificationsDropdown';
import { ProfileDropdown } from '../components/profileDropdown/ProfileDropdown/ProfileDropdown';
import { HeaderSearch } from '../components/HeaderSearch/HeaderSearch';
import * as S from '../Header.styles';
import HeaderContext from '@app/hooks/HeaderContext';
import { HeaderBreadcrumb } from '@app/components/header/HeaderBreadcrumb';
import { ClientDropdown } from '../components/ClientDropdown/ClientDropdown';

interface MobileHeaderProps {
  toggleSider: () => void;
  isSiderOpened: boolean;
}

export const MobileHeader: React.FC<MobileHeaderProps> = ({ toggleSider, isSiderOpened }) => {
  // TODO: Añadir navegación para el análisis de muestra
  // const { headerBreadcrumb } = useContext(HeaderContext);
  // const { headerNavigation } = useContext(HeaderContext);

  return (
    <Row wrap={false}>
      {/* {headerBreadcrumb && (
        <Col span={3} style={{ zIndex: 999 }}>
          <HeaderBreadcrumb items={headerBreadcrumb} />
        </Col>
      )}
      {headerNavigation && (
        <Col span={3} style={{ zIndex: 999 }}>
          {headerNavigation}
        </Col>
      )} */}
      <Col flex={'auto'}>
        <Row justify="space-between" align="middle">
          <Col>
            <ProfileDropdown />
          </Col>
          <Col>
            <Row align="middle">
              <Col>
                <NotificationsDropdown />
              </Col>
              <Col>
                <HeaderSearch />
              </Col>
              {/* <Col>
                <SettingsDropdown />
              </Col> */}
              <Col>
                <ClientDropdown isSiderCollapsed={isSiderOpened} />
              </Col>
            </Row>
          </Col>
          <S.BurgerCol>
            <S.MobileBurger onClick={toggleSider} isCross={isSiderOpened} />
          </S.BurgerCol>
        </Row>
      </Col>
    </Row>
  );
};
