import React from 'react';
import * as S from './MainSider/MainSider.styles';
import { getCurrentPlatform } from '@app/services/localStorage.service';
import { getLogoPlatformByName, getLogoPlatformShortByName } from '@app/utils/platformsLogo';
import { RightOutlined } from '@ant-design/icons';
import { useResponsive } from '@app/hooks/useResponsive';

export const SiderLogoPlatform: React.FC<SiderLogoProps> = ({ currentPlatform, isSiderCollapsed, toggleSider }) => {
  // const platform = currentPlatform ? currentPlatform : getCurrentPlatform();
  const platform = 'ravensight';
  const { mobileOnly } = useResponsive();
  const logoCurrentPlatform = isSiderCollapsed ? getLogoPlatformShortByName(platform) : getLogoPlatformByName(platform);

  return (
    <S.SiderLogoDiv>
      <S.SiderLogoLink to="#">
        <img src={logoCurrentPlatform} alt={platform} width="100%" />
      </S.SiderLogoLink>
      {!mobileOnly && (
        <S.CollapseButton
          shape="circle"
          size="small"
          $isCollapsed={isSiderCollapsed}
          icon={<RightOutlined rotate={isSiderCollapsed ? 0 : 180} />}
          onClick={toggleSider}
        />
      )}
    </S.SiderLogoDiv>
  );
};
