import React from 'react';
import { useTranslation } from 'react-i18next';
import * as S from './Error.styles';
import { Link } from 'react-router-dom';

interface ErrorProps {
  msg: string;
  title?: string;
  img?: string;
  link?: {
    route?: string;
    message?: string;
  };
}

export const Error: React.FC<ErrorProps> = ({ img, msg, link, title }) => {
  const { t } = useTranslation();

  return (
    <S.Wrapper>
      {!!img ? <S.Image preview={false} src={img} /> : null}
      <S.ContentWrapper>
        {!!title ? <S.Title>{t('common.oops')}</S.Title> : null}
        <S.Text>{msg}</S.Text>
        {/*TODO make common component */}
        <Link to={!!link?.route ? link.route : '/'} className="ant-btn ant-btn-link">
          {t(!!link?.message ? link.message : 'error404.comeBack')}
        </Link>
      </S.ContentWrapper>
    </S.Wrapper>
  );
};
