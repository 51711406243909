import { createSlice } from '@reduxjs/toolkit';
import { UserModel } from '@app/domain/UserModel';
import { persistUser, readUser } from '@app/services/localStorage.service';
import { RootState } from '../store';

export interface UserState {
  user: UserModel | null;
}

const initialState: UserState = {
  user: readUser(),
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser: (state, action) => {
      state.user = action.payload;
      persistUser(action.payload);
    },
    setUserLng: (state, action) => {
      const newUser = { ...state.user, lng: action.payload } as UserModel;
      state.user = newUser;
      persistUser(newUser);
    },
  },
  extraReducers: () => {},
});

export const { setUser, setUserLng } = userSlice.actions;

export const selectUserData = (state: RootState) => state.user.user;
export const selectUserClients = (state: RootState) => state.user.user?.all_clients;
export const selectCurrentClient = (state: RootState) => state.user.user?.cliente_id;
export const selectAvailableModules = (state: RootState) => state.user.user?.available_modules ?? [];
export const selectUserRole = (state: RootState) => state.user.user?.user_type;
export const selectClientRole = (state: RootState) => state.user.user?.cliente_usuario?.ROLE;
export const selectClientOriginal = (state: RootState) => state.user.user?.cliente_usuario?.ORIGINAL;
export const selectUserIdAssoc = (state: RootState) => state.user.user?.user_id_assoc;
export const selectUserRestrictions = (state: RootState) => state.user.user?.client_settings.restrictions;
export const selectUserLanguage = (state: RootState) => state.user.user?.lng;
export const selectUserAnalystPrivilege = (state: RootState) => state.user.user?.useranalystprivilege;

export default userSlice.reducer;
