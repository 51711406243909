import { useAppSelector } from '@app/hooks/reduxHooks';
import { themeObject } from '@app/styles/themes/themeVariables';
import { ITheme } from '@app/styles/themes/types';
import {
  detectFormatColor,
  getNumbersColorsFromRgb,
  getRgbaColorFromHex,
  hex3ToHex6,
  hslaToRgbaWithNewTransparence,
  hslToHex,
} from '@app/utils/utils';

export const useRgbaColor = () => {
  const theme = useAppSelector((state) => state.theme.theme);

  const getThemeVariableColor = (color: string): string => {
    let tagColor;
    if (themeObject[theme][`${color}` as keyof ITheme]) {
      tagColor = themeObject[theme][`${color}` as keyof ITheme].toString();
    } else {
      tagColor = color;
    }

    return tagColor;
  };

  const getRgbaColor = (color: string, trans: number | string): string => {
    let tagColor = getThemeVariableColor(color);
    const formatColor = detectFormatColor(tagColor);
    if (formatColor === 'hex') {
      if (color.length === 4) {
        tagColor = hex3ToHex6(tagColor);
      }
      if (theme === 'dark' && tagColor === '#000000') {
        tagColor = '#ffffff';
      }
      if (theme === 'light' && tagColor === '#ffffff') {
        tagColor = '#000000';
      }
      return getRgbaColorFromHex(tagColor, trans);
    }
    if (formatColor === 'rgb') {
      const rgbNumbers = getNumbersColorsFromRgb(tagColor);
      if (rgbNumbers && rgbNumbers.length === 3) {
        return `rgba(${rgbNumbers[0]},${rgbNumbers[1]},${rgbNumbers[2]},${trans})`;
      }
    }
    if (formatColor === 'rgba') {
      return tagColor;
    }
    if (formatColor === 'hsl') {
      let hexColor = hslToHex(tagColor);
      if (hexColor.length === 4) {
        hexColor = hex3ToHex6(hexColor);
      }
      if (theme === 'dark' && hexColor === '#000000') {
        hexColor = '#ffffff';
      }
      if (theme === 'light' && hexColor === '#ffffff') {
        hexColor = '#000000';
      }
      return getRgbaColorFromHex(hexColor, trans);
    }
    if (formatColor === 'hsla') {
      return hslaToRgbaWithNewTransparence(tagColor, trans);
    }
    return `rgba(0,0,0,${trans})`;
  };

  return { getRgbaColor };
};
