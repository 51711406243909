import styled from 'styled-components';

const LogoStyle = styled.img`
  display: inline-block;
  height: 5rem;
  margin-top: -1rem;
`;

const LogosComponent = () => {
  return <LogoStyle src="/logos/ravenloop.svg" alt="Logos" />;
};

export default LogosComponent;
