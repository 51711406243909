import { Dates } from '@app/constants/Dates';
import { LanguageType } from '@app/interfaces/interfaces';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export const useLanguage = (): { language: LanguageType; setLanguage: (locale: LanguageType) => Promise<void> } => {
  const { i18n } = useTranslation();

  const handleChangeLanguage = useCallback(
    async (locale: LanguageType) => {
      if (locale) {
        Dates.setLocale(locale);
        await i18n.changeLanguage(locale);
      }
    },
    [i18n],
  );

  return useMemo(
    () => ({ language: i18n.language as LanguageType, setLanguage: handleChangeLanguage }),
    [handleChangeLanguage, i18n.language],
  );
};
