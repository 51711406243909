import styled from 'styled-components';
import { CloseOutlined } from '@ant-design/icons';
import { TagSpan } from 'react-trello/dist/styles/Base';
import { FONT_SIZE_VH as FONT_SIZE } from '@app/styles/themes/constants';

export const RemoveTagWrapper = styled.span`
  padding-left: 0.3125rem;
  display: flex;
  align-items: center;
  padding-top: 1px;
`;

export const RemoveTagIcon = styled(CloseOutlined)`
  color: #ffffff;
  font-size: ${FONT_SIZE.xxs};
  cursor: pointer;
`;

interface TagInternalProps {
  $radius: string;
}

export const TagWrapper = styled(TagSpan)<TagInternalProps>`
  border-radius: ${(props) => (props.$radius == 'round' ? '1rem' : props.$radius == 'square' ? '0' : '0.5rem')};
  font-family: 'IBM Plex Sans', sans-serif;
  box-sizing: border-box;
  text-decoration: none;
  outline: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.3125rem 0.625rem;
  margin: 0;
  font-size: ${FONT_SIZE.md};
  overflow: hidden;
  text-overflow: ellipsis;
  transition: background-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
`;
