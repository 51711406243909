import { UserRoles } from '@app/domain/rolesModel';
import { withAccess } from '@app/hocs/withAccess';
import { lazy } from 'react';

const RavenwireDashboardPage = lazy(() => import('@app/pages/DashboardPages/Ravenwire/RavenwireDashboardPage'));
const EventsListPage = lazy(() => import('@app/pages/Events/EventsListPage'));
const EventElementPage = lazy(() => import('@app/pages/Events/EventElementPage'));
const AnomaliesListPage = lazy(() => import('@app/pages/Anomalies/AnomaliesListPage'));

const RavenwireDashboard = withAccess(RavenwireDashboardPage);
const EventsList = withAccess(EventsListPage);
const AnomaliesList = withAccess(AnomaliesListPage);
const EventElement = withAccess(EventElementPage);

const rwireRouter = [
  {
    path: '',
    element: <RavenwireDashboard />,
  },
  {
    path: 'events',
    element: <EventsList />,
  },
  {
    path: 'event/:eventId', //TODO: Meter dentro de events
    element: <EventElement />,
  },
  {
    path: 'anomalies',
    element: <AnomaliesList role={UserRoles.SUPERADMIN} />,
  },
];

export default rwireRouter;
