import { FormTitleWrapper } from '@app/components/common/forms/components/FormTitleWrapper/FormTitleWrapper';
import { Trans, useTranslation } from 'react-i18next';
import { transComponents } from './HelpContent';

const HelpRequirements = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'help.requirements' });

  return (
    <>
      <FormTitleWrapper>
        <h2 id="requirements">
          <Trans t={t} i18nKey="title" components={transComponents} />
        </h2>
      </FormTitleWrapper>
      <p>
        <Trans t={t} i18nKey="p1" components={transComponents} />
      </p>
      <ul>
        <li>
          <Trans t={t} i18nKey="ul.li1" components={transComponents} />
          <ul>
            <li>
              <Trans t={t} i18nKey="ul.li1-1" components={transComponents} />
            </li>
            <li>
              <Trans t={t} i18nKey="ul.li1-2" components={transComponents} />
            </li>
            <li>
              <Trans t={t} i18nKey="ul.li1-3" components={transComponents} />
            </li>
          </ul>
        </li>
        <li>
          <Trans t={t} i18nKey="ul.li2" components={transComponents} />
        </li>
        <li>
          <Trans t={t} i18nKey="ul.li3" components={transComponents} />
        </li>
      </ul>
    </>
  );
};

export default HelpRequirements;
