import { Trans, useTranslation } from 'react-i18next';
import { transComponents } from '../HelpContent';

const HelpTechnicalReportScreenshots = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'help.technicalReportScreenshots' });

  return (
    <>
      <h5 id="technicalReportScreenshots">
        <Trans t={t} i18nKey="title" components={transComponents} />
      </h5>
      <p>
        <Trans t={t} i18nKey="p1" components={transComponents} />
      </p>
      <h6 id="technicalReportScreenshots-image1">IMAGE</h6>
    </>
  );
};

export default HelpTechnicalReportScreenshots;
