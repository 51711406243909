import { AnalysisRtraceDataInterface, RoleType,MailsRpostDataInterface } from '@app/interfaces/interfaces';
import { languagesByShortcode } from '@app/constants/languages';
import { httpApi } from '@app/api/http.api';

export const checkAccessApi = (role: RoleType = 'user'): Promise<boolean> => {
  return httpApi.get('/check-access/' + role);
};

export const checkAccessRpostApi = (mailId: number): Promise<boolean> => {
  return httpApi.get('/check-rpost-access/');
};
