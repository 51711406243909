import { Col, Image, Row } from 'antd';
import {
  FileImageOutlined,
  FileProtectOutlined,
  FileTextOutlined,
  FileUnknownOutlined,
  FileZipOutlined,
} from '@ant-design/icons';
import { NotificationType } from '@app/components/common/Notification/Notification';
import { AvailableModules, CurrencyType, GeolocationDataIoc, ThemeType } from '@app/interfaces/interfaces';
import { Priority } from '@app//constants/enums/priorities';
import { ReactComponent as ETHIcon } from '@app/assets/icons/eth.svg';
import { ReactComponent as BTCIcon } from '@app/assets/icons/btc.svg';
import { EmptyDefaultTag } from '@app/components/common/TagsRavenloop/EmptyDefaultTag';
import { Tag } from '@app/components/common/TagsRavenloop/Tag';
import React from 'react';
import { TagNeon } from '@app/components/common/TagNeon/TagNeon';
import { EmptyDefaultTagNeon } from '@app/components/common/TagNeon/EmptyDefaultTagNeon';
import { getCountryValueTitleIocWithFlag } from '@app/utils/countriesNames';
import { TFunction } from 'i18next';
import { themeObject } from '@app/styles/themes/themeVariables';
import { PlatformsLogo } from '@app/components/PlatformsLogo/PlatformsLogo';
import { BASE_COLORS, ICON_WIDTH_VH_TABLE, IMAGE_WIDTH_VH_TABLE } from '@app/styles/themes/constants';
import { iconsIOCsByType } from '@app/constants/iocs/iconsIOCs';
import { Tooltip } from 'antd/lib';
import { CardInvisible } from '@app/components/common/CardInvisible/CardInvisible';
import { hexToRGB } from './themeUtils';
import {
  FileFilled,
  MailFilled,
  FileZipFilled,
  DribbbleCircleFilled,
  VideoCameraFilled,
  SoundFilled,
  TwitterCircleFilled,
} from '@ant-design/icons';
import { BsAndroid } from 'react-icons/bs';
import { IocsData } from '@app/api/rsight/IOCS/adapters/basicDataIOC.adapter';
import { LinearGradientObject } from 'echarts';

export const isEmailValid = (email: string): boolean => {
  const pattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  return pattern.test(email);
};
export const isObjEmpty = (obj?: Record<any, unknown>): boolean => {
  if (!obj || (typeof obj === 'object' && !Array.isArray(obj) && obj !== null)) return true;
  return Object.keys(obj).length === 0;
};

export const getFileIconByExtension = (extension: string): React.ReactNode => {
  if (extension) {
    extension = extension.toString().toLowerCase();
    if (['.png', 'png', '.jpg', 'jpg', 'image', '.image'].includes(extension)) {
      return FileImageOutlined;
    } else if (['.text', 'text'].includes(extension)) {
      return FileTextOutlined;
    } else if (['.zip', 'zip'].includes(extension)) {
      return FileZipOutlined;
    } else if (['.crt', 'certificate'].includes(extension)) {
      return FileProtectOutlined;
    }
  }
  return FileUnknownOutlined;
};

export const getPlatformByPathname = (pathname: string): string | null => {
  let result = null;

  if (pathname.startsWith('/rwire')) {
    result = 'ravenwire';
  } else if (pathname.startsWith('/rtrace')) {
    result = 'raventrace';
  } else if (pathname.startsWith('/rsight')) {
    result = 'ravensight';
  } else if (pathname.startsWith('/rscout')) {
    result = 'ravenscout';
  } else if (pathname.startsWith('/rpost')) {
    result = 'ravenpost';
  } else if (pathname.startsWith('/rseer')) {
    result = 'ravenseer';
  }

  return result;
};

export const getPathnameByPlatform = (modules?: AvailableModules[]): string | null => {
  if (!modules) {
    return null;
  }
  if (modules.includes(AvailableModules.RAVENSIGHT)) {
    return '/rsight/network-statistics';
  }
  if (modules.includes(AvailableModules.RAVENWIRE)) {
    return '/rwire';
  }
  if (modules.includes(AvailableModules.RAVENSCOUT)) {
    return '/rscout';
  }
  if (modules.includes(AvailableModules.RAVENPOST)) {
    return '/rpost';
  }

  return null;
};

export const convertDateTimeStringtoLocaleDateString = (datetime: string): string => {
  if (datetime) {
    return new Date(datetime).toLocaleDateString();
  }
  return '';
};

export const convertTimestampMilisecondstoLocaleDateTimeString = (timestamp?: number): string => {
  if (timestamp) {
    let date = new Date(timestamp);
    return date.toLocaleDateString() + ' ' + date.toLocaleTimeString();
  }
  return '';
};

export const convertTimestampSecondstoLocaleDateTimeString = (timestamp?: number): string => {
  if (timestamp) {
    let date = new Date(timestamp * 1000);
    return date.toLocaleDateString() + ' ' + date.toLocaleTimeString();
  }
  return '';
};

export const convertDateTimeStringtoLocaleDateTimeString = (datetime?: string): string => {
  if (datetime) {
    let date = new Date(datetime);
    return date.toLocaleDateString() + ' ' + date.toLocaleTimeString();
  }
  return '';
};

export const convertDateTimeSecondsStringtoLocaleDateTimeString = (datetime: number): string => {
  if (datetime) {
    let date = new Date(datetime * 1000); // Convert to milliseconds
    return date.toLocaleDateString() + ' ' + date.toLocaleTimeString();
  }
  return '';
};

export const getNowDateTimeStringtoLocaleDateTimeString = (): string => {
  let date = new Date();
  return date.toLocaleDateString() + ' ' + date.toLocaleTimeString();
};

export const hasIocGeolocationByType = (iocType: string): boolean => {
  if (iocType) {
    let typesWithGeolocation = ['ipv4'];
    return typesWithGeolocation.includes(iocType);
  }
  return false;
};

export const getCountryShortcodeFromGeolocationHistory = (iocGeolocationHistory: GeolocationDataIoc[]): string => {
  let shortCode = null;
  if (iocGeolocationHistory && iocGeolocationHistory.length > 0) {
    let i = 0;
    while (!shortCode && i < iocGeolocationHistory.length) {
      let geolocation = iocGeolocationHistory[i];
      if (geolocation && geolocation.country && geolocation.country != 'None') {
        shortCode = geolocation.country;
      }
      i++;
    }
  }
  return shortCode;
};

export const hasIocHistoryGeolocationByType = (iocType: string): boolean => {
  if (iocType) {
    let typesWithHistoryGeolocation = ['asn', 'lat_long', 'pais', 'ciudad', 'ipv4'];
    return typesWithHistoryGeolocation.includes(iocType);
  }
  return false;
};

export const truncate_text = (text: string, length: number): string => {
  return text.slice(0, length) + (text.length > length ? '...' : '');
};

export const truncateTextFinal = (text: string, length: number): string | null => {
  if (text) {
    return text.slice(0, length);
  } else {
    return null;
  }
};

export const truncateLongText = (text: string): string | null => {
  const maxLength = 500;
  if (text) {
    return text.slice(0, maxLength);
  } else {
    return null;
  }
};

export const showImageIOC = (value: string, preview: boolean): React.ReactNode => {
  return (
    <Image
      src={'data:image/png;base64, ' + value}
      alt={'image'}
      preview={preview}
      style={{
        minWidth: '90%',
        maxWidth: '90%',
        display: 'block',
        marginLeft: 'auto',
        marginRight: 'auto',
      }}
    />
  );
};

export const showValueIOC = (value: string, typeIOC: string): string | React.ReactNode | null => {
  if (typeIOC == 'imagen') {
    return 'image data';
  } else if (typeIOC == 'pais') {
    return getCountryValueTitleIocWithFlag(value);
  }
  return truncateLongText(value);
};

export function isBase64(str: string): boolean {
  try {
    return btoa(atob(str)) == str;
  } catch (err) {
    return false;
  }
}

export function decodeBase64(base64String?: string): string | null {
  if (base64String) {
    try {
      const buffer = Buffer.from(base64String, 'base64');
      const decodedString = buffer.toString('utf-8');
      return decodedString;
    } catch (err) {
      return null;
    }
  }
  return null;
}

export function decodeBase64ToJson(base64String?: string): any | null {
  if (base64String) {
    try {
      const decodedString = decodeBase64(base64String);
      const result = decodedString ? JSON.parse(decodedString) : null;
      return result;
    } catch (err) {
      return null;
    }
  }
  return null;
}

export const truncateIocsText = (text: string, length: number): string | null => {
  if (text) {
    return text.slice(0, length);
  } else {
    return null;
  }
};

export const getTextMeaningScore = (iocScore: number, t: Record<string, unknown>): string | null => {
  let textScore = null;
  iocScore = parseInt(iocScore);
  if (iocScore === 0) {
    textScore = t('ioc.scoreWhitelist');
  } else if (iocScore === 1) {
    textScore = t('ioc.scoreNeutral');
  } else if (iocScore === 2) {
    textScore = t('ioc.scoreMalicious');
  } else if (iocScore === 3) {
    textScore = t('ioc.scoreFeeds');
  } else if (iocScore === 4) {
    textScore = t('ioc.scoreAnalist');
  }
  return textScore;
};

export const getBgColorScore = (iocScore: number): string => {
  let bgColorScore = 'white';
  iocScore = parseInt(iocScore);
  if (iocScore === 0) {
    // bgColorScore = "var(--ioc-score-color-0)"
    bgColorScore = '#77dd77';
  } else if (iocScore === 1) {
    bgColorScore = '#84b6f4';
    // bgColorScore = "var(--ioc-score-color-1)"
  } else if (iocScore === 2) {
    // bgColorScore = "var(--ioc-score-color-2)"
    bgColorScore = '#daae58';
  } else if (iocScore === 3) {
    // bgColorScore = "var(--ioc-score-color-3)"
    bgColorScore = '#ff6961';
  } else if (iocScore === 4) {
    // bgColorScore = "var(--ioc-score-color-4)"
    bgColorScore = '#C7b0f2';
  }
  return bgColorScore;
};

export const getBgColorTlp = (tlp: string): string | null => {
  let bgColorTlp = null;
  if (tlp === 'red') {
    bgColorTlp = '#ef7b7b';
  } else if (tlp === 'ambar') {
    bgColorTlp = '#e2be6a';
  } else if (tlp === 'green') {
    bgColorTlp = '#8aea8f';
  } else if (tlp === 'white') {
    bgColorTlp = '#ffffff';
  }
  return bgColorTlp;
};

export const getBgColorStatus = (status: string): string | null => {
  let bgColorStatus = null;
  if (status === 'Completada') {
    bgColorStatus = 'rgb(0, 166, 226)';
  }
  return bgColorStatus;
};

export const getBgColorPriority = (eventScore: number): string | null => {
  let bgColorScore = null;
  eventScore = parseInt(eventScore);
  if (!eventScore) {
    return null;
  }
  if (eventScore >= 6) {
    bgColorScore = '#2194e7';
  } else if (eventScore >= 5) {
    bgColorScore = '#8aea8f';
  } else if (eventScore >= 4) {
    bgColorScore = '#e2be6a';
  } else if (eventScore >= 3) {
    bgColorScore = '#ef7b7b';
  } else {
    bgColorScore = '#d66bd1';
  }
  return bgColorScore;
};

export const getTranslatedTypeIOC = (type: string, t: TFunction): string => {
  return t('iocType.' + type);
};

export const getTranslatedTypeFile = (type: string, t: Record<string, unknown>): string => {
  return t('fileType.' + type);
};

export const getInstanceDisplayTag = (instance: string): React.ReactNode => {
  let element = (
    <EmptyDefaultTag
      otherStyle={{
        fontWeight: 'bold',
        textOverflow: 'hidden',
        whiteSpace: 'nowrap',
      }}
      title="No Instance"
      width="100%"
    />
  );
  if (instance && instance !== '') {
    element = (
      <Tag
        otherStyle={{ fontWeight: 'bold', textOverflow: 'hidden', whiteSpace: 'nowrap' }}
        title={instance}
        bgColor="var(--tag-ravenloop-background-color)"
        width="100%"
      />
    );
  }
  return element;
};

export const getFileSizeRaventraceInfoByFileType = (
  fileSize: number,
  fileType: string,
  t: Record<string, unknown>,
): string => {
  if (fileType === 'file') {
    return formatSizeUnits(fileSize);
  } else {
    // return fileSize + " " + t("rtrace.chars")
    return '-';
  }
};

export const formatSizeUnits = (bytes: number | string): string => {
  let result;
  if (typeof bytes == 'string') {
    bytes = parseInt(bytes);
  }
  if (bytes >= 1073741824) {
    result = (bytes / 1073741824).toFixed(2) + ' GB';
  } else if (bytes >= 1048576) {
    result = (bytes / 1048576).toFixed(2) + ' MB';
  } else if (bytes >= 1024) {
    result = (bytes / 1024).toFixed(2) + ' KB';
  } else if (bytes > 1) {
    result = bytes + ' bytes';
  } else if (bytes == 1) {
    result = bytes + ' byte';
  } else {
    result = '0 bytes';
  }
  return result;
};

export const stripPrintableText = (value: string): string => {
  if (value === null || value === '') return value;
  else value = value.toString();
  return value.replace(/[^\x20-\x7E]/g, '');
};

export const isPrintableChar = (char: string): string => {
  return !/[\x00-\x08\x0E-\x1F\x80-\xFF]/.test(char);
};

export const getIocOrigenText = (iocHistorical: IocsData['historical']): string => {
  if (iocHistorical?.length > 0) {
    let lastHistorical = iocHistorical[iocHistorical.length - 1];
    if (lastHistorical?.instance) {
      return lastHistorical.instance;
    }
  }
  return '';
};

export const getIocOrigenDisplay = (iocHistorical: string[], width?: string): React.ReactNode => {
  let element = (
    <EmptyDefaultTag
      otherStyle={{
        fontWeight: 'bold',
        textOverflow: 'hidden',
        whiteSpace: 'nowrap',
      }}
      title="No Origen"
      width="100%"
    />
  );
  if (iocHistorical?.length > 0) {
    let lastHistorical = iocHistorical[iocHistorical.length - 1];
    if (lastHistorical.instance) {
      element = (
        <Tag
          otherStyle={{ fontWeight: 'bold', textOverflow: 'hidden', whiteSpace: 'nowrap', marginBottom: '0.3em' }}
          title={lastHistorical.instance}
          bgColor="var(--tag-ravenloop-background-color)"
          width={width ? width : '100%'}
        />
      );
    }
  }
  return element;
};

export const getIocOrigenDisplayNeon = (iocHistorical: string[], width?: string): React.ReactNode => {
  let element = (
    <EmptyDefaultTagNeon
      otherStyle={{
        fontWeight: 'bold',
        textOverflow: 'hidden',
        whiteSpace: 'nowrap',
      }}
      $radius={'square'}
      title="No Origen"
      width={width ? width : '100%'}
      bgColor
    />
  );
  if (iocHistorical?.length > 0) {
    let lastHistorical = iocHistorical[iocHistorical.length - 1];
    if (lastHistorical.instance) {
      element = (
        <TagNeon
          otherStyle={{ fontWeight: 'bold', textOverflow: 'hidden', whiteSpace: 'nowrap' }}
          title={lastHistorical.instance}
          color="#6f76b9"
          bgColor
          $radius={'square'}
          width={width ? width : '100%'}
        />
      );
    }
  }
  return element;
};

export const getTlpTagDisplay = (tlp: string | null): React.ReactNode => {
  let element = <EmptyDefaultTag otherStyle={{ fontWeight: 'bold' }} title="No TLP" width="100%" />;
  let bgColorTlp = getBgColorTlp(tlp);
  let color = 'var(--white)';
  if (bgColorTlp) {
    if (bgColorTlp === '#ffffff') {
      color = 'var(--black)';
    }
    element = <Tag otherStyle={{ fontWeight: 'bold' }} title={tlp} bgColor={bgColorTlp} width="100%" color={color} />;
  }
  return element;
};

export const getTlpTagDisplayNeon = (tlp: string | null): React.ReactNode => {
  let element = <EmptyDefaultTagNeon otherStyle={{ fontWeight: 'bold' }} title="No TLP" width="100%" bgColor />;
  let bgColorTlp = getBgColorTlp(tlp);
  let color = '#ffffff';
  if (bgColorTlp) {
    if (bgColorTlp === '#ffffff') {
      color = '#000000';
    }
    element = <TagNeon otherStyle={{ fontWeight: 'bold' }} title={tlp} color={bgColorTlp} bgColor width="100%" />;
  }
  return element;
};

export const getStatusTagDisplay = (status: string | null): React.ReactNode => {
  let element = <EmptyDefaultTag otherStyle={{ fontWeight: 'bold' }} title="No status" width="100%" />;
  let bgColorTlp = getBgColorStatus(status);
  let color = 'var(--white)';
  if (bgColorTlp) {
    if (bgColorTlp === '#ffffff') {
      color = 'var(--black)';
    }
    element = <Tag otherStyle={{ fontWeight: 'bold' }} title={status} bgColor={bgColorTlp} width="50%" color={color} />;
  }
  return element;
};

export const getIocScoreDisplay = (iocScore: number): React.ReactNode => {
  let element = <EmptyDefaultTag otherStyle={{ fontWeight: 'bold' }} title="No Score" width="100%" />;
  let bgColorScore = getBgColorScore(iocScore);
  if (bgColorScore) {
    element = <Tag otherStyle={{ fontWeight: 'bold' }} title={iocScore} bgColor={bgColorScore} width="100%" />;
  }
  return element;
};

export const getIocScoreDisplayNeon = (iocScore: number): React.ReactNode => {
  let element = <EmptyDefaultTagNeon otherStyle={{ fontWeight: 'bold' }} title="No Score" width="100%" bgColor />;
  let bgColorScore = getBgColorScore(iocScore);
  if (bgColorScore) {
    element = (
      <TagNeon otherStyle={{ fontWeight: 'bold' }} title={iocScore} color={bgColorScore} bgColor width="100%" />
    );
  }
  return element;
};

export const getEventsPriorityDisplay = (eventScore: number): React.ReactNode => {
  let element = <EmptyDefaultTag otherStyle={{ fontWeight: 'bold' }} title="No Priority" width="100%" />;
  let bgColorScore = getBgColorPriority(eventScore);
  if (bgColorScore) {
    element = <Tag otherStyle={{ fontWeight: 'bold' }} title={eventScore} bgColor={bgColorScore} width="100%" />;
  }
  return element;
};

export const getEventsPriorityDisplayNeon = (eventScore: number): React.ReactNode => {
  let element = <EmptyDefaultTagNeon otherStyle={{ fontWeight: 'bold' }} title="No Priority" width="100%" bgColor />;
  let bgColorScore = getBgColorPriority(eventScore);
  if (bgColorScore) {
    element = (
      <TagNeon otherStyle={{ fontWeight: 'bold' }} title={eventScore} color={bgColorScore} bgColor width="100%" />
    );
  }
  return element;
};

export const getSrcImagePlatform = (platform: string): string => {
  return '/logos_platform/logo_' + platform.toLowerCase() + '_long.png';
};

// export const onImageLoadFailure = (idImg: string, value) => {
//     let img = document.getElementById(idImg);
//     if (img) {
//         const newItem = document.createElement('p');
//         if (value == undefined || value == '') {
//             newItem.innerHTML = 'undefined';
//         } else {
//             newItem.innerHTML = value;
//         }
//         img.parentNode.replaceChild(newItem, img);
//     }
// }

export const convert_bytes = (bytes: number, length: number): string => {
  let value = bytes / Math.pow(1024, 2);
  let value_string = value.toString();
  return value_string.slice(0, length) + (value_string.length > length ? '...' : '');
};

export const convert_number = (value: number, length: number): string => {
  let value_string = value.toString();
  return value_string.slice(0, length) + (value_string.length > length ? '...' : '');
};

export const getColorCategoryRavenwire = (category: string): string => {
  let backgroundColor = '#E8E8E8';
  if (category === 'Notice' || category === 'Notification') {
    backgroundColor = '#8aea8f';
  } else if (category === 'Information') {
    backgroundColor = '#2194e7';
  } else if (category === 'Warning') {
    backgroundColor = '#e2be6a';
  } else if (category === 'Error') {
    backgroundColor = '#ef7b7b';
  } else if (category === 'Critical' || category === 'Alert' || category === 'Emergency') {
    backgroundColor = '#d66bd1';
  } else if (category === 'Traffic') {
    backgroundColor = '#2194E7';
  } else if (category === 'Threat') {
    backgroundColor = '#DCDE35';
  } else if (category === 'Correlation') {
    backgroundColor = '#8AEA8F';
  } else if (category === 'System') {
    backgroundColor = '#DE5435';
  }
  return backgroundColor;
};

export const camelize = (string: string): string => {
  return string
    .split(' ')
    .map((word, index) => (index === 0 ? word.toLowerCase() : word[0].toUpperCase() + word.slice(1)))
    .join('');
};

export const getCurrencyPrice = (
  price: number | string,
  currency: CurrencyType,
  isIcon = true,
): string | React.ReactNode => {
  switch (currency) {
    case 'USD': {
      return isIcon ? `$${price}` : `${price} USD`;
    }

    case 'BTC': {
      return isIcon ? (
        <Row align="middle" gutter={[8, 8]}>
          <Col style={{ display: 'flex' }}>
            <BTCIcon />
          </Col>

          <Col>{price}</Col>
        </Row>
      ) : (
        `${price} BTC`
      );
    }

    case 'ETH': {
      return isIcon ? (
        <Row align="middle" gutter={[8, 8]}>
          <Col style={{ display: 'flex' }}>
            <ETHIcon />
          </Col>

          <Col>{price}</Col>
        </Row>
      ) : (
        `${price} ETH`
      );
    }

    default: {
      return isIcon ? `$${price}` : `${price} USD`;
    }
  }
};

type MarkArea = {
  xAxis: number;
};

export const getMarkAreaData = (data: string[] | number[]): MarkArea[][] =>
  data.map((el, index) => [
    {
      xAxis: 2 * index,
    },
    {
      xAxis: 2 * index + 1,
    },
  ]);

export const capitalize = (word: string): string => `${word[0].toUpperCase()}${word.slice(1)}`;

export const hex3ToHex6 = (hex: string): string => {
  if (hex.length == 4) {
    let digit = hex;
    digit = digit
      .split('')
      .map((item) => {
        if (item == '#') {
          return item;
        }
        return item + item;
      })
      .join('');
    if (digit[0] != '#') {
      digit = '#' + digit;
    }
    hex = digit;
  }
  return hex;
};

export const getDifference = (value: number, prevValue: number): string | null =>
  prevValue !== 0 ? `${((Math.abs(value - prevValue) / prevValue) * 100).toFixed(0)}%` : '100%';

export const normalizeProp = (prop: string | number | [number, number]): string =>
  typeof prop === 'number' ? `${prop}px` : (Array.isArray(prop) && `${prop[0]}px ${prop[1]}px`) || prop.toString();

export const defineColorByPriority = (priority: Priority): string => {
  switch (priority) {
    case Priority.INFO:
      return 'var(--primary-color)';
    case Priority.LOW:
      return 'var(--success-color)';
    case Priority.MEDIUM:
      return 'var(--warning-color)';
    case Priority.HIGH:
      return 'var(--error-color)';
    default:
      return 'var(--success-color)';
  }
};

export const defineColorBySeverity = (severity: NotificationType | undefined, rgb = false): string => {
  const postfix = rgb ? 'rgb-color' : 'color';
  switch (severity) {
    case 'error':
    case 'warning':
    case 'success':
      return `var(--${severity}-${postfix})`;
    case 'info':
    default:
      return `var(--primary-${postfix})`;
  }
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const mergeBy = (a: any[], b: any[], key: string): any[] =>
  a.filter((elem) => !b.find((subElem) => subElem[key] === elem[key])).concat(b);

export const getSmoothRandom = (factor: number, start: number): number => {
  const halfEnvelope = 1 / factor / 2;
  const max = Math.min(1, start + halfEnvelope);
  const min = Math.max(0, start - halfEnvelope);

  return Math.random() * (max - min) + min;
};

export const hexToHSL = (hex: string): { h: number; s: number; l: number } => {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);

  if (result) {
    let r = parseInt(result[1], 16);
    let g = parseInt(result[2], 16);
    let b = parseInt(result[3], 16);
    (r /= 255), (g /= 255), (b /= 255);
    const max = Math.max(r, g, b),
      min = Math.min(r, g, b);
    let h, s;
    const l = (max + min) / 2;
    if (max == min) {
      h = s = 0; // achromatic
    } else {
      const d = max - min;
      s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
      switch (max) {
        case r:
          h = (g - b) / d + (g < b ? 6 : 0);
          break;
        case g:
          h = (b - r) / d + 2;
          break;
        case b:
          h = (r - g) / d + 4;
          break;
        default:
          h = 0;
      }
      h /= 6;
    }
    return {
      h,
      s,
      l,
    };
  } else {
    throw new Error('Non valid HEX color');
  }
};

export const formatNumberWithCommas = (value: number): string => {
  return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const detectFormatColor = (color: string) => {
  if (color) {
    if (color.startsWith('#')) {
      return 'hex';
    } else if (color.startsWith('rgb(')) {
      return 'rgb';
    } else if (color.startsWith('rgba(')) {
      return 'rgba';
    } else if (color.startsWith('hsl(')) {
      return 'hsl';
    } else if (color.startsWith('hsla(')) {
      return 'hsla';
    }
  }
  return null;
};

export const getRgbaColorFromHex = (hexColor: string, trans: string | number): string => {
  if (hexColor) {
    if (hexColor.length == 4) {
      hexColor = hex3ToHex6(hexColor);
    }
    const rgbColor = hexToRGB(hexColor);
    return 'rgba(' + rgbColor + ', ' + trans + ')';
  }
  return 'rgba(0,0,0, ' + trans + ')';
};

export const getNumbersColorsFromRgb = (rgbColor: string): string[] => {
  return rgbColor
    .substring(4, rgbColor.length - 1)
    .replace(/ /g, '')
    .split(',');
};

export const hslToRgb = (hsl: string): string => {
  let sep = hsl.indexOf(',') > -1 ? ',' : ' ';
  hsl = hsl.substr(4).split(')')[0].split(sep);

  let h = hsl[0],
    s = hsl[1].substr(0, hsl[1].length - 1) / 100,
    l = hsl[2].substr(0, hsl[2].length - 1) / 100;

  let c = (1 - Math.abs(2 * l - 1)) * s,
    x = c * (1 - Math.abs(((h / 60) % 2) - 1)),
    m = l - c / 2,
    r = 0,
    g = 0,
    b = 0;

  if (0 <= h && h < 60) {
    r = c;
    g = x;
    b = 0;
  } else if (60 <= h && h < 120) {
    r = x;
    g = c;
    b = 0;
  } else if (120 <= h && h < 180) {
    r = 0;
    g = c;
    b = x;
  } else if (180 <= h && h < 240) {
    r = 0;
    g = x;
    b = c;
  } else if (240 <= h && h < 300) {
    r = x;
    g = 0;
    b = c;
  } else if (300 <= h && h < 360) {
    r = c;
    g = 0;
    b = x;
  }
  r = Math.round((r + m) * 255);
  g = Math.round((g + m) * 255);
  b = Math.round((b + m) * 255);

  return 'rgb(' + r + ',' + g + ',' + b + ')';
};

export const hslToHex = (hsl: string): string => {
  let sep = hsl.indexOf(',') > -1 ? ',' : ' ';
  hsl = hsl.substr(4).split(')')[0].split(sep);

  let h = hsl[0],
    s = hsl[1].substr(0, hsl[1].length - 1) / 100,
    l = hsl[2].substr(0, hsl[2].length - 1) / 100;

  // Strip label and convert to degrees (if necessary)
  if (h.indexOf('deg') > -1) h = h.substr(0, h.length - 3);
  else if (h.indexOf('rad') > -1) h = Math.round(h.substr(0, h.length - 3) * (180 / Math.PI));
  else if (h.indexOf('turn') > -1) h = Math.round(h.substr(0, h.length - 4) * 360);
  if (h >= 360) h %= 360;

  let c = (1 - Math.abs(2 * l - 1)) * s,
    x = c * (1 - Math.abs(((h / 60) % 2) - 1)),
    m = l - c / 2,
    r = 0,
    g = 0,
    b = 0;

  if (0 <= h && h < 60) {
    r = c;
    g = x;
    b = 0;
  } else if (60 <= h && h < 120) {
    r = x;
    g = c;
    b = 0;
  } else if (120 <= h && h < 180) {
    r = 0;
    g = c;
    b = x;
  } else if (180 <= h && h < 240) {
    r = 0;
    g = x;
    b = c;
  } else if (240 <= h && h < 300) {
    r = x;
    g = 0;
    b = c;
  } else if (300 <= h && h < 360) {
    r = c;
    g = 0;
    b = x;
  }
  // Having obtained RGB, convert channels to hex
  r = Math.round((r + m) * 255).toString(16);
  g = Math.round((g + m) * 255).toString(16);
  b = Math.round((b + m) * 255).toString(16);

  // Prepend 0s, if necessary
  if (r.length == 1) r = '0' + r;
  if (g.length == 1) g = '0' + g;
  if (b.length == 1) b = '0' + b;

  return '#' + r + g + b;
};

export const hslaToRgba = (hsla: string): string => {
  let sep = hsla.indexOf(',') > -1 ? ',' : ' ';
  hsla = hsla.substr(5).split(')')[0].split(sep);

  if (hsla.indexOf('/') > -1) hsla.splice(3, 1);

  let h = hsla[0],
    s = hsla[1].substr(0, hsla[1].length - 1) / 100,
    l = hsla[2].substr(0, hsla[2].length - 1) / 100,
    a = hsla[3];

  if (h.indexOf('deg') > -1) h = h.substr(0, h.length - 3);
  else if (h.indexOf('rad') > -1) h = Math.round(h.substr(0, h.length - 3) * (180 / Math.PI));
  else if (h.indexOf('turn') > -1) h = Math.round(h.substr(0, h.length - 4) * 360);
  if (h >= 360) h %= 360;

  let c = (1 - Math.abs(2 * l - 1)) * s,
    x = c * (1 - Math.abs(((h / 60) % 2) - 1)),
    m = l - c / 2,
    r = 0,
    g = 0,
    b = 0;

  if (0 <= h && h < 60) {
    r = c;
    g = x;
    b = 0;
  } else if (60 <= h && h < 120) {
    r = x;
    g = c;
    b = 0;
  } else if (120 <= h && h < 180) {
    r = 0;
    g = c;
    b = x;
  } else if (180 <= h && h < 240) {
    r = 0;
    g = x;
    b = c;
  } else if (240 <= h && h < 300) {
    r = x;
    g = 0;
    b = c;
  } else if (300 <= h && h < 360) {
    r = c;
    g = 0;
    b = x;
  }
  r = Math.round((r + m) * 255);
  g = Math.round((g + m) * 255);
  b = Math.round((b + m) * 255);

  return 'rgba(' + r + ',' + g + ',' + b + ',' + a + ')';
};

export const hslaToRgbaWithNewTransparence = (hsla: string, transparence: string | number): string => {
  let sep = hsla.indexOf(',') > -1 ? ',' : ' ';
  hsla = hsla.substr(5).split(')')[0].split(sep);

  if (hsla.indexOf('/') > -1) hsla.splice(3, 1);

  let h = hsla[0],
    s = hsla[1].substr(0, hsla[1].length - 1) / 100,
    l = hsla[2].substr(0, hsla[2].length - 1) / 100,
    a = hsla[3];

  if (h.indexOf('deg') > -1) h = h.substr(0, h.length - 3);
  else if (h.indexOf('rad') > -1) h = Math.round(h.substr(0, h.length - 3) * (180 / Math.PI));
  else if (h.indexOf('turn') > -1) h = Math.round(h.substr(0, h.length - 4) * 360);
  if (h >= 360) h %= 360;

  let c = (1 - Math.abs(2 * l - 1)) * s,
    x = c * (1 - Math.abs(((h / 60) % 2) - 1)),
    m = l - c / 2,
    r = 0,
    g = 0,
    b = 0;

  if (0 <= h && h < 60) {
    r = c;
    g = x;
    b = 0;
  } else if (60 <= h && h < 120) {
    r = x;
    g = c;
    b = 0;
  } else if (120 <= h && h < 180) {
    r = 0;
    g = c;
    b = x;
  } else if (180 <= h && h < 240) {
    r = 0;
    g = x;
    b = c;
  } else if (240 <= h && h < 300) {
    r = x;
    g = 0;
    b = c;
  } else if (300 <= h && h < 360) {
    r = c;
    g = 0;
    b = x;
  }
  r = Math.round((r + m) * 255);
  g = Math.round((g + m) * 255);
  b = Math.round((b + m) * 255);

  return 'rgba(' + r + ',' + g + ',' + b + ',' + transparence + ')';
};

export const getColorByRiskText = (riskText: string): string => {
  if (
    riskText.toLowerCase() === 'very high' ||
    riskText.toLowerCase() == 'very_high' ||
    riskText.toLowerCase() == 'veryhigh'
  ) {
    return '#8F1DED';
  } else if (riskText.toLowerCase() === 'high') {
    return '#ef486f';
  } else if (riskText.toLowerCase() === 'alert') {
    return '#EA9FE6';
  } else if (riskText.toLowerCase() === 'medium') {
    return '#ffd166';
  } else if (riskText.toLowerCase() === 'low') {
    return '#05d69e';
  } else if (riskText.toLowerCase() === 'warn') {
    return '#FFFAA0';
  } else {
    return '#79CFFF';
  }
};

export const getRiskTextTranslated = (riskText: string, t): string => {
  if (
    riskText.toLowerCase() === 'very high' ||
    riskText.toLowerCase() == 'very_high' ||
    riskText.toLowerCase() == 'veryhigh'
  ) {
    return t('rwire.riskVeryHigh');
  } else if (riskText.toLowerCase() === 'high') {
    return t('rwire.riskHigh');
  } else if (riskText.toLowerCase() === 'alert') {
    return t('rwire.riskAlert');
  } else if (riskText.toLowerCase() === 'medium') {
    return t('rwire.riskMedium');
  } else if (riskText.toLowerCase() === 'low') {
    return t('rwire.riskLow');
  } else if (riskText.toLowerCase() === 'warn') {
    return t('rwire.riskWarn');
  } else {
    return t('rwire.riskInformative');
  }
};

export const getNumberIntegerTextScaled = (value?: number, fixedScale?: number) => {
  if (fixedScale === undefined) {
    fixedScale = 0;
  }

  if (value > 1000000) {
    value = value / 1000000.0;
    return value.toFixed(fixedScale) + 'M';
  }
  if (value > 1000) {
    value = value / 1000;
    return value.toFixed(fixedScale) + 'K';
  }
  return value;
};

export const getMaximumRiskFromAlerts = (alerts: Record<string, unknown>[]): string => {
  var maximumScoreFound = 0;
  var maximumRiskFound = null;
  if (!Array.isArray(alerts)) {
    return null;
  }
  alerts.forEach((alert) => {
    if ('alert' in alert && 'risk' in alert['alert']) {
      const riskAlert = alert['alert']['risk'];
      const scoreAlert = getRiskScoreAlert(riskAlert);
      if (scoreAlert > maximumScoreFound) {
        maximumScoreFound = scoreAlert;
        maximumRiskFound = riskAlert;
      }
    }
  });
  return maximumRiskFound;
};

export const getRiskScoreAlert = (risk: string): number => {
  switch (risk) {
    case 'very_high':
      return 5;
      break;
    case 'high':
      return 4;
      break;
    case 'medium':
      return 3;
      break;
    case 'low':
      return 2;
      break;
    default:
      return 1;
      break;
  }
};

export const getRiskColor = (risk: string): string => {
  switch (risk) {
    case 'very_high':
      return '#8F1DED';
      break;
    case 'high':
      return '#ef486f';
      break;
    case 'medium':
      return '#ffd166';
      break;
    case 'low':
      return '#05d69e';
      break;
    default:
      return '#79CFFF';
      break;
  }
};

export function getUniqueListBy(arr, key) {
  if (arr) {
    return [...new Map(arr.map((item) => [item[key], item])).values()];
  }
  return [];
}

const lightColors = [
  '#F0E5D8',
  '#F2D3BD',
  '#F9C6A1',
  '#F7AF9D',
  '#E5D6CC',
  '#D5D6B1',
  '#C5E2B0',
  '#A5D5AB',
  '#97B5D2',
  '#B5BFD0',
  '#E6C9A8',
  '#F0C0C8',
  '#E1B0B1',
  '#D1C8BB',
  '#E0C7C0',
  '#B4BCC2',
  '#C3B4C8',
  '#F7D0D0',
  '#E7C4D1',
  '#D7B9C8',
  '#A1BFD3',
  '#BFD1E5',
  '#C7B4B9',
  '#D4B4B4',
  '#DCB4A3',
  '#E5C79A',
  '#F2D7A3',
  '#F9C7BD',
  '#F5B5C8',
  '#E5D6CC',
  '#BFD1E5',
  '#A1BFD3',
  '#C5E2B0',
  '#D5D6B1',
  '#E6C9A8',
  '#F2D3BD',
  '#F0E5D8',
  '#F9C6A1',
  '#F7AF9D',
  '#D1C8BB',
  '#E1B0B1',
  '#F0C0C8',
  '#E7C4D1',
  '#D7B9C8',
  '#B4BCC2',
  '#C3B4C8',
  '#F7D0D0',
  '#E5D6CC',
  '#A1BFD3',
  '#BFD1E5',
];

const darkColors = [
  '#A79F8E',
  '#A8836E',
  '#9B7C53',
  '#916E6A',
  '#8F9CAB',
  '#7C7E5C',
  '#6A8D5B',
  '#4F7D56',
  '#4B5B73',
  '#5C5F73',
  '#8D704F',
  '#9C4E58',
  '#7F4245',
  '#685E50',
  '#7F5E54',
  '#5B6166',
  '#63505E',
  '#916767',
  '#7E5A68',
  '#6F4F5E',
  '#4D596B',
  '#5E6B80',
  '#675854',
  '#6E5454',
  '#753F3F',
  '#7E634C',
  '#8F7454',
  '#9B5F6E',
  '#916F7E',
  '#8F547A',
  '#8F9CAB',
  '#5E6B80',
  '#4D596B',
  '#6A8D5B',
  '#7C7E5C',
  '#8D704F',
  '#A8836E',
  '#A79F8E',
  '#9B7C53',
  '#916E6A',
  '#685E50',
  '#7F4245',
  '#9C4E58',
  '#7E5A68',
  '#6F4F5E',
  '#5B6166',
  '#63505E',
  '#916767',
  '#8F9CAB',
  '#4D596B',
  '#5E6B80',
];

const dictColorsByTheme: Record<string, string[]> = {
  light: darkColors,
  dark: lightColors,
};

export const randomColor = (theme: string): string => {
  const listColors = dictColorsByTheme?.[theme];
  if (listColors) {
    const randomIndex = Math.floor(Math.random() * listColors.length);
    return listColors[randomIndex];
  }
  const randomIndex = Math.floor(Math.random() * lightColors.length);
  return lightColors[randomIndex];
};

const hashStringToNumber = (input: string): number => {
  let hash = 0;
  for (let i = 0; i < input.length; i++) {
    const char = input.charCodeAt(i);
    hash = (hash << 5) - hash + char;
    hash |= 0; // Convert to 32bit integer
  }
  return hash;
};

export const randomColorByValue = (theme: string, value: string): string => {
  const listColors = dictColorsByTheme?.[theme];
  if (listColors) {
    const randomIndex = Math.abs(hashStringToNumber(value)) % listColors.length;
    return listColors[randomIndex];
  }
  const randomIndex = Math.abs(hashStringToNumber(value)) % listColors.length;
  return lightColors[randomIndex];
};

export const get_evidence_data_from_b64_pretty = (data: string | null): string | null => {
  try {
    if (data) {
      let decoded = atob(data);
      decoded = JSON.parse(decoded);
      decoded = JSON.stringify(decoded, undefined, 2);
      return decoded;
    }
  } catch (error) {
    console.log(error);
  }
  return null;
};

export const get_evidence_data_from_double_b64 = (data: string | null): string | null => {
  try {
    if (data) {
      let decoded = atob(data);
      decoded = JSON.parse(decoded);
      if (typeof decoded == 'string') {
        decoded = atob(decoded);
        decoded = JSON.parse(decoded);
      }
      return decoded;
    }
  } catch (error) {
    console.log(error);
  }
  return null;
};

export const get_evidence_data_from_recursive_b64 = (data: string | null): string | null => {
  try {
    if (data) {
      const maxIters = 50;
      let iters = 0;
      let decoded = atob(data);
      decoded = JSON.parse(decoded);
      while (typeof decoded == 'string' && iters <= maxIters) {
        decoded = atob(decoded);
        decoded = JSON.parse(decoded);
        iters += 1;
      }
      return decoded;
    }
  } catch (error) {
    console.log(error);
  }
  return null;
};

export const capitalizeString = (str?: string): string | undefined => {
  if (!str) {
    return undefined;
  }

  if (str.length === 0) {
    return str;
  }

  const firstChar = str.charAt(0).toUpperCase();
  const restOfString = str.slice(1).toLowerCase();

  return firstChar + restOfString;
};

export const isUserAdminByRole = (role?: string): boolean => {
  return role == 'admin' || role == 'superadmin';
};

export const isUserSuperadminByRole = (role?: string): boolean => {
  return role == 'superadmin';
};

export const isString = (str?: string): string | null => {
  return typeof str === 'string' ? str : null;
};

export const generateApiKeyStringByLength = (length: number): string => {
  const DEFAULT_ALPHABET =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789012345678901234567890123456789';
  return Array.from({ length: length })
    .map(() => {
      return DEFAULT_ALPHABET.charAt(Math.floor(Math.random() * DEFAULT_ALPHABET.length));
    })
    .join('');
};

export const colorByStatusCode = (statusCode: string | number, theme: string): string => {
  try {
    const statusCodeNumber = Number(statusCode);
    if (statusCodeNumber >= 200 && statusCodeNumber < 300) {
      return themeObject[theme].success;
    } else if (statusCodeNumber >= 300 && statusCodeNumber < 400) {
      return themeObject[theme].warning;
    } else if (statusCodeNumber >= 400) {
      return themeObject[theme].error;
    }
  } catch (error) {
    return themeObject[theme].light;
  }
  return themeObject[theme].light;
};

export const renderPlatformColumn = (platformName: string) => {
  let logoPlatform = <span>-</span>;
  if (platformName && platformName === 'Rsight') {
    platformName = 'ravensight';
  }
  if (platformName) {
    logoPlatform = (
      <PlatformsLogo platformName={platformName} widthIcon={ICON_WIDTH_VH_TABLE} widthLogo={IMAGE_WIDTH_VH_TABLE} />
    );
  }
  return logoPlatform;
};

export const calculateColorScoreVerdict = (score: number | string | undefined | null, theme: ThemeType): string => {
  if (score !== 0 && !score) {
    // score = 0;
    return theme ? themeObject[theme].grayScoreColor : BASE_COLORS.lightgrey;
  }
  if (typeof score === 'string') score = parseInt(score);

  if (score >= 4) {
    return theme ? themeObject[theme].redScoreColor : '#ef5c5c';
  } else if (score >= 2 && score <= 3) {
    return theme ? themeObject[theme].yellowScoreColor : '#ebff82';
  } else {
    return theme ? themeObject[theme].greenScoreColor : '#8aea8f';
  }
};

export const getIconTypeIOC = (type: string, width: string) => {
  const iconType = iconsIOCsByType[type];
  if (!iconType || iconType == '') {
    return <span style={{ fontSize: width, marginRight: '0.7em' }}>{type}</span>;
  } else {
    return (
      <Tooltip destroyTooltipOnHide={true} placement="top" trigger="hover" title={type}>
        <span style={{ fontSize: width, marginRight: '0.7em' }}>{iconType}</span>
      </Tooltip>
    );
  }
};

export const getValueTitleIoc = (valueData: string, type: string, fontSize: string) => {
  const value = showValueIOC(valueData, type);
  return (
    <CardInvisible bordered={false} justifyContent="left" fontSizeText={fontSize}>
      {getIconTypeIOC(type, '3vh')}{' '}
      <Tooltip destroyTooltipOnHide={true} placement="bottomLeft" trigger="hover" title={value}>
        <div
          style={{
            overflow: 'hidden',
            textAlign: 'left',
            marginTop: 'auto',
            marginBottom: 'auto',
            display: 'block',
            marginLeft: '2.5vh',
            textOverflow: 'ellipsis',
          }}
        >
          {value}
        </div>
      </Tooltip>
    </CardInvisible>
  );
};

export const getImageSrc = (img: string) => {
  if (isBase64(img)) {
    return 'data:image/png;base64, ' + img;
  }
  return img;
};

export const transformDate = (originalDate: string) => {
  const date = new Date(originalDate);
  const day = date.getDate().toString().padStart(2, '0');
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const year = date.getFullYear();
  return `${day}/${month}/${year}`;
};

export const generateColorForIcon = (score: number | string): string => {
  if (score !== 0 && !score) {
    return BASE_COLORS.lightgrey;
  }
  if (typeof score === 'string') score = parseInt(score);

  if (score >= 4) {
    return '#ef5c5c';
  } else if (score >= 2 && score <= 3) {
    return '#ebff82';
  }

  return '#8aea8f';
};
export const generateIconForSample = (stype: string, sample_name: string, score: number): React.ReactNode => {
  const color = generateColorForIcon(score);

  if (!stype) return <FileFilled />;
  if (stype == 'FILE') {
    if (['eml', 'msg'].includes(sample_name.slice(sample_name.length - 3).toLowerCase())) {
      return <MailFilled style={{ color: color }} />;
    }
    if (
      ['zip', 'rar', '.7z', '.gz', 'tar', 'rar', 'r01', 'arj'].includes(
        sample_name.slice(sample_name.length - 3).toLowerCase(),
      )
    ) {
      return <FileZipFilled style={{ color: color }} />;
    }
    if (
      ['mpg', 'mp2', 'mpe', 'mpv', 'ogg', 'mp4', 'm4p', 'm4v', 'avi', 'wmv', 'mov', '.qt', 'flv', 'swf'].includes(
        sample_name.slice(sample_name.length - 3).toLowerCase(),
      )
    ) {
      return <VideoCameraFilled style={{ color: color }} />;
    }
    if (['.webm', '.mpeg', 'avchd'].includes(sample_name.slice(sample_name.length - 5).toLowerCase())) {
      return <VideoCameraFilled style={{ color: color }} />;
    }
    if (
      ['.aif', '.cda', '.mid', 'midi', '.mp3', '.mpa', '.ogg', '.wav', '.wma', '.wpl'].includes(
        sample_name.slice(sample_name.length - 4).toLowerCase(),
      )
    ) {
      return <SoundFilled style={{ color: color }} />;
    }
    if (['.apk'].includes(sample_name.slice(sample_name.length - 4).toLowerCase())) {
      return <BsAndroid className={'anticon'} style={{ color: color }} />;
    }
    return <FileFilled style={{ color: color }} />;
  }

  if (sample_name.includes('twitter.com/')) {
    return <TwitterCircleFilled style={{ color: color }} />;
  }
  return <DribbbleCircleFilled style={{ color: color }} />;
};

export const veredictAnalystColor = (veredict: string | undefined) => {
  switch (veredict) {
    case 'SUSPICIOUS':
      return BASE_COLORS.amber;
    case 'PROBABLE':
      return BASE_COLORS.amber;
    case 'HIGHLY SUSPICIOUS':
      return BASE_COLORS.red;
    case 'FIRM':
      return BASE_COLORS.lightgreen;
    case 'INCONCLUSIVE':
      return BASE_COLORS.bluishgreen;
    default:
      return BASE_COLORS.gray;
  }
};
