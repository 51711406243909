import React from 'react';
import { notification } from 'antd';
import styled from 'styled-components';
import { CheckCircleFilled, ExclamationCircleFilled, InfoCircleFilled, StopFilled } from '@ant-design/icons';
import { ArgsProps } from 'antd/lib/notification';
import {
  addPersistNotificationToLocalStorage,
  setPersistNotificationToLocalStorage,
} from '@app/services/localStorage.service';
import { Notification, NotificationApi, NotificationApi2 } from '@app/api/notifications.api';

interface IconWrapperProps {
  $isOnlyTitle: boolean;
}

const IconWrapper = styled.div<IconWrapperProps>`
  font-size: ${(props) => (props.$isOnlyTitle ? '2rem' : '3rem')};
  line-height: 2rem;
`;

const EmptyDescription = styled.div`
  margin-top: -0.75rem;
`;

type NotificationProps = ArgsProps;

const openSuccessNotification = (config: NotificationProps): void => {
  notification.success({
    ...config,
    icon: (
      <IconWrapper $isOnlyTitle={!config.description}>
        <CheckCircleFilled className="success-icon" />
      </IconWrapper>
    ),
    message: <div className={`title ${!config.description && `title-only`}`}>{config.message}</div>,
    description: config.description ? <div className="description">{config.description}</div> : <EmptyDescription />,
    className: config.description ? '' : 'notification-without-description',
  });
};

const openInfoNotification = (config: NotificationProps): void => {
  notification.info({
    ...config,
    icon: (
      <IconWrapper $isOnlyTitle={!config.description}>
        <InfoCircleFilled className="info-icon" />
      </IconWrapper>
    ),
    message: <div className={`title ${!config.description && `title-only`}`}>{config.message}</div>,
    description: config.description ? <div className="description">{config.description}</div> : <EmptyDescription />,
    className: config.description ? '' : 'notification-without-description',
  });
};

const openWarningNotification = (config: NotificationProps): void => {
  notification.warning({
    ...config,
    icon: (
      <IconWrapper $isOnlyTitle={!config.description}>
        <ExclamationCircleFilled className="warning-icon" />
      </IconWrapper>
    ),
    message: <div className={`title ${!config.description && `title-only`}`}>{config.message}</div>,
    description: config.description ? <div className="description">{config.description}</div> : <EmptyDescription />,
    className: config.description ? '' : 'notification-without-description',
  });
};

const openErrorNotification = (config: NotificationProps): void => {
  notification.error({
    ...config,
    icon: (
      <IconWrapper $isOnlyTitle={!config.description}>
        <StopFilled className="error-icon" />
      </IconWrapper>
    ),
    message: <div className={`title ${!config.description && `title-only`}`}>{config.message}</div>,
    description: config.description ? <div className="description">{config.description}</div> : <EmptyDescription />,
    className: config.description ? '' : 'notification-without-description',
  });
};

export const notificationController = {
  success: openSuccessNotification,
  info: openInfoNotification,
  warning: openWarningNotification,
  error: openErrorNotification,
};

export const setNotificationsApiToLocalStorage = (notificationsApi: NotificationApi[]): void => {
  const notificationsToPersist: Notification[] = [];

  notificationsApi.forEach((notificationApi) => {
    if (notificationApi?.n_type == 'notification') {
      const notificationToPersist: NotificationApi2 = {
        userIcon: <InfoCircleFilled className="info-icon" />,
        description: notificationApi.n_text,
        id: 0, // Info
        date: notificationApi.n_date,
      };
      notificationsToPersist.push(notificationToPersist);
    } else if (notificationApi?.n_type == 'success') {
      const notificationToPersist = {
        userIcon: <CheckCircleFilled className="success-icon" />,
        description: notificationApi.n_text,
        id: 1, // Success
        date: notificationApi.n_date,
      };
      notificationsToPersist.push(notificationToPersist);
    } else if (notificationApi?.n_type == 'warning') {
      const notificationToPersist = {
        userIcon: <ExclamationCircleFilled className="warning-icon" />,
        description: notificationApi.n_text,
        id: 2, // Warning
        date: notificationApi.n_date,
      };
      notificationsToPersist.push(notificationToPersist);
    } else if (notificationApi?.n_type == 'error') {
      const notificationToPersist = {
        userIcon: <StopFilled className="error-icon" />,
        description: notificationApi.n_text,
        id: 3, // Error
        date: notificationApi.n_date,
      };
      notificationsToPersist.push(notificationToPersist);
    }
  });
  setPersistNotificationToLocalStorage(notificationsToPersist);
};

export const addNotificationApiToLocalStorage = (notificationApi: NotificationApi): void => {
  if (notificationApi?.n_type == 'notification') {
    const notificationToPersist = {
      userIcon: <InfoCircleFilled className="info-icon" />,
      description: notificationApi.n_text,
      id: 1, // Info
      date: notificationApi.n_date,
    };
    addPersistNotificationToLocalStorage(notificationToPersist);
  }
};
