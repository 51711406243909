import { useQueryHandler } from '@app/api/hooks/useQueryHandler';
import { useQuery } from '@tanstack/react-query';
import { getAnalysisRsightSandboxesScoresBySampleId } from '../requests/analyses.api';
import { rsightSandboxesScoresAdapter } from '../adapters/rsightSandboxesScores.adapter';
import { useTranslation } from 'react-i18next';

export const useRsightSandboxesScoresBySampleId = (sampleId?: string) => {
  const { t } = useTranslation();
  return useQueryHandler(
    useQuery({
      queryKey: ['getAnalysisRsightSandboxesScoresBySampleId', sampleId],
      queryFn: () => (sampleId ? getAnalysisRsightSandboxesScoresBySampleId(sampleId) : undefined),
      select: (data) => rsightSandboxesScoresAdapter(data),
    }),
    {
      errorMessage: t('common.loadingDataError'),
    },
  );
};
