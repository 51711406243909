import { NumericLiteral } from 'typescript';
import { HistoricalIocData } from '@app/api/iocs.api';
import * as React from 'react';
import { FilterValue, SorterResult, TablePaginationConfig } from 'antd/lib/table/interface';
import { MultiAVItem } from './apiInterfaces';
import { AuditJsonDataItemOutputsData, AuditRscoutRiskDataInterface } from './apiInterfaces/auditApiInterfaces';
import { ReactFlowNodeDataItem } from '@app/components/RavenScout/PlaybooksSettings/Playbooks/PlaybookForm/hooks/playbookWorkflowMethodsUtils';
import { ToolArgs } from '@app/interfaces/apiInterfaces/playbooks';

export type RoleType = 'user' | 'admin' | 'superadmin';
export interface ChartSeries {
  seriesName: string;
  value: number;
  data: {
    day: number;
    value: NumericLiteral;
  };
  name: string;
}

export interface IOCData {
  '@timestamp': string;
  elastic_id?: string;
  id: string;
  events: string[];
  feeds?: string[];
  hash: string;
  hash_chain: string[];
  parent_iocs: string[];
  subtype?: string[];
  type: string;
  value: string;
  score?: number;
  historical: HistoricalIocData[];
  tlp: string;
  geolocation?: any;
  nevents: number;
}

export interface EventInfo {
  log_facility: string | null;
  log_hostname: string;
  log_id: string;
  log_instance: string;
  log_ip_origin: string | null;
  log_logspace: string;
  log_message: string;
  log_priority: number;
  log_program: string;
  log_tech: string;
  total_log: number;
}

export interface EventMoreInfo {
  log_severity: string;
  log_name: string;
  log_category: string;
  log_description: string;
  log_id: string;
  log_meaning: string;
  log_type: string;
  log_fields: Array<string>;
}

export interface IOC {
  iocType: string;
  iocSubtype: Array<string>;
  iocId: string;
  iocValue: string;
  iocHash: string;
  iocNEvents: number;
  iocEvents: string[];
  iocScore: number;
  iocHistorical: Array<string>;
  feeds: Array<string>;
  iocParentsId: Array<string>;
  iocTlp: string;
}

export interface IPSInfo {
  first_ip: string;
  first_ip_data: Array<string>;
  second_ip: string;
  second_ip_data: Array<string>;
}

export interface IOCevents {
  elastic_id: string;
  hash: string;
  subtype: Array<string>;
  timestamp: string;
  type: string;
  value: string;
}

export interface GeolocationDataIoc {
  ip: string;
  latitude: number;
  longitude: number;
  country: string;
  country_long: string;
  country_name: string;
  country_short: string;
  city: string;
  autonomous_system_number: number;
  autonomous_system_organization: string;
  region: string;
  timezone: string;
  zipcode: string;
  ip_public: boolean;
  '@timestamp': string;
}

export interface GeolocationHistoryDataIoc {
  '@timestamp': string;
  latitude: string;
  longitude: string;
  country: string;
  city: string;
  ip: string;
  event_id: string;
}

export interface IOCiD {
  iocId: string;
  sampleId?: string;
}

export interface SampleIdProps {
  sampleId?: string;
  children?: React.ReactNode;
}

export interface eventiD {
  eventId: string;
}

export interface IOCImageData {
  iocImg: string;
  iocSpan: number;
  iocAlt: string;
}

export interface FileRtraceDataInterface {
  '@timestamp': string;
  elastic_id: string;
  file_name: string;
  file_type: string;
  file_size: string;
  file_data: string;
  tlp: string;
  instance: string;
  data: Record<string, unknown>;
}

export interface AnalysisRtraceDataInterface {
  '@timestamp': string;
  elastic_id: string;
  confidence_level: string;
  c0b2d3: string;
  c0b2d4: string;
  sample_id: number;
  [key: string]: unknown;
}

export interface MitreAttackData {
  id: string;
}

export interface AnalysisRsightMatrixMitreAttackResponse {
  data: {
    mitre_attack: MitreAttackData;
    tactics_attack: string[];
  };
}

export interface AnalysisRsightSBScore {
  c0b12d3: number;
  c0b12d2: number;
  c0b9d2: string;
  c0b12d4: number;
  c0b11d4: string;
  c0b5d4: string;
  c0b5d5: string;
  c0b15d4: null;
}
export interface AnalysisRsightScore {
  scores: AnalysisRsightScoreScores;
}

export interface AnalysisRsightScoreScores {
  sandboxes: SandboxScores;
  static: unknown;
  av_score: unknown;
  av_results: unknown;
  final_score: number;
  verdict: string;
}

export interface TablePagination extends TablePaginationConfig {
  pageSize: number;
  current: number;
  showQuickJumper: boolean;
}

export type TableFiltersType = Record<string, FilterValue | null> | null;
export type TableSorterType<RecordType> = SorterResult<RecordType> | SorterResult<RecordType>[] | null;

export interface AuditsRscoutApiResponse {
  audits_list: AuditsRscoutDataInterface[];
  total: number;
  page: number;
  size: number;
}
export interface AuditsRscoutDataInterface {
  analisis_id: number;
  client_id: number;
  client_image?: string;
  cliente: string;
  descripcion: string;
  estado_analisis: string;
  fecha_analisis: string;
  nombre_analisis: string;
  row_total: number;
  tipo: string;
  total_activos: string;
  total_vulns: string;
  totales_coste: AuditRscoutRiskDataInterface;
  totales_prioridad: AuditRscoutRiskDataInterface;
  totales_riesgo: AuditRscoutRiskDataInterface;
  user_name: string;
  vulns_id: string;
}

export interface AssetsRscoutDataInterface {
  activo_id: number;
  cliente_id: number;
  cliente: string;
  descripcion_activo: string;
  entorno_activo: string;
  nombre_activo: string;
  tipo_activo: string;
  row_total: number;
  [key: string]: any;
}

export interface MostCommonVulnerabilityInterface {
  id_vulnerabilidad: number;
  rscout_id: string;
  tipo_vulnerabilidad: string;
  total_vulnerabilidades: number;
  vulnerabilidad: string;
  riesgo_nivel: string;
  prioridad_nivel: string;
  coste_nivel: string;
}

export interface NumberStatusMailsInterfaceResponse {
  result_mails: NumberStatusMails;
  result_mails_last_hours: NumberStatusMails;
}

export interface NumberStatusMails {
  clean_mails: number;
  suspicious_mails: number;
  no_evaluated_mails: number;
  total_mails: number;
}

export interface IPGeolocationData {
  ip_address: string;
  ip_data: Record<string, unknown>;
  ip_country: string;
  ip_city: string;
  ip_lat: string;
  ip_long: string;
  mail_id: number;
  city: string;
  country: string;
  ip_dest: string;
  ip_origin: string;
  latitude: number;
  longitude: number;
}

export interface IPsGeolocatedMailsInterface {
  geolocation_mails: IPGeolocationData[];
}

export interface ThreatsByCategoryInterface {
  total_threats: {
    spam_threat_total: number;
    phis_threat_total: number;
    bec_threat_total: number;
    mal_threat_total: number;
  };
}

export interface DetectedSignatureRpostDataInterface {
  id: string;
  eid: string;
  cname: string;
  score: number;
  date: string;
  description: string;
}

export interface AdvancedSearchMailsRpostDataApiResponse {
  mails_rs_ids: string[];
  mails_elastic_ids: string[];
  total: number;
}

export interface CountriesAndCitiesAdvancedSearchMailsRpostDataApiResponse {
  countries: string[];
  cities: string[];
}

export interface MailsRpostDataApiResponse {
  mails: MailsRpostDataInterface[];
  page: number;
  size: number;
  total: number;
}

export interface MailsRpostAttachmentDataInterface {
  elastic_id_mail_parent?: string;
  binary: boolean;
  charset: string;
  'content-disposition': string;
  'content-id': string;
  content_transfer_encoding: string;
  filename: string;
  filesize?: string | number;
  mail_content_type: string;
  payload?: string;
  hash_sha256?: string;
}

export interface MailsRpostDataInterface {
  mail_id: number;
  bec_threat: number;
  elastic_id: string;
  ip_city: string | null;
  ip_country: string | null;
  ip_data: string | null;
  ip_origin: string | null;
  mail_d_date: string;
  mail_p_date: string;
  mail_score: number;
  mail_subject: string;
  mal_threat: number;
  phis_threat: number;
  rpost_id: string;
  spam_threat: number;
  mail_sender: string | null;
  mail_addresses: string | null;
  mail_attachment: string | null;
  mail_attachment_info?: MailsRpostAttachmentDataInterface[] | null;
  mail_platform: string | null;
}

export interface VulnerabilitiesData {
  tecnologies_list: string | null;
  uername: string | null;
  vulnerabilities_refetched: VulnerabilityData[];
  vulnerabilities_list: VulnerabilityData[];
  new_vulns: string;
}

export interface VulnerabilityData {
  id_vulnerabilidad: number;
  elastic_id: string;
  nombre_vulnerabilidad: string;
  descripcion_vulnerabilidad: string;
  vulnerabilidad: string;
  riesgo_nivel: string;
  prioridad_nivel: string;
  coste_nivel: string;
  tecnologia_vulnerabilidad: string;
  fecha: string;
  rscout_id: string;
  vulnerabilidiad_tipo: number;
}

//! HACER INTERFAZ
export interface AssetDataForGraph {}

export interface AssetDataForGeolocation {}

export interface AVListItem {
  avName: string;
  avProps: MultiAVItem;
}

export interface GeneralItemTabs {
  icon: JSX.Element;
  label: string;
  key: string;
  children: JSX.Element;
  visible: boolean;
}

export type Dimension = number | string;

export type ChartData = number[];

export type xData = number[] | string[];

export type LanguageType = string;

export type ThemeType = 'light' | 'dark';

export type ChartSeriesData = ChartSeries[];

export type Severity = 'success' | 'error' | 'info' | 'warning';

export type TwoFactorAuthOption = 'email' | 'phone';

export type CurrencyType = 'USD' | 'ETH' | 'BTC';

export type ActivityStatusType = 'sold' | 'booked' | 'added';

export type TypeFileUploadType = 'file' | 'url' | 'text';

export type TlpType = 'red' | 'ambar' | 'green' | 'white';

export enum Sandboxes {
  'INVISIBLE' = 'INVISIBLE', // TODO: Deprecated, now is RAVENBOX
  'CAPE' = 'CAPE',
  'STATICANALYSIS' = 'STATICANALYSIS', // TODO: Deprecated, now is RAVENSICS
  'JOESANDBOX' = 'JOESANDBOX',
  'HATCHING' = 'HATCHING',
  'FALCON' = 'FALCON',
  'RAVENSICS' = 'RAVENSICS',
  'RAVENSICSAV' = 'RAVENSICSAV',
  'RAVENBOX' = 'RAVENBOX',
  'RAVENTRACE' = 'RAVENTRACE',
}

export interface SandboxScores {
  [Sandboxes.CAPE]?: number;
  [Sandboxes.FALCON]?: number;
  [Sandboxes.HATCHING]?: number;
  [Sandboxes.INVISIBLE]?: number;
  [Sandboxes.JOESANDBOX]?: number;
  [Sandboxes.STATICANALYSIS]?: number;
}

export interface DynamicSandboxScores extends Omit<SandboxScores, Sandboxes.STATICANALYSIS> {}

export enum SampleTypes {
  'MAIL' = 'MAIL',
  'URL' = 'URL',
}

export type ReactFlowNodeData = {
  args?: {
    [handleId: string]: ReactFlowNodeDataItem;
  };
} & {
  [handleId: string]: ReactFlowNodeDataItem;
};

export interface ReactFlowNodeToolOutputData {
  output: AuditJsonDataItemOutputsData;
  tool: {
    type: string;
    value: string;
  };

  priority: {
    type: string;
    value: string;
  };

  order: {
    type: string;
    value: string;
  };

  input: {
    type: string;
    value: string;
  };

  args: ToolArgs | null;
}

export enum AvailableModules {
  RAVENSIGHT = 'ravensight',
  RAVENSEER = 'ravenseer',
  RAVENSCOUT = 'ravenscout',
  RAVENWIRE = 'ravenwire',
  RAVENPOST = 'ravenpost',
  RAVENTRACE = 'raventrace',
}

export type ModulesNames = ['ravensight', 'ravenseer', 'ravenscout', 'ravenwire', 'ravenpost'];
