import React, { useRef, useEffect } from 'react';
import styled from 'styled-components';
import { GlobalSpinner } from '@app/components/common/GlobalSpinner';
import { useAppSelector } from '@app/hooks/reduxHooks';
import { themeObject } from '@app/styles/themes/themeVariables';

interface LoadingProps {
  size?: string;
  color?: string;
}

export const LoadingRavenloop: React.FC<LoadingProps> = ({ size, color }) => {
  const theme = useAppSelector((state) => state.theme.theme);
  const spinnerColor = color || themeObject[theme].spinnerBase;
  const ref = useRef(null);

  useEffect(() => {
    const myDomElement = loop_loader();
    while (ref.current.firstChild) {
      ref.current.removeChild(ref.current.firstChild);
    }
    ref.current.appendChild(myDomElement);
  }, []);

  const loop_loader = () => {
    const canvas = document.createElement('canvas');
    // const canvas = document.getElementById(slab_name+'-loader-infinito');
    const width = canvas.width;
    const height = canvas.height;
    const ctx = canvas.getContext('2d');

    const startingPositions = [0, 1, 2, 3];
    const startionPositionSize = startingPositions.length;
    const nodeStartingDeviation = 0;
    const nodeCount = 180;
    const alphaIncrement = 1;
    let nodes = [];
    let originX = -1;
    let originY = -1;
    let domain = 0;
    const originRadius = 64;
    const waveHeight = 200;
    const connectionDistanceThreshold = 256;
    let counter = 0;
    let spacing = Math.floor(width / nodeCount);

    // Setup Canvas
    window.addEventListener('resize', function (e) {
      initialize();
    });
    window.requestAnimationFrame(render);
    ctx.fillStyle = '#FFFFFF';
    ctx.fillRect(0, 0, width, height);

    // Set A Timeout Before Starting
    window.setTimeout(initialize, 1);

    function initialize() {
      nodes = [];
      for (let i = 1; i <= nodeCount; i++) {
        nodes.push(createNode(i));
        counter += ((90 / 180) * Math.PI) / 9;
        domain += 1;
      }
    }

    // Render
    function render() {
      ctx.clearRect(0, 0, width, height);

      renderNodes_static();
      updateNodes_static();
      renderNodes();
      updateNodes();
      populateNodes();
      window.requestAnimationFrame(render);
    }

    function renderOrigin() {}

    function renderNodes() {
      Array.prototype.forEach.call(nodes, function (b) {
        if (!b.hasOwnProperty('type')) {
          ctx.fillStyle = spinnerColor;
          ctx.beginPath();
          ctx.arc(b.x, b.y, 10, 0, Math.PI * 2, true);
          ctx.closePath();
          ctx.fill();
        }
      });
    }

    function renderNodes_static() {
      Array.prototype.forEach.call(nodes, function (b) {
        if (!b.hasOwnProperty('type')) {
          ctx.fillStyle = 'transparent';
          ctx.beginPath();
          ctx.arc(b.x, b.y, 10, 0, Math.PI * 2, true);
          ctx.closePath();
          ctx.fill();
        }
      });
    }

    function populateNodes() {
      if (nodes.length < nodeCount) {
        domain = 0;
        nodes.push(createNode(0));
      }
    }

    function createNode(i) {
      const min = 0;
      const max = 10;
      var angle = (360 / width) * i;
      var rad = (angle * Math.PI) / 180;
      var a = 2;
      const percent = (i * spacing) / width;
      return {
        x: getXAxisLocation() + (a * Math.cos(rad)) / (1 + Math.sin(rad) * Math.sin(rad)),
        y: getYAxisLocation() + (a * Math.sin(rad) * Math.cos(rad)) / (1 + Math.sin(rad) * Math.sin(rad)),
        index: i,
        vx: getRandomSpeed(0)[0],
        vy: getRandomSpeed(0)[1],
        r: nodeStartingDeviation,
        phase: getRandom(min, max),
        connected: false,
      };
    }

    // Update balls
    function updateNodes_static() {
      const new_balls = [];
      Array.prototype.forEach.call(nodes, function (b) {
        const percent = (b.index * spacing) / width;
        b.index += 1;
        var angle = (500 / width) * b.index; // Longitud infinito
        var rad = (angle * Math.PI) / 180;
        var a = 100; // Altura infinito
        b.x = getXAxisLocation() + (a * Math.cos(rad)) / (1 + Math.sin(rad) * Math.sin(rad));
        b.y = getYAxisLocation() + (a * Math.sin(rad) * Math.cos(rad)) / (1 + Math.sin(rad) * Math.sin(rad));

        if (b.x > -50 && b.x < width + 50 && b.y > -50 && b.y < height + 50) {
          new_balls.push(b);
        }
      });

      nodes = new_balls.slice(0);
    }

    function updateNodes() {
      const new_balls = [];
      Array.prototype.forEach.call(nodes, function (b) {
        const percent = (b.index * spacing) / width;
        b.index += 1;
        var angle = (700 / width) * b.index; // Longitud infinito
        var rad = (angle * Math.PI) / 180;
        var a = 100; // Altura infinito
        b.x = getXAxisLocation() + (a * Math.cos(rad)) / (1 + Math.sin(rad) * Math.sin(rad));
        b.y = getYAxisLocation() + (a * Math.sin(rad) * Math.cos(rad)) / (1 + Math.sin(rad) * Math.sin(rad));

        if (b.x > -50 && b.x < width + 50 && b.y > -50 && b.y < height + 50) {
          new_balls.push(b);
        }
      });

      nodes = new_balls.slice(0);
    }

    function getRandomSpeed() {
      const min = -1;
      const max = 0.1;
      return [getRandom(min, max), getRandom(min, max)];
    }

    function getRandom(min, max) {
      return Math.random() * (max - min) + min;
    }

    function getXAxisLocation() {
      const centerX = width / 2;
      return getRandom(centerX - nodeStartingDeviation, centerX + nodeStartingDeviation);
    }

    function getYAxisLocation() {
      const centerY = height / 2;
      return getRandom(centerY - nodeStartingDeviation, centerY + nodeStartingDeviation);
    }

    return canvas;
  };

  return <SpinnerContainer ref={ref}></SpinnerContainer>;
};

const SpinnerContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
