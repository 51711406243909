import { withAccess } from '@app/hocs/withAccess';
import { withLoading } from '@app/hocs/withLoading.hoc';
import { lazy } from 'react';

const GeneralStatsLayout = lazy(() => import('@app/pages/DashboardPages/Ravensight/GeneralStatsLayout'));
const GeneralDashboardPage = lazy(() => import('@app/pages/DashboardPages/Ravensight/GeneralDashboardPage'));
const MitreDashboardPage = lazy(() => import('@app/pages/DashboardPages/Ravensight/MitreDashboardPage'));
const ClientDashboardPage = lazy(() => import('@app/pages/DashboardPages/ClientDashboard/ClientDashboardPage'));
const UserContentPage = lazy(() => import('../RavenSight/ClientDashboard/components/UserContent/UserContentPage'));

const GeneralStatsLayoutFallback = withLoading(GeneralStatsLayout);
const GeneralDashboard = withAccess(GeneralDashboardPage);
const MitreDashboard = withAccess(MitreDashboardPage);

const dashboardRouter = [
  {
    path: 'general',
    element: <GeneralStatsLayoutFallback />,
    children: [
      {
        path: '',
        element: <GeneralDashboard />,
      },
    ],
  },
  {
    path: 'mitre',
    element: <GeneralStatsLayoutFallback />,
    children: [
      {
        path: '',
        element: <MitreDashboard />,
      },
    ],
  },
  {
    path: 'client',
    children: [
      {
        path: '',
        element: <ClientDashboardPage />,
      },
      {
        path: 'user/:userId', //TODO: users en plural
        element: <UserContentPage />,
      },
    ],
  },
];

export default dashboardRouter;
