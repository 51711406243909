import { BaseForm } from '@app/components/common/forms/BaseForm/BaseForm';
import { CaptchaData } from '@app/interfaces/loginInterfaces';
import * as Auth from '@app/components/layouts/AuthLayout/AuthLayout.styles';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

interface Props {
  captchaData: CaptchaData;
}

const CaptchaImgWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

export const LoginCaptcha: React.FC<Props> = ({ captchaData }) => {
  const { t } = useTranslation();
  return (
    <>
      <CaptchaImgWrapper>
        <img src={`data:image/png;base64, ${captchaData.img}`} width="60%" />
      </CaptchaImgWrapper>
      <Auth.FormItem
        name="captcha-text"
        label={t('common.captcha')}
        rules={[{ required: true, message: t('common.requiredField') }]}
      >
        <Auth.FormInput placeholder={t('common.enterCaptcha')} />
      </Auth.FormItem>
    </>
  );
};
