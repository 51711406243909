//COLOR
import { ReactComponent as CapeSVG } from '@app/assets/icons/sandbox/CAPE.svg';
import { ReactComponent as FalconSVG } from '@app/assets/icons/sandbox/FALCON.svg';
import { ReactComponent as FireeyeSVG } from '@app/assets/icons/sandbox/FIREEYE.svg';
import { ReactComponent as FireeyeFXSVG } from '@app/assets/icons/sandbox/FIREEYEFX.svg';
import { ReactComponent as FortisandboxSVG } from '@app/assets/icons/sandbox/FORTISANDBOX.svg';
import { ReactComponent as InvisibleSVG } from '@app/assets/icons/sandbox/INVISIBLE.svg';
import { ReactComponent as JosesandboxSVG } from '@app/assets/icons/sandbox/JOSESANDBOX.svg';
import { ReactComponent as RavenscoutSVG } from '@app/assets/icons/sandbox/RAVENSCOUT.svg';
import { ReactComponent as RavensightSVG } from '@app/assets/icons/sandbox/RAVENSIGHT.svg';
import { ReactComponent as RavensightPLSVG } from '@app/assets/icons/sandbox/RAVENSIGHT_PL.svg';
import { ReactComponent as StaticanalysisSVG } from '@app/assets/icons/sandbox/STATICANALYSIS.svg';
import { ReactComponent as HatchingSVG } from '@app/assets/icons/sandbox/HATCHING.svg';
import { ReactComponent as StormshieldSVG } from '@app/assets/icons/sandbox/STORMSHIELD.svg';
import { ReactComponent as VmraySVG } from '@app/assets/icons/sandbox/VMRAY.svg';
import React from 'react';
import { AiFillCodeSandboxCircle, AiFillHome } from 'react-icons/ai';
import { HiOutlineBeaker } from 'react-icons/hi';

import { IconType } from 'react-icons/lib';

type RCAsSVG = React.FunctionComponent<
  React.SVGProps<SVGSVGElement> & {
    title?: string | undefined;
  }
>;

interface DictReactIconSandboxes {
  color: Record<string, RCAsSVG | IconType>;
}

const dictReactIconSandboxes: DictReactIconSandboxes = {
  color: {
    CAPE: CapeSVG,
    FALCON: FalconSVG,
    FIREEYE: FireeyeSVG,
    FIREEYEFX: FireeyeFXSVG,
    FORTISANDBOX: FortisandboxSVG,
    RAVENBOX: InvisibleSVG,
    INVISIBLE: InvisibleSVG,
    JOSESANDBOX: JosesandboxSVG,
    RAVENSCOUT: RavenscoutSVG,
    RAVENSIGHT: RavensightSVG,
    RAVENSIGHT_PL: RavensightPLSVG,
    STATICANALYSIS: StaticanalysisSVG,
    HATCHING: HatchingSVG,
    STORMSHIELD: StormshieldSVG,
    VMRAY: VmraySVG,
    DYNAMIC: AiFillCodeSandboxCircle,
    PRINCIPAL: AiFillHome,
    INTELLIGENCE: HiOutlineBeaker,
    RAVENTRACE: HiOutlineBeaker,
  },
};

export const getReactIconSandboxByName = (sandboxName: string): RCAsSVG | IconType | undefined => {
  if (sandboxName) {
    return dictReactIconSandboxes['color'][sandboxName.toString().toUpperCase()];
  }

  return undefined;
};
