import { FormTitleWrapper } from '@app/components/common/forms/components/FormTitleWrapper/FormTitleWrapper';
import { Trans, useTranslation } from 'react-i18next';
import { transComponents } from '../HelpContent';
import HelpExecutiveReportPrincipal from './HelpExecutiveReportPrincipal';
import HelpExecutiveReportDatabase from './HelpExecutiveReportDatabase';

const HelpExecutiveReport = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'help.executiveReport' });

  return (
    <>
      <FormTitleWrapper>
        <h4 id="executiveReport">
          <Trans t={t} i18nKey="title" components={transComponents} />
        </h4>
      </FormTitleWrapper>
      <h5 id="executiveReport-video1">VIDEO</h5>
      <p>
        <Trans t={t} i18nKey="p1" components={transComponents} />
      </p>
      <ul>
        <li>
          <Trans t={t} i18nKey="ul1.li1" components={transComponents} />
        </li>
        <li>
          <Trans t={t} i18nKey="ul1.li2" components={transComponents} />
        </li>
        <li>
          <Trans t={t} i18nKey="ul1.li3" components={transComponents} />
        </li>
        <li>
          <Trans t={t} i18nKey="ul1.li4" components={transComponents} />
        </li>
        <li>
          <Trans t={t} i18nKey="ul1.li5" components={transComponents} />
        </li>
        <li>
          <Trans t={t} i18nKey="ul1.li6" components={transComponents} />
        </li>
      </ul>
      <p>
        <Trans t={t} i18nKey="p2" components={transComponents} />
      </p>
      <ul>
        <li>
          <Trans t={t} i18nKey="ul2.li1" components={transComponents} />
        </li>
        <li>
          <Trans t={t} i18nKey="ul2.li2" components={transComponents} />
        </li>
        <li>
          <Trans t={t} i18nKey="ul2.li3" components={transComponents} />
        </li>
        <li>
          <Trans t={t} i18nKey="ul2.li4" components={transComponents} />
        </li>
      </ul>
      <HelpExecutiveReportPrincipal />
      <HelpExecutiveReportDatabase />
    </>
  );
};

export default HelpExecutiveReport;
