import React, { Suspense } from 'react';
import { Loading } from '@app/components/common/Loading';
import { LoadingRavenloop } from '@app/components/common/Loadings/LoadingRavenloop';
import { LoadingRavenloopGif } from '@app/components/common/Loadings/LoadingRavenloopGif';

type ReturnType<T> = (props: T) => JSX.Element;

// eslint-disable-next-line @typescript-eslint/ban-types
export const withLoading = <T extends object>(Component: React.ComponentType<T>): ReturnType<T> => {
  return (props: T) => (
    <Suspense fallback={<LoadingRavenloop />}>
      <Component {...props} />
    </Suspense>
  );
};
