import { Trans, useTranslation } from 'react-i18next';
import { transComponents } from '../HelpContent';

const HelpAdvancedSearch = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'help.analyses.advancedSearch' });

  return (
    <>
      <h4 id="advancedSearch">
        <Trans t={t} i18nKey="title" components={transComponents} />
      </h4>
      <p>
        <Trans t={t} i18nKey="p1" components={transComponents} />
      </p>
      <h5 id="advancedSearch-image1">IMAGE</h5>
      <p>
        <Trans t={t} i18nKey="p2" components={transComponents} />
      </p>
      <h5 id="advancedSearch-video1">VIDEO</h5>
    </>
  );
};

export default HelpAdvancedSearch;
