import React, { useEffect, useMemo, useState } from 'react';
import { Trans } from 'react-i18next';
import { useTranslation } from 'react-i18next';
import { Col, PaginationProps, Row, Space } from 'antd';
import { Link } from 'react-router-dom';
import { Notification } from '@app/components/common/Notification/Notification';
import { capitalize } from '@app/utils/utils';
import { Mention, Notification as NotificationType, postDeleteAllNotificationsApi } from '@app/api/notifications.api';
import { notificationsSeverities } from '@app/constants/notificationsSeverities';
import * as S from './NotificationsOverlay.styles';
import { clearNotificationsLocalStorage } from '@app/services/localStorage.service';
import { Pagination } from '@app/components/common/Pagination/Pagination';
import { Pagination as PaginationInterface } from '@app/api/rwire/TableLastAlerts.api';
import { SpaceCustom } from './NotificationsOverlay.styles';
import { FONT_SIZE_VH as FONT_SIZE } from '@app/styles/themes/constants';
import { useResponsive } from '@app/hooks/useResponsive';

interface NotificationsOverlayProps {
  notifications: NotificationType[];
  setNotifications: (state: NotificationType[]) => void;
}

const initialPagination: PaginationInterface = {
  current: 1,
  pageSize: 5,
  // pageSizeOptions: [5, 10],
  showSizeChanger: false,
  position: 'bottom',
};

const initialMobilePagination: PaginationInterface = {
  current: 1,
  pageSize: 3,
  // pageSizeOptions: [5, 10],
  showSizeChanger: false,
  position: 'bottom',
};

export const NotificationsOverlay: React.FC<NotificationsOverlayProps> = ({
  notifications,
  setNotifications,
  ...props
}) => {
  const { t } = useTranslation();
  // const [noticesList, setNoticesList] = useState<NotificationType[]>([]);
  const { isDesktop, mobileOnly } = useResponsive();
  const [pagination, setPagination] = useState<PaginationInterface>(initialPagination);

  const noticesList = useMemo(
    () =>
      notifications
        ?.slice(
          pagination.pageSize * (pagination.current - 1),
          pagination.pageSize * (pagination.current - 1) + pagination.pageSize,
        )
        .map((notification, index) => {
          const type = notificationsSeverities.find((dbSeverity) => dbSeverity.id === notification.id)?.name;

          return (
            <Notification
              key={index}
              type={type || 'warning'}
              title={capitalize(type || 'warning')}
              description={t(notification.description)}
              {...(type === 'mention' && {
                mentionIconSrc: (notification as Mention).userIcon,
                title: (notification as Mention).userName,
                description: (
                  <Trans i18nKey={(notification as Mention).description}>
                    <S.LinkBtn type="link" href={(notification as Mention).href}>
                      {{ place: t((notification as Mention).place) }}
                    </S.LinkBtn>
                  </Trans>
                ),
              })}
            />
          );
        }),
    [notifications, t, pagination],
  );

  useEffect(() => {
    // getNotificationsListByPage(pagination);
    setPagination({
      ...pagination,
      total: notifications ? notifications.length : 0,
    });
  }, [notifications]); // eslint-disable-line react-hooks/exhaustive-deps

  const getNotificationsListByPage = (pagination) => {
    const offset = pagination.pageSize * (pagination.current - 1);
    const limit = pagination.pageSize;
    const noticesList = notifications?.slice(offset, limit).map((notification, index) => {
      const type = notificationsSeverities.find((dbSeverity) => dbSeverity.id === notification.id)?.name;
      return (
        <Notification
          key={index}
          type={type || 'warning'}
          title={capitalize(type || 'warning')}
          description={t(notification.description)}
          {...(type === 'mention' && {
            mentionIconSrc: (notification as Mention).userIcon,
            title: (notification as Mention).userName,
            description: (
              <Trans i18nKey={(notification as Mention).description}>
                <S.LinkBtn type="link" href={(notification as Mention).href}>
                  {{ place: t((notification as Mention).place) }}
                </S.LinkBtn>
              </Trans>
            ),
          })}
        />
      );
    });
    setNoticesList(noticesList);
  };

  const handlePaginationChange: PaginationProps['onChange'] = (page, pageSize) => {
    setPagination({
      ...pagination,
      current: page,
      pageSize: pageSize,
    });
    // getNotificationsListByPage({
    //     ...pagination,
    //     current: page,
    //     pageSize: pageSize
    // });
  };

  return (
    <S.NoticesOverlayMenu mode="inline" {...props}>
      <S.MenuRow gutter={[20, 20]}>
        <Col span={24}>
          {notifications?.length > 0 ? (
            <S.SpaceCustom direction="vertical" size={10} split={<S.SplitDivider />}>
              {noticesList}
            </S.SpaceCustom>
          ) : (
            <S.Text>{t('header.notifications.noNotifications')}</S.Text>
          )}
        </Col>
        <Col span={24}>
          <Row gutter={[10, 10]}>
            {notifications?.length > pagination.pageSize && (
              <Col span={24}>
                <Pagination
                  style={{ textAlign: 'center', fontSize: FONT_SIZE.md }}
                  size="small"
                  defaultCurrent={pagination.current}
                  pageSize={pagination.pageSize}
                  total={pagination.total}
                  pageSizeOptions={pagination.pageSizeOptions}
                  showSizeChanger={pagination.showSizeChanger}
                  onChange={handlePaginationChange}
                />
              </Col>
            )}
            {notifications?.length > 0 && (
              <Col span={24}>
                <S.Btn
                  type="ghost"
                  onClick={() => {
                    postDeleteAllNotificationsApi(t);
                  }}
                >
                  {t('header.notifications.readAll')}
                </S.Btn>
              </Col>
            )}
            <Col span={24}>
              <S.Btn type="link">
                <Link to="#" style={{ fontSize: FONT_SIZE.md }}>
                  {t('header.notifications.viewAll')}
                </Link>
              </S.Btn>
            </Col>
          </Row>
        </Col>
      </S.MenuRow>
    </S.NoticesOverlayMenu>
  );
};
