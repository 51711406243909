//COLOR
import logoRavensight from '@app/assets/logos_platform/logo_ravensight_long.png';
import logoRavenpost from '@app/assets/logos_platform/logo_ravenpost_long.png';
import logoRavenwire from '@app/assets/logos_platform/logo_ravenwire_long.png';
import logoRavenscout from '@app/assets/logos_platform/logo_ravenscout_long.png';
import logoRaventrace from '@app/assets/logos_platform/logo_raventrace_long.png';
import logoRavenseer from '@app/assets/logos_platform/logo_ravenseer_long.png';
import logoElasticSearch from '@app/assets/logos_platform/logo_elasticsearch_short.png.svg';
//COLOR SHORT
import logoRavensightShort from '@app/assets/logos_platform/logo_ravensight_short.png';
import logoRavenpostShort from '@app/assets/logos_platform/logo_ravenpost_short.png';
import logoRavenwireShort from '@app/assets/logos_platform/logo_ravenwire_short.png';
import logoRavenscoutShort from '@app/assets/logos_platform/logo_ravenscout_short.png';
import logoRaventraceShort from '@app/assets/logos_platform/logo_raventrace_short.png';
import logoRavenseerShort from '@app/assets/logos_platform/logo_ravenseer_short.png';
import logoinsightVM from '@app/assets/logos_platform/logo_insightvm.png';
import logoOpenvas from '@app/assets/logos_platform/logo_openvas.png';
import logoNessus from '@app/assets/logos_platform/logo_nessus.png';

const dictLogoPlatforms = {
  color: {
    ravensight: logoRavensight,
    ravenwire: logoRavenwire,
    ravenseer: logoRavenseer,
    ravenpost: logoRavenpost,
    ravenscout: logoRavenscout,
    raventrace: logoRaventrace,
    elasticsearch: logoElasticSearch,
    insightvm: logoinsightVM,
    nessus: logoNessus,
    openvas: logoOpenvas,
  },
};

const dictLogoShortPlatforms = {
  color: {
    ravensight: logoRavensightShort,
    ravenwire: logoRavenwireShort,
    ravenseer: logoRavenseerShort,
    ravenpost: logoRavenpostShort,
    ravenscout: logoRavenscoutShort,
    raventrace: logoRaventraceShort,
    insightvm: logoinsightVM,
    nessus: logoNessus,
    openvas: logoOpenvas,
  },
};

export const getLogoPlatformByNameString = (platformName: string): string => {
  return dictLogoPlatforms['color'][platformName];
};

export const getLogoPlatformShortByNameString = (platformName: string): string => {
  return dictLogoShortPlatforms['color'][platformName];
};

export const getLogoPlatformByName = (platformName: string): string => {
  return dictLogoPlatforms['color'][platformName.toString().toLowerCase()];
};

export const getLogoPlatformShortByName = (platformName: string): string => {
  return dictLogoShortPlatforms['color'][platformName.toString().toLowerCase()];
};
