import { useTranslation } from 'react-i18next';
import { Error } from '@app/components/Error/Error';
import { PageTitle } from '@app/components/common/PageTitle/PageTitle';

const LoadingPageError = () => {
  const { t } = useTranslation();

  return (
    <>
      <PageTitle>{t('loadingPageError.title')}</PageTitle>
      <Error msg={t('loadingPageError.main')} />
    </>
  );
};

export default LoadingPageError;
