import { ApiError } from '../ApiError';
import { UseQueryResult } from '@tanstack/react-query';
import { useApiErrorHandler } from './useApiErrorHandler';
import { notificationController } from '@app/controllers/notificationController';
import { useEffect } from 'react';

export type UseQueryHandler = <T>(queryResult: T, customMessages?: CustomMessages) => T;
export type CustomMessages = {
  errorMessage?: string;
  successMessage?: string;
};

export const useQueryHandler: UseQueryHandler = (queryResult, customMessages?) => {
  const apiErrorHandler = useApiErrorHandler();
  const { isError, error, isSuccess, isLoading } = queryResult as UseQueryResult;

  useEffect(() => {
    if (isError && error instanceof ApiError) apiErrorHandler(error, customMessages?.errorMessage);
  }, [isError, error, customMessages?.errorMessage]);

  useEffect(() => {
    if (isSuccess && !isLoading && customMessages?.successMessage)
      notificationController.success({ message: customMessages.successMessage });
  }, [isSuccess, isLoading, customMessages?.successMessage]);

  return queryResult;
};
