import React from 'react';
import {
  FileOutlined,
  FileUnknownOutlined,
  WalletOutlined,
  LaptopOutlined,
  BorderlessTableOutlined,
  CreditCardOutlined,
  MailOutlined,
  UserOutlined,
  LinkOutlined,
  PhoneOutlined,
  TagOutlined,
  FileImageOutlined,
} from '@ant-design/icons';
import { BiWorld } from 'react-icons/bi';
import { FaCity } from 'react-icons/fa';
import { GoLocation } from 'react-icons/go';
import { GrDomain } from 'react-icons/gr';
import { TbWorld } from 'react-icons/tb';
import { MdOutlineScreenSearchDesktop } from 'react-icons/md';

export const iconsIOCsByType: { [id: string]: React.ReactNode } = {
  url: <LinkOutlined />,
  ipv4: <LaptopOutlined />,
  ipv6: <LaptopOutlined />,
  filename: <FileOutlined />,
  filesize: <FileUnknownOutlined />,
  wallet: <WalletOutlined />,
  hash_md5: <BorderlessTableOutlined />,
  hash_sha256: <BorderlessTableOutlined />,
  hash_sha1: <BorderlessTableOutlined />,
  hash_ssdeep: <BorderlessTableOutlined />,
  hash_imphash: <BorderlessTableOutlined />,
  credit_card: <CreditCardOutlined />,
  email: <MailOutlined />,
  username: <UserOutlined />,
  pais: <BiWorld />,
  ciudad: <FaCity />,
  lat_long: <GoLocation />,
  telefono: <PhoneOutlined />,
  tag: <TagOutlined />,
  imagen: <FileImageOutlined />,
  dominio: <TbWorld />,
  domain: <TbWorld />,
  subdominio: <TbWorld />,
  subdomain: <TbWorld />,
  whois: <MdOutlineScreenSearchDesktop />,
};

export const allIOCsTypes = [
  'ipv4',
  'ipv6',
  'url',
  'dominio',
  'subdomino',
  'uri',
  'tld',
  'ip_puerto',
  'puerto',
  'asn',
  'pais',
  'ciudad',
  'lat_long',
  'identidad',
  'email',
  'username',
  'sexo',
  'ss_number',
  'credit_card',
  'bank_account',
  'telefono',
  'software',
  'process_name',
  'servicios',
  'guid',
  'op_system',
  'version',
  'mutex',
  'regkey',
  'filename',
  'filesize',
  'hash_md5',
  'hash_sha256',
  'hash_sha1',
  'hash_ssdeep',
  'hash_imphash',
  'ttp',
  'organizacion',
  'threat',
  'familia',
  'mitre_tactic',
  'cwe',
  'capec',
  'wallet',
  'funciones_codigo',
  'lenguaje_p',
  'lenguaje_i',
  'cvss',
  'cve',
  'swift',
  'ja3',
  'strings',
  'matricula_coche',
  'file',
  'vulnerability',
  'imagen',
  'tag',
];
