import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import * as S from './ProfileOverlay.styles';
import { DropdownMenu } from '@app/components/header/Header.styles';
import { readUser } from '@app/services/localStorage.service';
import { downloadMyCertificate } from '@app/api/rsight/management.api';
import { Button } from 'antd';
import { NightModeSettings } from '../../settingsDropdown/settingsOverlay/nightModeSettings/NightModeSettings';
import { ThemePicker } from '../../settingsDropdown/settingsOverlay/ThemePicker/ThemePicker';
import { SettingsOverlayMenu } from '../../settingsDropdown/settingsOverlay/SettingsOverlay/SettingsOverlay.styles';
import styled from 'styled-components';
import { AiOutlineDownload } from 'react-icons/ai';

const CertificateContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const ProfileOverlay: React.FC = ({ ...props }) => {
  const { t } = useTranslation();

  return (
    <DropdownMenu selectable={false} {...props}>
      {(() => {
        if (readUser().user_type == 'superadmin') {
          return (
            <>
              <S.MenuItem key={2}>
                <S.Text>
                  <CertificateContainer>
                    {t('header.certificate')}
                    <Button
                      onClick={() =>
                        downloadMyCertificate(
                          readUser()?.client_settings.language,
                        )
                      }
                    >
                      <AiOutlineDownload />
                    </Button>
                  </CertificateContainer>
                </S.Text>
              </S.MenuItem>
              <S.ItemsDivider />
            </>
          );
        }

        return null;
      })()}
      <S.MenuItem key={0}>
        <S.Text>
          <Link to="/profile">{t('profile.title')}</Link>
        </S.Text>
      </S.MenuItem>
      <S.ItemsDivider />
      <S.MenuItem key={1}>
        <S.Text>
          <Link to="/logout">{t('header.logout')}</Link>
        </S.Text>
      </S.MenuItem>
      <S.ItemsDivider />

      <SettingsOverlayMenu mode="inline">
        <S.TitleNightMode>{t('header.nightMode.title')}</S.TitleNightMode>
        <NightModeSettings />
      </SettingsOverlayMenu>

      <S.ItemsDivider />

      <S.ThemePickerDiv>
        <ThemePicker />
      </S.ThemePickerDiv>
    </DropdownMenu>
  );
};
