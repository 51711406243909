import { createGlobalStyle } from 'styled-components';
import { resetCss } from './resetCss';
import { BREAKPOINTS, FONT_SIZE_VH as FONT_SIZE, FONT_WEIGHT, BASE_COLORS, media } from './themes/constants';
import {
  lightThemeVariables,
  darkThemeVariables,
  commonThemeVariables,
  antOverrideCssVariables,
} from './themes/themeVariables';

export default createGlobalStyle`

  ${resetCss}
  [data-theme='light'],
  :root {
    ${lightThemeVariables}
  }

  [data-theme='dark'] {
    ${darkThemeVariables}
  }

  :root {
    ${commonThemeVariables};
    ${antOverrideCssVariables};
  } 
 
  [data-no-transition] * {
    transition: none !important;
  }

  .range-picker {
    & .ant-picker-panels {
      @media only screen and (${media.xs}) and (max-width: ${BREAKPOINTS.md - 0.02}px) {
        display: flex;
        flex-direction: column;
      }
    }
  }

  .search-dropdown {
    box-shadow: var(--box-shadow);

    @media only screen and (${media.xs}) and (max-width: ${BREAKPOINTS.md - 0.02}px) {
      width: calc(100vw - 16px);
      max-width: 600px;
    }

    @media only screen and (${media.md}) {
      max-width: 323px;
    }
  }

  a {
    color: var(--primary-color);

    &:hover, :active {
      color: var(--ant-primary-color-hover);
    }
  }

  .d-none {
    display: none;
  }

  .ant-picker-cell {
    color: var(--text-main-color);
  }

  .ant-picker-cell-in-view .ant-picker-calendar-date-value {
    color: var(--text-main-color);
    font-weight: ${FONT_WEIGHT.bold};
  }

  .ant-picker svg {
    color: var(--text-light-color);
  }

  // notifications start
  .ant-notification-notice {
    width: 36rem;
    padding: 2rem;
    min-height: 6rem;

    .ant-notification-notice-with-icon .ant-notification-notice-message {
      margin-bottom: 0;
      margin-left: 2.8125rem;
    }

    .ant-notification-notice-with-icon .ant-notification-notice-description {
      margin-left: 4.375rem;
      margin-top: 0;
    }

    .ant-notification-notice-icon {
      font-size: 2.8125rem;
      margin-left: 0
    }

    .ant-notification-notice-close {
      top: 1.25rem;
      right: 1.25rem;
    }

    .ant-notification-notice-close-x {
      display: flex;
      font-size: 0.9375rem;
    }

    .notification-without-description {
      .ant-notification-notice-close {
        top: 1.875rem;
      }

      .ant-notification-notice-with-icon .ant-notification-notice-description {
        margin-top: 0.625rem;
      }
    }

    .title {
      font-size: ${FONT_SIZE.xxl};
      height: 3rem;
      margin-left: 1.5rem;
      display: flex;
      align-items: center;
      font-weight: ${FONT_WEIGHT.bold};

      &.title-only {
        color: var(--text-main-color);
        font-size: ${FONT_SIZE.md};
        height: 2rem;
        line-height: 2rem;
        margin-left: 0.75rem;
        font-weight: ${FONT_WEIGHT.semibold};
      }
    }

    .description {
      color: #404040;
      font-size: ${FONT_SIZE.md};
      font-weight: ${FONT_WEIGHT.semibold};
      line-height: 1.375rem;
    }

    &.ant-notification-notice-success {
      border: 1px solid var(--success-color);
      background: var(--notification-success-color);

      .title {
        color: var(--success-color);
      }
    }

    &.ant-notification-notice-info {
      border: 1px solid var(--primary-color);
      background: var(--notification-primary-color);

      .title {
        color: var(--primary-color);
      }
    }

    &.ant-notification-notice-warning {
      border: 1px solid var(--warning-color);
      background: var(--notification-warning-color);

      .title {
        color: var(--warning-color);
      }
    }

    &.ant-notification-notice-error {
      border: 1px solid var(--error-color);
      background: var(--notification-error-color);

      .title {
        color: var(--error-color);
      }
    }

    .success-icon {
      color: var(--success-color);
    }

    .info-icon {
      color: var(--primary-color);
    }

    .warning-icon {
      color: var(--warning-color);
    }

    .error-icon {
      color: var(--error-color);
    }
  }

  .ant-menu-inline, .ant-menu-vertical {
    border-right: 0;
  }

  // notifications end

  .ant-list-item-meta-description {
    color: var(--text-light-color);
  }

  .ant-upload-hint {
    color: var(--text-main-hint-color) !important;
  }

  .ant-form-item-extra {
    color: var(--text-main-hint-color) !important;
  }

  .tlpOptionSelect-Red {
    background: var(--red) !important;
    color: var(--white) !important;
  }

  .tlpOptionSelect-Amber {
    background: var(--amber) !important;
    color: var(--white) !important;
  }

  .tlpOptionSelect-Green {
    background: var(--green) !important;
    color: var(--white) !important;
  }

  .tlpOptionSelect-White {
    background: var(--white) !important;
    color: var(--black) !important;
  }

  .bordered_text {
    color: var(--text-main-color) !important;
    text-shadow: 1px 0 0 var(--background-color),
    -1px 0 0 var(--background-color),
    0 1px 0 var(--background-color),
      0 -1px 0 var(--background-color),
    1px 1px var(--background-color),
      -1px -1px 0 var(--background-color),
      1px -1px 0 var(--background-color),
    -1px 1px 0 var(--background-color);
  }

  .spinning_element {
    animation: spin 1s infinite;
  }

  .ant-timeline.ant-timeline-reverse .ant-timeline-item-last .ant-timeline-item-tail, .ant-tooltip-hidden {
    display: none !important;
  }

  .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background-color: transparent !important;

    .ant-menu-submenu-expand-icon,
    .ant-menu-submenu-arrow,
    span[role='img'],
    .ant-menu-item-icon,
    a {
      color: var(--text-sider-primary-color);
      fill: var(--text-sider-primary-color);
    }

    .ant-menu-submenu-selected {
      .ant-menu-submenu-title {
        color: var(--text-sider-primary-color);

        .ant-menu-submenu-expand-icon,
        .ant-menu-submenu-arrow,
        span[role='img'] {
          color: var(--text-sider-primary-color);
          fill: var(--text-sider-primary-color);
        }
      }
    }
  }

  .ant-card-head {
    min-height: 2vh;
  }

  .ant-card-type-inner .ant-card-extra {
    padding: 0.25rem 0;
  }

  .ant-form-item-with-help .ant-form-item-explain {
    color: var(--text-main-hint-color)
  }

  .ant-tabs-tabpane {
    height: 100%;
  }

  .ant-tabs-content {
    height: 100%;
  }

  .ant-tabs-card > .ant-tabs-nav .ant-tabs-tab, .ant-tabs-card > div > .ant-tabs-nav .ant-tabs-tab {
    border: 1px solid var(--text-main-color);
  }

  .disabled_option_greyscale {
    color: var(--disabled-color);
    filter: grayscale(100%);
    -webkit-filter: grayscale(100%);
  }
  
  .ant-select-arrow, .ant-select-item-option-disabled {
    color: var(--text-main-hint-color);
  }

  /* .ant-picker-input>input, .ant-select, .ant-input, .ant-select-selection-placeholder, .ant-select-item-option-content {
    font-size: ${FONT_SIZE.xl};
  } */

 .ant-tabs-tab {
    font-size: ${FONT_SIZE.xxl};
  }
  
  .ant-list-items {
    font-size: ${FONT_SIZE.lg};
  }
  
  .ant-tabs-tab.ant-tabs-tab-disabled {
    color: var(--disabled-color);
    filter: grayscale(1);
  }

  .rmdp-container {
    width: 100%;
    font-size: ${FONT_SIZE.lg} !important;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: var(--text-main-color);
    font-variant: tabular-nums;
    line-height: 1.5715;
    list-style: none;
    font-feature-settings: 'tnum';
    position: relative;
    display: inline-block;
    cursor: pointer;
  }

  .rmdp-input {
    position: relative;
    background-color: var(--background-color);
    border: 1px solid var(--border-base-color);
    border-radius: 7px;
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    height: 50px;
    padding: 0 11px;
  }

  .ant-empty-normal {
    color: var(--text-main-hint-color);
  }

  tr.ant-table-expanded-row:hover>td, tr.ant-table-expanded-row>td {
    background: transparent;
  }

  .ant-divider-horizontal.ant-divider-with-text {
    border-top-color: var(--text-main-hint-color);
  }

  .ant-drawer-header, .ant-list-split .ant-list-item {
    border-bottom: 1px solid var(--border-table-color);
  }

  .ant-menu-submenu-popup > .ant-menu-sub > .ant-menu-item-disabled {
    color: var(--disabled-color) !important;

  }

  .ant-menu-submenu-disabled > .ant-menu-submenu-title {
    color: var(--text-sider-disabled-color) !important;
    a {
      color: var(--text-sider-disabled-color) !important;
    }
  } 

  .ant-menu-item-disabled {
    color: var(--text-sider-disabled-color) !important;
    a {
      color: var(--text-sider-disabled-color) !important;
    }
  } 
  
  .table-cell-top {
    vertical-align: top;
  }

  .ant-table-filter-trigger:hover {
    color: var(--primary-color)
  }

  .ant-picker-header button {
    color: var(--text-main-hint-color)
  }
  .ant-picker-header button:hover {
    color: var(--primary-color)
  }
  .ant-table-filter-dropdown-search-input .anticon {
  color: var(--text-main-hint-color)
  }
  .ant-btn[disabled]  {
    cursor: not-allowed;
    color: var(--text-main-hint-color)
  }
  .ant-btn[disabled]:hover {
    color: var(--text-main-hint-color)
  }
  .ant-input-password-icon.anticon {
    color: var(--primary-color)
  }
  .ant-input-password-icon.anticon:hover{
    color: var(--text-main-hint-color)
  }
  .ant-form-item-label>label>.anticon {
    color: var(--text-main-hint-color)
  }

  .ant-checkbox-disabled+span {
    color: inherit;
  }
  .ant-checkbox-disabled.ant-checkbox-checked .ant-checkbox-inner::after {
    border-color: var(--disabled-color);
  }
  .ant-btn.ant-btn-circle.ant-btn-default.ant-btn-lg.ant-btn-icon-only.no-advanced-search {
    background-color: var(--primary-color);
    border-color: #ffffff;
    color: #ffffff;
  } 
  .ant-radio-disabled + span {
    color: var(--disabled-color);
  }
  .ant-tooltip .ant-list-item {
  color:${BASE_COLORS.white};
}

`;
