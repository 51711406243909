import { FormTitleWrapper } from '@app/components/common/forms/components/FormTitleWrapper/FormTitleWrapper';
import { Trans, useTranslation } from 'react-i18next';
import { transComponents } from '../HelpContent';
import HelpDashboardGeneral from './HelpDashboardGeneral';
import HelpDashboardSandboxes from './HelpDashboardSandboxes';
import HelpDashboardNetwork from './HelpDashboardNetwork';
import HelpDashboardMITRE from './HelpDashboardMITRE';
import HelpDashboardClientsAndUsers from './HelpDashboardClientsAndUsers';

const HelpDashboard = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'help.dashboard' });

  return (
    <>
      <FormTitleWrapper>
        <h3 id="dashboard">
          <Trans t={t} i18nKey="title" components={transComponents} />
        </h3>
      </FormTitleWrapper>
      <p>
        <Trans t={t} i18nKey="p1" components={transComponents} />
      </p>
      <h4 id="dashboard-image1">IMAGE</h4>
      <h4 id="dashboard-image1">IMAGE</h4>
      <p>
        <Trans t={t} i18nKey="p2" components={transComponents} />
      </p>
      <HelpDashboardGeneral />
      <HelpDashboardSandboxes />
      <HelpDashboardNetwork />
      <HelpDashboardMITRE />
      <HelpDashboardClientsAndUsers />
    </>
  );
};

export default HelpDashboard;
