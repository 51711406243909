import { useCallback, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Image } from 'antd';
import { useTranslation } from 'react-i18next';
import { BaseForm } from '@app/components/common/forms/BaseForm/BaseForm';
import VerifyEmailImage from '@app/assets/images/verify-email.webp';
import * as Auth from '@app/components/layouts/AuthLayout/AuthLayout.styles';
import * as S from './RecoverPassword.styles';
import { Button } from '@app/components/common/buttons/Button/Button';
import { doRecoverPassword } from '@app/store/slices/authSlice';
import { useAppDispatch } from '@app/hooks/reduxHooks';
import { notificationController } from '@app/controllers/notificationController';

export const RecoverPassword = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [isLoading, setLoading] = useState(false);
  const [isConfirm, setIsConfirm] = useState(false);
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();

  const navigateForward = useCallback(() => navigate('/auth/login'), [navigate]);

  const token = searchParams.get('token');

  const handleConfirm = () => {
    setLoading(true);
    setIsConfirm(true);
    dispatch(doRecoverPassword({ token: token }))
      .unwrap()
      .then((res) => console.log(res))
      .catch((err) => {
        notificationController.error({ message: err.message });
      });
  };
  return (
    <Auth.FormWrapper>
      <BaseForm layout="vertical" requiredMark="optional">
        {isConfirm ? (
          <>
            <Auth.BackWrapper onClick={navigateForward}>
              <Auth.BackIcon />
              {t('common.login')}
            </Auth.BackWrapper>
            <S.ContentWrapper>
              <S.ImageWrapper>
                <Image src={VerifyEmailImage} alt="Not found" preview={false} />
              </S.ImageWrapper>
              <Auth.FormTitle>{t('recoverPassword.checkEmail')}</Auth.FormTitle>
              <S.VerifyEmailDescription>{t('recoverPassword.newPasswordSent')}</S.VerifyEmailDescription>
            </S.ContentWrapper>
          </>
        ) : (
          <>
            <Auth.BackWrapper onClick={navigateForward}>
              <Auth.BackIcon />
              {t('common.login')}
            </Auth.BackWrapper>
            <S.ContentWrapper>
              <Auth.FormTitle>{t('recoverPassword.confirmRecover')}</Auth.FormTitle>
              <S.VerifyEmailDescription>{t('recoverPassword.confirmNewPasswordMessage')}</S.VerifyEmailDescription>
              <Button onClick={handleConfirm} loading={isLoading}>
                {t('recoverPassword.confirmNewPassword')}
              </Button>
            </S.ContentWrapper>
          </>
        )}
      </BaseForm>
    </Auth.FormWrapper>
  );
};
