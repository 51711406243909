import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import * as S from './SiderMenuCollapsed.styles';
import { useAppSelector } from '@app/hooks/reduxHooks';
import {
  getSidebarNavigationCollapsedByTheme,
  SidebarNavigationItem,
  SidebarNavigationSeparator,
} from '@app/components/layouts/main/sider/sidebarNavigation';
import { MenuProps } from 'antd';

export interface SiderContentProps {
  setCollapsed: (isCollapsed: boolean) => void;
}

const SiderMenuCollapsed = ({ setCollapsed }: SiderContentProps) => {
  const { t } = useTranslation();
  const location = useLocation();
  const theme = useAppSelector((state) => state.theme.theme);
  const sidebarNavigation = getSidebarNavigationCollapsedByTheme(theme, t);
  const navigate = useNavigate();

  const flatten = (arr: Array<SidebarNavigationItem | SidebarNavigationSeparator>) => {
    let hasChildren = arr.some((item) => 'children' in item && Array.isArray(item.children));
    let result = arr;
    while (hasChildren) {
      result = result.reduce((acc: SidebarNavigationItem[], current) => {
        if ('children' in current && Array.isArray(current.children)) {
          return acc.concat(current.children);
        } else {
          return acc.concat(current as SidebarNavigationItem);
        }
      }, []);

      hasChildren = result.some((item) => 'children' in item && Array.isArray(item.children));
    }
    return result;
  };

  const sidebarNavFlat = flatten(sidebarNavigation);

  const currentMenuItem = sidebarNavFlat.find(
    (item): item is SidebarNavigationItem => 'url' in item && item.url === location?.pathname,
  );

  const defaultSelectedKeys = currentMenuItem ? [currentMenuItem.key] : [];

  const openedSubmenu = sidebarNavigation.find((item) => {
    if ('children' in item) {
      return item.children?.some(({ url }) => url === location?.pathname);
    }
    return false;
  });
  const defaultOpenKeys = openedSubmenu ? ['key' in openedSubmenu ? openedSubmenu.key : ''] : [];

  const onClick: MenuProps['onClick'] = (e) => {
    const itemSelected = sidebarNavFlat.find((item) => 'key' in item && item.key === e.key);
    if (itemSelected && 'url' in itemSelected && itemSelected.url) {
      navigate(itemSelected.url);
    }
  };

  return (
    <S.Menu
      mode="inline"
      defaultSelectedKeys={defaultSelectedKeys}
      // defaultOpenKeys={defaultOpenKeys}
      onClick={onClick}
      // onClick={() => setCollapsed(true)}
      items={sidebarNavigation}
    />
  );
};

export default SiderMenuCollapsed;
