import { Trans, useTranslation } from 'react-i18next';
import { transComponents } from '../HelpContent';
import { MdBugReport, MdSave, MdSecurity } from 'react-icons/md';

const HelpSampleMetrics = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'help.analyses.status' });

  return (
    <>
      <h4 id="sampleStatus">
        <Trans t={t} i18nKey="title" components={transComponents} />
      </h4>
      <p>
        <Trans t={t} i18nKey="p1" components={transComponents} />
      </p>
      <h5 id="status-image1">IMAGE</h5>
      <p>
        <Trans t={t} i18nKey="p2" components={transComponents} />
      </p>
      <h5 id="status-image2">IMAGE</h5>
      <p>
        <Trans t={t} i18nKey="p3" components={transComponents} />
      </p>
      <ul className="no-dots">
        <li>
          <span className="highlighted--completed">
            <MdSecurity className="icon" />
            <Trans t={t} i18nKey="ul1.li1" components={transComponents} />
          </span>
          <ul>
            <li>
              <Trans t={t} i18nKey="ul1.li1-1" components={transComponents} />
            </li>
          </ul>
        </li>
        <li>
          <span className="highlighted--analyzed">
            <MdSecurity className="icon" />
            <Trans t={t} i18nKey="ul1.li2" components={transComponents} />
          </span>
          <ul>
            <li>
              <Trans t={t} i18nKey="ul1.li2-1" components={transComponents} />
            </li>
          </ul>
        </li>
        <li>
          <span className="highlighted--analyzing">
            <MdSecurity className="icon" />
            <Trans t={t} i18nKey="ul1.li3" components={transComponents} />
          </span>
          <ul>
            <li>
              <Trans t={t} i18nKey="ul1.li3-1" components={transComponents} />
            </li>
          </ul>
        </li>
        <li>
          <span className="highlighted--uploaded">
            <MdSecurity className="icon" />
            <Trans t={t} i18nKey="ul1.li4" components={transComponents} />
          </span>
          <ul>
            <li>
              <Trans t={t} i18nKey="ul1.li4-1" components={transComponents} />
            </li>
          </ul>
        </li>
        <li>
          <span className="highlighted--error">
            <MdSecurity className="icon" />
            <Trans t={t} i18nKey="ul1.li5" components={transComponents} />
          </span>
          <ul>
            <li>
              <Trans t={t} i18nKey="ul1.li5-1" components={transComponents} />
            </li>
          </ul>
        </li>
        <li>
          <span className="highlighted--not-processed">
            <MdSecurity className="icon" />
            <Trans t={t} i18nKey="ul1.li6" components={transComponents} />
          </span>
          <ul>
            <li>
              <Trans t={t} i18nKey="ul1.li6-1" components={transComponents} />
            </li>
          </ul>
        </li>
      </ul>
      <p>
        <Trans t={t} i18nKey="p4" components={transComponents} />
      </p>
      <h5 id="status-image3">IMAGE</h5>
      <p>
        <Trans t={t} i18nKey="p5" components={transComponents} />
      </p>
      <h5 id="status-image4">IMAGE</h5>
      <p>
        <Trans t={t} i18nKey="p6" components={transComponents} />
      </p>
      <h5 id="status-image5">IMAGE</h5>
      <p>
        <Trans t={t} i18nKey="p7" components={transComponents} />
      </p>
      <h5 id="status-image6">IMAGE</h5>
      <p>
        <Trans t={t} i18nKey="p8" components={transComponents} />
      </p>
      <h5 id="status-image7">IMAGE</h5>
      <p>
        <Trans t={t} i18nKey="p9" components={transComponents} />
      </p>
      <h5 id="status-image8">IMAGE</h5>
      <p>
        <Trans t={t} i18nKey="p10" components={transComponents} />
      </p>
      <h5 id="status-video1">VIDEO</h5>
    </>
  );
};

export default HelpSampleMetrics;
