import { FormTitleWrapper } from '@app/components/common/forms/components/FormTitleWrapper/FormTitleWrapper';
import { Trans, useTranslation } from 'react-i18next';
import { transComponents } from '../HelpContent';

const HelpManagementSystemConf = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'help.management.systemConfiguration' });

  return (
    <>
      <FormTitleWrapper>
        <h4 id="systemConfiguration">
          <Trans t={t} i18nKey="title" components={transComponents} />
        </h4>
      </FormTitleWrapper>
      <p>
        <Trans t={t} i18nKey="p1" components={transComponents} />
      </p>
    </>
  );
};

export default HelpManagementSystemConf;
