import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { Image } from 'antd';
import { useTranslation } from 'react-i18next';
import { BaseForm } from '@app/components/common/forms/BaseForm/BaseForm';
import VerifyEmailImage from '@app/assets/images/verify-email.webp';
import * as Auth from '@app/components/layouts/AuthLayout/AuthLayout.styles';
import * as S from './RecoverPassword.styles';

export const EmailFound = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const navigateForward = useCallback(() => navigate('/auth/login'), [navigate]);

  return (
    <Auth.FormWrapper>
      <BaseForm layout="vertical" requiredMark="optional">
        <Auth.BackWrapper onClick={navigateForward}>
          <Auth.BackIcon />
          {t('common.login')}
        </Auth.BackWrapper>
        <S.ContentWrapper>
          <S.ImageWrapper>
            <Image src={VerifyEmailImage} alt="Not found" preview={false} />
          </S.ImageWrapper>
          <Auth.FormTitle>{t('recoverPassword.checkEmail')}</Auth.FormTitle>
          <S.VerifyEmailDescription>{t('forgotPassword.successReset')}</S.VerifyEmailDescription>
        </S.ContentWrapper>
      </BaseForm>
    </Auth.FormWrapper>
  );
};
