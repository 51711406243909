import { MenuProps } from 'antd';
import React from 'react';

export type HeaderBreadcrumb = {
  label: string;
  key: string;
  href: string;
  menu: MenuProps;
};
interface InitialHeaderContext {
  headerBreadcrumb: HeaderBreadcrumb[];
  setHeaderBreadcrumb: React.Dispatch<React.SetStateAction<HeaderBreadcrumb[]>>;
  headerNavigation: React.ReactNode;
  setHeaderNavigation: React.Dispatch<React.SetStateAction<React.ReactNode>>;
  headerKeyReset: number;
}
// TODO: Inicializarlo a null y hacer lo de abajo.
// TODO: En vez de utilizar la aserción, crear un hook que use el contexto con useContext y devuelva las propiedades, manejando la comprobación de si es null o no

const HeaderContext = React.createContext<InitialHeaderContext>({} as InitialHeaderContext);

export default HeaderContext;
