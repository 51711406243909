import { FormTitleWrapper } from '@app/components/common/forms/components/FormTitleWrapper/FormTitleWrapper';
import { Trans, useTranslation } from 'react-i18next';
import { transComponents } from '../HelpContent';

const HelpDashboardClientsAndUsers = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'help.dashboardClientsAndUsers' });

  return (
    <>
      <FormTitleWrapper>
        <h4 id="dashboardClientsAndUsers">
          <Trans t={t} i18nKey="title" components={transComponents} />
        </h4>
      </FormTitleWrapper>
      <p>
        <Trans t={t} i18nKey="p1" components={transComponents} />
      </p>
      <h5 id="dashboardClientsAndUsers-image1">IMAGE</h5>
      <p>
        <Trans t={t} i18nKey="p2" components={transComponents} />
      </p>
      <h5 id="dashboardClientsAndUsers-image2">IMAGE</h5>
      <p>
        <Trans t={t} i18nKey="p3" components={transComponents} />
      </p>
      <h5 id="dashboardClientsAndUsers-image3">IMAGE</h5>
      <p>
        <Trans t={t} i18nKey="p4" components={transComponents} />
      </p>
      <h5 id="dashboardClientsAndUsers-image4">IMAGE</h5>
      <p>
        <Trans t={t} i18nKey="p5" components={transComponents} />
      </p>
      <h5 id="dashboardClientsAndUsers-image5">IMAGE</h5>
      <p>
        <Trans t={t} i18nKey="p6" components={transComponents} />
      </p>
      <h5 id="dashboardClientsAndUsers-image6">IMAGE</h5>
      <p>
        <Trans t={t} i18nKey="p7" components={transComponents} />
      </p>
      <h5 id="dashboardClientsAndUsers-image7">IMAGE</h5>
      <p>
        <Trans t={t} i18nKey="p8" components={transComponents} />
      </p>
      <h5 id="dashboardClientsAndUsers-image8">IMAGE</h5>
      <p>
        <Trans t={t} i18nKey="p9" components={transComponents} />
      </p>
      <h5 id="dashboardClientsAndUsers-image9">IMAGE</h5>
    </>
  );
};

export default HelpDashboardClientsAndUsers;
