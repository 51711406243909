import { FormTitleWrapper } from '@app/components/common/forms/components/FormTitleWrapper/FormTitleWrapper';
import { Trans, useTranslation } from 'react-i18next';
import { transComponents } from './HelpContent';
import { MdErrorOutline } from 'react-icons/md';

const HelpUserManual = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'help.userManual' });

  return (
    <>
      <FormTitleWrapper>
        <h2 id="userManual">
          <Trans t={t} i18nKey="title" components={transComponents} />
        </h2>
      </FormTitleWrapper>
      <p>
        <MdErrorOutline className="icon" />
        <Trans t={t} i18nKey="p1" components={transComponents} />
      </p>
      <p>
        <Trans t={t} i18nKey="p2" components={transComponents} />
      </p>
      <blockquote>
        <p>
          <Trans t={t} i18nKey="p3" components={transComponents} />
        </p>
        <h3 id="image1">IMAGE</h3>
      </blockquote>
    </>
  );
};

export default HelpUserManual;
