import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom';
import { useRsightSandboxesAvailable } from '@app/api/rsight/analysis/hooks/useRsightSandboxesAvailable';
import { useRsightSandboxesScoresBySampleId } from '@app/api/rsight/analysis/hooks/useRsightSandboxesScoresBySampleId';
import { useRsightJsonBySampleId } from '@app/api/rsight/analysis/hooks/useRsightJsonBySampleId';
import { hasSampleDynamic, hasSampleSandboxName, hasSampleTraceData } from '@app/utils/ravensightVerdict';
import { useEffect } from 'react';
import { useAppDispatch } from '@app/hooks/reduxHooks';
import { changeExtraKey } from '@app/store/slices/analysisSlice';

export const CheckRoute = () => {
  const { sampleId } = useParams();
  const navigate = useNavigate();
  const { data: sandboxesNames } = useRsightSandboxesAvailable();
  const { data: scoresList, isLoading: isLoadingScores } = useRsightSandboxesScoresBySampleId(sampleId);
  const {
    data: { jsonSample },
    isLoading: isLoadingJson,
  } = useRsightJsonBySampleId(sampleId);

  const dispatch = useAppDispatch();
  const { pathname } = useLocation();
  const path = `/rsight/analyses/${sampleId}`;
  const pathKey = pathname.split('/')[4];

  useEffect(() => {
    const verifyRoute = () => {
      let isAccesible = true;
      const verifyDynamic = hasSampleDynamic(sandboxesNames, scoresList) && pathKey.includes('dynamic');
      const verifyStatic = hasSampleSandboxName('STATICANALYSIS', scoresList) && pathKey.includes('static');
      const verifyIntelligence = hasSampleTraceData(jsonSample) && pathKey.includes('intelligence');
      isAccesible = verifyDynamic || verifyStatic || verifyIntelligence;

      return isAccesible;
    };
    if (!isLoadingJson && !!jsonSample && !isLoadingScores) {
      if (pathname.includes('principal')) {
        return navigate(pathname);
      } else if (!verifyRoute() && sandboxesNames && scoresList && jsonSample) {
        return navigate(path);
      } else {
        dispatch(changeExtraKey(verifyRoute() ? pathKey : 'principal'));
        return navigate(pathname);
      }
    }
  }, [pathname, sandboxesNames, scoresList, jsonSample, isLoadingJson, isLoadingScores]);

  return <Outlet></Outlet>;
};
