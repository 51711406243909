import { Trans, useTranslation } from 'react-i18next';
import { transComponents } from '../HelpContent';

const HelpExecutiveReportPrincipal = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'help.executiveReportPrincipal' });

  return (
    <>
      <h5 id="executiveReportPrincipal">
        <Trans t={t} i18nKey="title" components={transComponents} />
      </h5>
      <p>
        <Trans t={t} i18nKey="p1" components={transComponents} />
      </p>
      <h6 id="executiveReportPrincipal-image1">IMAGE</h6>
      <p>
        <Trans t={t} i18nKey="p2" components={transComponents} />
      </p>
      <h6 id="executiveReportPrincipal-image2">IMAGE</h6>
      <p>
        <Trans t={t} i18nKey="p3" components={transComponents} />
      </p>
      <h6 id="executiveReportPrincipal-image3">IMAGE</h6>
      <p>
        <Trans t={t} i18nKey="p4" components={transComponents} />
      </p>
      <h6 id="executiveReportPrincipal-image4">IMAGE</h6>
      <p>
        <Trans t={t} i18nKey="p5" components={transComponents} />
      </p>
      <h6 id="executiveReportPrincipal-image5">IMAGE</h6>
    </>
  );
};

export default HelpExecutiveReportPrincipal;
