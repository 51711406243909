import React, { useRef } from 'react';
import { CardProps as AntCardProps } from 'antd';
import { defaultPaddings } from '@app/constants/defaultPaddings';
import { useResponsive } from '@app/hooks/useResponsive';
import * as S from './Card.styles';
import { HelpIcon } from '@app/components/common/HelpIcon/HelpIcon';
import { FullScreen, useFullScreenHandle } from 'react-full-screen';
import { FullScreenExtraIcon } from '@app/components/common/FullScreenExtraIcon/FullScreenExtraIcon';
import { FONT_SIZE_VH as FONT_SIZE } from '@app/styles/themes/constants';
import { RequireFullscreen } from '@app/components/common/RequireFullscreen/RequireFullscreen';
import { HeaderActionWrapper } from '@app/components/header/Header.styles';
import { Button } from '@app/components/common/buttons/Button/Button';
import { FullscreenExitOutlined, FullscreenOutlined } from '@ant-design/icons';
import { FullScreenButton } from './Card.styles';

export interface CardProps extends AntCardProps {
  className?: string;
  padding?: string | number | [number, number];
  autoHeight?: boolean;
}

export const Card: React.FC<CardProps> = ({
  className,
  padding,
  size,
  fontSizeTitle,
  fontSizeText,
  helpText,
  typeHelpText,
  fullScreen,
  autoHeight = true,
  children,
  ...props
}) => {
  const { isTablet, isDesktop } = useResponsive();
  const screen1 = useFullScreenHandle();
  const rootRef = useRef(null);

  const CardComponent = (
    <S.Card
      ref={rootRef}
      size={size ? size : isTablet ? 'default' : 'small'}
      className={fullScreen ? (className ? className + ' graphIOC-fullScreen' : 'graphIOC-fullScreen') : className}
      bordered={false}
      $padding={padding || padding === 0 ? padding : '1vh'}
      $fontSizeTitle={fontSizeTitle}
      $fontSizeText={fontSizeText}
      $autoHeight={autoHeight}
      extra={
        helpText ? (
          <HelpIcon message={helpText} title={props.title} type={typeHelpText ? typeHelpText : 'tooltip'} />
        ) : fullScreen ? (
          // <FullScreenExtraIcon screen={screen1} />
          <RequireFullscreen component={rootRef}>
            {(isFullscreen) => (
              <S.FullScreenButton>
                <Button
                  type={isFullscreen ? 'ghost' : 'text'}
                  icon={isFullscreen ? <FullscreenExitOutlined /> : <FullscreenOutlined />}
                />
              </S.FullScreenButton>
            )}
          </RequireFullscreen>
        ) : (
          props.extra
        )
      }
      {...props}
    >
      {React.isValidElement(children) && fullScreen
        ? React.cloneElement(children, { isFullScreen: screen1?.active })
        : children}
    </S.Card>
  );

  return <>{CardComponent}</>;
};
