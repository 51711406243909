import React from 'react';
import { Row, Col, Avatar, Image, Tooltip } from 'antd';
import { getIconPlatformByName } from '@app/utils/platformsIcon';
import { getLogoPlatformByName } from '@app/utils/platformsLogo';
import { EmptyDefaultTag } from '@app/components/common/TagsRavenloop/EmptyDefaultTag';
import { useTranslation } from 'react-i18next';
import { EmptyDefaultTagNeon } from '@app/components/common/TagNeon/EmptyDefaultTagNeon';

export const PlatformsLogo: React.FC = ({ platformsList, platformName, widthIcon, widthLogo }) => {
  const { t } = useTranslation();

  const displayPlatformIcon = (platformName: string) => {
    const srcImageIconPlatform = getIconPlatformByName(platformName);
    return <Avatar src={<Image preview={false} src={srcImageIconPlatform} width={widthIcon} />} />;
  };

  if (platformsList && Array.isArray(platformsList) && platformsList.length > 1) {
    return (
      <Row
        gutter={[5, 5]}
        // justify="space-around"
        align="middle"
      >
        {platformsList.map((platformName) => (
          <Col key={platformName}>
            <Tooltip destroyTooltipOnHide={true} placement="top" trigger="hover" title={platformName}>
              {displayPlatformIcon(platformName)}
            </Tooltip>
          </Col>
        ))}
      </Row>
    );
  } else if (platformName && platformName !== '') {
    return (
      // <Row gutter={[10, 10]} justify="space-around" align="middle">
      <Image preview={false} src={getLogoPlatformByName(platformName)} width={widthLogo} />
      // </Row>
    );
  } else if (platformsList && Array.isArray(platformsList) && platformsList.length == 1) {
    return (
      // <Row gutter={[10, 10]} justify="space-around" align="middle">
      <Image preview={false} src={getLogoPlatformByName(platformsList[0])} width={widthLogo} />
      // </Row>
    );
  } else {
    // return <EmptyDefaultTag title={t("common.noPlatform")}/>
    return (
      <EmptyDefaultTagNeon
        otherStyle={{
          fontWeight: 'bold',
          textOverflow: 'hidden',
          whiteSpace: 'nowrap',
        }}
        $radius={'square'}
        title={t('common.noPlatform')}
        width="100%"
        bgColor
      />
    );
  }
};
