import { FormTitleWrapper } from '@app/components/common/forms/components/FormTitleWrapper/FormTitleWrapper';
import { Trans, useTranslation } from 'react-i18next';
import { transComponents } from '../HelpContent';

const HelpDashboardGeneral = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'help.dashboardGeneral' });

  return (
    <>
      <FormTitleWrapper>
        <h4 id="dashboardGeneral">
          <Trans t={t} i18nKey="title" components={transComponents} />
        </h4>
      </FormTitleWrapper>
      <p>
        <Trans t={t} i18nKey="p1" components={transComponents} />
      </p>
      <h5 id="dashboardGeneral-video1">VIDEO</h5>
      <p>
        <Trans t={t} i18nKey="p2" components={transComponents} />
      </p>
      <h5 id="dashboardGeneral-image1">IMAGE</h5>
      <p>
        <Trans t={t} i18nKey="p3" components={transComponents} />
      </p>
      <h5 id="dashboardGeneral-image2">IMAGE</h5>
      <p>
        <Trans t={t} i18nKey="p4" components={transComponents} />
      </p>
      <h5 id="dashboardGeneral-image3">IMAGE</h5>
      <p>
        <Trans t={t} i18nKey="p5" components={transComponents} />
      </p>
      <h5 id="dashboardGeneral-image4">IMAGE</h5>
      <p>
        <Trans t={t} i18nKey="p6" components={transComponents} />
      </p>
      <h5 id="dashboardGeneral-image5">IMAGE</h5>
      <p>
        <Trans t={t} i18nKey="p7" components={transComponents} />
      </p>
      <h5 id="dashboardGeneral-image6">IMAGE</h5>
      <p>
        <Trans t={t} i18nKey="p8" components={transComponents} />
      </p>
      <h5 id="dashboardGeneral-image7">IMAGE</h5>
      <p>
        <Trans t={t} i18nKey="p9" components={transComponents} />
      </p>
      <h5 id="dashboardGeneral-image8">IMAGE</h5>
      <p>
        <Trans t={t} i18nKey="p10" components={transComponents} />
      </p>
      <h5 id="dashboardGeneral-image9">IMAGE</h5>
      <p>
        <Trans t={t} i18nKey="p11" components={transComponents} />
      </p>
      <h5 id="dashboardGeneral-image10">IMAGE</h5>
      <p>
        <Trans t={t} i18nKey="p12" components={transComponents} />
      </p>
      <h5 id="dashboardGeneral-image11">IMAGE</h5>
      <p>
        <Trans t={t} i18nKey="p13" components={transComponents} />
      </p>
      <h5 id="dashboardGeneral-image12">IMAGE</h5>
      <p>
        <Trans t={t} i18nKey="p14" components={transComponents} />
      </p>
      <h5 id="dashboardGeneral-image13">IMAGE</h5>
    </>
  );
};

export default HelpDashboardGeneral;
