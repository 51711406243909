export enum UserRoles {
  USER = 'user',
  ADMIN = 'admin',
  SUPERADMIN = 'superadmin',
}

export const userRoles = {
  user: UserRoles.USER,
  admin: UserRoles.ADMIN,
  superadmin: UserRoles.SUPERADMIN,
};
