import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { BaseForm } from '@app/components/common/forms/BaseForm/BaseForm';
import * as S from './ForgotPasswordForm.styles';
import * as Auth from '@app/components/layouts/AuthLayout/AuthLayout.styles';
import { useAppDispatch } from '@app/hooks/reduxHooks';
import { doResetPassword } from '@app/store/slices/authSlice';
import { notificationController } from '@app/controllers/notificationController';
import { TitleLoginStyle } from '@app/styles/TitleLogin';
import { useCaptcha } from '../LoginForm/hooks/useCaptcha';
import { LoginCaptcha } from '../LoginForm/LoginCaptcha';
import { LoginFormData } from '@app/interfaces/loginInterfaces';
import LogosComponent from '@app/utils/LogosComponent';

interface ForgotPasswordFormData {
  uemail: string;
  recover: boolean;
  'captcha-hash': string;
  'captcha-text': string;
}

export const ForgotPasswordForm = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [isLoading, setLoading] = useState(false);
  const [email, setEmail] = useState('');
  const { captchaData, newCaptcha } = useCaptcha();

  const handleNavigate = (res: { error: string } | undefined) => {
    if (res?.error == 'Email was sent') {
      setLoading(false);
      return navigate('/auth/email-found');
    }
  };

  const handleLogin = (values: ForgotPasswordFormData) => {
    setLoading(true);
    dispatch(doResetPassword(values))
      .unwrap()
      .then((res) => handleNavigate(res))
      .catch((err) => {
        notificationController.error({ message: err.message });
        setLoading(false);
        navigate('/auth/email-not-found');
      });
  };

  const handleSubmit = (values: LoginFormData) => {
    if (!captchaData) {
      newCaptcha();
    } else {
      handleLogin({
        ...values,
        'captcha-hash': captchaData.hash,
        'captcha-text': captchaData.img,
        uemail: email,
        recover: true,
      });
    }
  };

  return (
    <Auth.FormWrapper>
      <TitleLoginStyle>
        <LogosComponent />
      </TitleLoginStyle>
      <BaseForm layout="vertical" onFinish={handleSubmit} requiredMark="optional">
        <Auth.BackWrapper onClick={() => navigate(-1)}>
          <Auth.BackIcon />
          {t('common.back')}
        </Auth.BackWrapper>
        <Auth.FormTitle>{t('common.resetPassword')}</Auth.FormTitle>
        <S.Description>{t('forgotPassword.description')}</S.Description>
        <Auth.FormItem
          name="uemail"
          label={t('common.email')}
          rules={[{ required: true, type: 'email', message: t('common.notValidEmail') }]}
          onChange={(e: any) => setEmail(e.target.value)}
        >
          <Auth.FormInput placeholder={'chris.johnson@ravenloop.io'} />
        </Auth.FormItem>
        {captchaData && <LoginCaptcha captchaData={captchaData} />}
        <BaseForm.Item noStyle>
          <S.SubmitButton type="primary" htmlType="submit" loading={isLoading}>
            {t('forgotPassword.sendInstructions')}
          </S.SubmitButton>
        </BaseForm.Item>
      </BaseForm>
    </Auth.FormWrapper>
  );
};
