import { FormTitleWrapper } from '@app/components/common/forms/components/FormTitleWrapper/FormTitleWrapper';
import { Trans, useTranslation } from 'react-i18next';
import { transComponents } from '../HelpContent';
import HelpManagementAccess from './HelpManagementAccess';
import HelpManagementClients from './HelpManagementClients';
import HelpManagementGroups from './HelpManagementGroups';
import HelpManagementUsers from './HelpManagementUsers';
import HelpManagementClientConf from './HelpManagementClientConf';
import HelpManagementUserConf from './HelpManagementUserConf';
import HelpManagementSystemConf from './HelpManagementSystemConf';

const HelpManagement = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'help.management' });

  return (
    <>
      <FormTitleWrapper>
        <h3 id="management">
          <Trans t={t} i18nKey="title" components={transComponents} />
        </h3>
      </FormTitleWrapper>
      <HelpManagementAccess />
      <HelpManagementClients />
      <HelpManagementGroups />
      <HelpManagementUsers />
      <HelpManagementClientConf />
      <HelpManagementUserConf />
      <HelpManagementSystemConf />
    </>
  );
};

export default HelpManagement;
