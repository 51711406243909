import axios from 'axios';
import { AxiosError } from 'axios';
import { ApiError } from '@app/api/ApiError';
import { readClientId, readToken } from '@app/services/localStorage.service';

export interface ApiErrorData {
  status?: number;
  message: string;
}

// JESUS: ya se obtiene la URL BASE API desde el fichero: .env.development
// pero si se desea usar otra url temporal (como localhost), cambiar mejor desde aqui, ya que
// si se cambia en el .env.development, es necesario tambien reiniciar el servicio web para actualizar los cambios
export const httpApi = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,

//   baseURL: 'http://127.0.0.1:8080/api/',
});

export const rloopApi = axios.create({
  baseURL: process.env.REACT_APP_NORMALIZER_URL,
});

export const datalakeIp = process.env.REACT_APP_DATALAKE_IP;

rloopApi.interceptors.request.use((config) => {
  config.headers = { ...config.headers, 'X-API-TOKEN': `${readToken()}` };
  config.params = { ...config.params, client_id: readClientId() };
  return config;
});

httpApi.interceptors.request.use((config) => {
  config.headers = { ...config.headers, 'X-API-TOKEN': `${readToken()}` };
  config.params = { ...config.params, client_id: readClientId() };
  return config;
});

httpApi.interceptors.response.use(
  (response) => response,
  (error: AxiosError) => {
    if (error?.response?.data?.errors) {
      if (error?.response?.status === 401 && error.response.data.errors === 'unauthorized') {
        console.log('Unauthorized user, going to login...');
        window.location.href = '/auth/login';
      }
    }
    throw new ApiError<ApiErrorData>(error.response?.data.message || error.message, {
      status: error.response?.status,
      message: error.response?.data,
    });
  },
);

export const storageApi = axios.create({
  baseURL: process.env.REACT_APP_STORAGE_URL,
  // baseURL: 'http://127.0.0.1:5000/api/',
});
storageApi.interceptors.request.use((config) => {
  config.headers = { ...config.headers, 'X-API-TOKEN': `${readToken()}` };
  config.params = { ...config.params, client_id: readClientId() };
  return config;
});
storageApi.interceptors.response.use(
  (response) => response,
  (error: AxiosError) => {
    // console.log(error);
    if (error?.response?.data?.errors) {
      if (error?.response?.status === 401 && error.response.data.errors === 'unauthorized') {
        console.log('Unauthorized user, going to login...');
        window.location.href = '/auth/login';
      }
    }
    throw new ApiError<ApiErrorData>(error.response?.data.message || error.message, {
      status: error.response?.status,
      message: error.response?.data,
    });
  },
);
