import { withAccess } from '@app/hocs/withAccess';
import { withLoading } from '@app/hocs/withLoading.hoc';
import { lazy } from 'react';

const AuditContextLayout = lazy(() => import('@app/pages/DashboardPages/Ravenscout/AuditContextLayout'));
const RavenscoutAuditsListPage = lazy(() => import('@app/pages/DashboardPages/Ravenscout/RavenscoutAuditsListPage'));
const RavenscoutDashboardPage = lazy(() => import('@app/pages/DashboardPages/Ravenscout/RavenscoutDashboardPage'));
const AuditElementPage = lazy(() => import('@app/pages/Audit/AuditElementPage'));
const AssetElementPage = lazy(() => import('@app/pages/Asset/AssetElementPage'));
const AuditAssetVulnerabilityElementPage = lazy(() => import('@app/pages/Asset/AuditAssetVulnerabilityElementPage'));
const AuditAssetLayout = lazy(() => import('@app/pages/DashboardPages/Ravenscout/AuditAssetLayout'));

const RavenscoutDashboard = withAccess(RavenscoutDashboardPage);
const AuditElement = withAccess(AuditElementPage);
const AssetElement = withAccess(AssetElementPage);
const AuditAssetVulnerabilityElement = withAccess(AuditAssetVulnerabilityElementPage);
const AuditContextLayoutFallback = withLoading(AuditContextLayout);
const AuditaAssetLayoutFallback = withLoading(AuditAssetLayout);

const rscoutRouter = [
  {
    path: '',
    element: <RavenscoutDashboard />,
  },
  {
    path: 'audits',
    element: <RavenscoutAuditsListPage />,
  },
  {
    path: 'audit/:auditId',
    element: <AuditContextLayoutFallback />,
    children: [
      {
        path: '',
        element: <AuditElement />,
      },
      {
        path: 'assets/:assetId',
        element: <AuditaAssetLayoutFallback />,
        children: [
          {
            path: '',
            element: <AssetElement />,
          },
          {
            path: 'vulnerability/:vulnerabilityId', //TODO: vulnerabilities en plural
            element: <AuditAssetVulnerabilityElement />,
          },
        ],
      },
    ],
  },
];

export default rscoutRouter;
