import { Dropdown } from '@app/components/common/Dropdown/Dropdown';
import { MenuProps, Image } from 'antd';
import { DownOutlined, UpOutlined } from '@ant-design/icons';
import { Button } from '@app/components/common/buttons/Button/Button';
import styled from 'styled-components';
import { useAppDispatch, useAppSelector } from '@app/hooks/reduxHooks';
import { selectUserClients, selectUserData, setUser } from '@app/store/slices/userSlice';
import { useTranslation } from 'react-i18next';
import { ClientUser } from '@app/domain/UserModel';
import { ClientLogo } from '@app/components/header/components/ClientDropdown/ClientLogo';
import { SiderSelectorDiv } from '@app/components/layouts/main/sider/MainSider/MainSider.styles';
import { useState } from 'react';
import { useResponsive } from '@app/hooks/useResponsive';

const StyledDropdown = styled(Dropdown)`
  overflow: hidden;
  .ant-dropdown-menu {
    max-height: 40vh;
    overflow: hidden auto;
  }
  .ant-btn {
    margin: 0 auto;
    > span {
      max-width: 400px;
      white-space: break-spaces;
    }
  }
`;

interface SiderLogoProps {
  isSiderCollapsed: boolean;
}

export const ClientDropdown: React.FC<SiderLogoProps> = ({ isSiderCollapsed }) => {
  const [isClicked, setIsClicked] = useState(false);
  const { mobileOnly } = useResponsive();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const user = useAppSelector(selectUserData);
  const allClients = useAppSelector(selectUserClients);
  const items: MenuProps['items'] = [];

  const showSelectedClient = (client?: ClientUser) => {
    if (!client) return t('common.noClient');
    return client.IMAGE ? (
      <Image src={`data:image/png;base64, ${client.IMAGE}`} height={'3rem'} preview={false} />
    ) : (
      client.CLIENT
    );
  };

  const getDropdownItems = () => {
    allClients?.forEach((client) => {
      if (user && client.CLIENT_ID === user?.cliente_id) return;
      const labelContent = showSelectedClient(client);

      items.push({
        label: (
          <Button noStyle onClick={() => handleChange(client.CLIENT_ID)} style={{ width: '100%' }}>
            {labelContent}
          </Button>
        ),
        key: client.CLIENT_ID,
      });
    });
    return items;
  };

  const saveCurrentClient = (clientId: number) => {
    const client = allClients?.find((client) => client.CLIENT_ID === clientId);

    if (user && client) {
      // Shallow copy. User is readonly.
      const newUser = { ...user };
      newUser.cliente_id = client.CLIENT_ID;
      newUser.cliente_usuario = client;
      dispatch(setUser(newUser));
    }
  };

  const handleChange = (clientId: number) => {
    saveCurrentClient(clientId);

    window.location.reload();
  };

  const upOutlinedHandle = () => {
    setIsClicked(!isClicked);
  };

  return (
    <StyledDropdown menu={{ items: getDropdownItems() }} trigger={['click']}>
      {/* <S.ProfileDropdownHeader as={Row} gutter={[10, 10]} align="middle">
        <Col>
          <H6> */}

      <SiderSelectorDiv onClick={upOutlinedHandle}>
        <ClientLogo client={user?.cliente_usuario} isSiderCollapsed={isSiderCollapsed} />
        {mobileOnly ? (
          <DownOutlined rotate={isClicked ? 180 : 0} height={'10rem'} />
        ) : (
          <UpOutlined rotate={isClicked ? 180 : 0} height={'10rem'} />
        )}
      </SiderSelectorDiv>

      {/* {showSelectedClient(user?.cliente_usuario)} <DownOutlined /> */}
      {/* </H6>
        </Col>
      </S.ProfileDropdownHeader> */}
    </StyledDropdown>
  );
};
