import { UserModel } from '@app/domain/UserModel';
import { getPlatformByPathname } from '@app/utils/utils';
import { Notification } from '@app/api/notifications.api';
import { VmStateInterface } from '@app/components/context/VmProvider';

const avatarImg = '/logos/logo-raven.png';

export const persistToken = (token: string, expires: string, module: string): void => {
  localStorage.setItem('accessToken', token);
  localStorage.setItem('expiresToken', expires);
  localStorage.setItem('currentModule', module);
};

export const readToken = (): string | null => {
  return localStorage.getItem('accessToken') || null;
};

export const readExpiresToken = (): string | null => {
  return localStorage.getItem('expiresToken') || null;
};

export const readCurrentModule = (): string | null => {
  return localStorage.getItem('currentModule') || null;
};

export const persistUser = (user: UserModel | null): void => {
  localStorage.setItem('user', JSON.stringify(user));
};

export const readUser = (): UserModel | null => {
  const userStr = localStorage.getItem('user');
  try {
    return userStr ? JSON.parse(userStr) : null;
  } catch (error) {
    console.log(error);
  }
  return null;
};

export const readUsername = (): string | null => {
  const userData = readUser();
  if (userData) {
    return userData.username;
  }
  return null;
};

export const readLanguage = (): string | null => {
  const user = readUser();
  return user?.lng || null;
};

export const readClientName = (): string | null => {
  const userData = readUser();
  if (userData) {
    return userData?.cliente_usuario?.CLIENT;
  }
  return null;
};

export const readClientId = (): number | null => {
  const userData = readUser();
  if (userData) {
    return userData?.cliente_id;
  }
  return null;
};

export const isUserSuperadmin = (): boolean => {
  let result = false;
  const userData = readUser();
  if (userData) {
    const role = userData['user_type'];
    if (role == 'superadmin') {
      result = true;
    }
  }
  return result;
};

export const isUserAdmin = (): boolean => {
  let result = false;
  const userData = readUser();
  if (userData) {
    const role = userData['user_type'];
    if (role == 'admin' || role == 'superadmin') {
      result = true;
    }
  }
  return result;
};

export const setCurrentPlatformByPathname = (pathname: string): string | null => {
  const userData = readUser();
  const platform = getPlatformByPathname(pathname);
  if (userData && platform) {
    userData['current_module'] = platform;
    persistUser(userData);
  }
  return platform;
};

export const setCurrentPlatform = (platform: string): void => {
  const userData = readUser();
  if (userData) {
    userData['current_module'] = platform;
  }
  persistUser(userData);
};

export const getCurrentPlatform = (): string | null => {
  let result = null;
  const userData = readUser();
  if (userData) {
    result = userData['current_module'];
  }
  return result;
};

export const deleteToken = (): void => localStorage.removeItem('accessToken');
export const deleteExpiresToken = (): void => localStorage.removeItem('expiresToken');
export const deleteUser = (): void => localStorage.removeItem('user');

export const addPersistNotificationToLocalStorage = (notification: Notification): void => {
  let notificationsString = localStorage.getItem('notifications');
  let notifications = [];
  if (notificationsString) {
    notifications = JSON.parse(notificationsString);
  }
  notifications.push(notification);
  localStorage.setItem('notifications', JSON.stringify(notifications));
  window.dispatchEvent(new Event('storage'));
};

export const setPersistNotificationToLocalStorage = (notifications: Notification[]): void => {
  localStorage.setItem('notifications', JSON.stringify(notifications));
  window.dispatchEvent(new Event('storage'));
};

export const getNotificationsLocalStorage = (): Notification[] | null => {
  const notifications = localStorage.getItem('notifications');
  return notifications ? JSON.parse(notifications) : null;
};

export const clearNotificationsLocalStorage = (): void => {
  localStorage.removeItem('notifications');
  window.dispatchEvent(new Event('storage'));
};

export const setVmData = (vmData: VmStateInterface) => {
  localStorage.setItem('vmSelected', JSON.stringify(vmData));
  window.dispatchEvent(new Event('storage'));
};
export const getVmData = () => {
  const vmData = localStorage.getItem('vmSelected');
  return vmData ? JSON.parse(vmData) : null;
};
