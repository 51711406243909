import React, { useEffect } from 'react';
import { ConfigProvider } from 'antd';
import deDe from 'antd/lib/locale/de_DE';
import enUS from 'antd/lib/locale/en_US';
import esES from 'antd/lib/locale/es_ES';
import type { Locale } from 'antd/lib/locale-provider';
import GlobalStyle from './styles/GlobalStyle';
import 'typeface-montserrat';
import 'typeface-lato';
import { AppRouter } from './components/router/AppRouter';
import { useLanguage } from './hooks/useLanguage';
import { useAutoNightMode } from './hooks/useAutoNightMode';
import { usePWA } from './hooks/usePWA';
import { useThemeWatcher } from './hooks/useThemeWatcher';
import { useAppSelector } from './hooks/reduxHooks';
import { themeObject } from './styles/themes/themeVariables';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { selectUserLanguage } from './store/slices/userSlice';
import { shortcodeByLanguage } from './constants/languages';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
    },
  },
});

const App: React.FC = () => {
  const { language, setLanguage } = useLanguage();
  const theme = useAppSelector((state) => state.theme.theme);
  const userLanguage = useAppSelector(selectUserLanguage);

  usePWA();

  useAutoNightMode();

  useThemeWatcher();

  const getLanguageProvider = (): Locale => {
    if (language == 'es') {
      return esES;
    } else if (language == 'de') {
      return deDe;
    } else {
      return enUS;
    }
  };
  const languageProvider = getLanguageProvider();

  useEffect(() => {
    if (userLanguage) {
      setLanguage(shortcodeByLanguage[userLanguage]);
    }
  }, [userLanguage]);
  return (
    <>
      <meta name="theme-color" content={themeObject[theme].primary} />
      <GlobalStyle />
      <ConfigProvider locale={languageProvider}>
        <QueryClientProvider client={queryClient}>
          <AppRouter />
          {/* React Query devtools */}
          {/* <ReactQueryDevtools initialIsOpen={false} /> */}
        </QueryClientProvider>
      </ConfigProvider>
    </>
  );
};

export default App;
