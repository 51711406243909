import React from 'react';
import * as S from './TagNeon.styles';
import {
  detectFormatColor,
  getNumbersColorsFromRgb,
  getRgbaColorFromHex,
  hex3ToHex6,
  hexToRGB,
  hslaToRgbaWithNewTransparence,
  hslToHex,
} from '@app/utils/utils';
import { useAppSelector } from '@app/hooks/reduxHooks';

export interface ITag {
  id: string;
  title: string;
  bgColor: string;
}

interface TagProps {
  title: string;
  color?: string;
  bgColor?: string;
}

export const EmptyDefaultTagNeon: React.FC<TagProps> = ({
  title,
  color,
  bgColor,
  width,
  maxWidth,
  display,
  otherStyle,
  ...otherProps
}) => {
  const theme = useAppSelector((state) => state.theme.theme);

  const getRgbaColor = (color: string, trans: string): string => {
    const formatColor = detectFormatColor(color);
    if (formatColor == 'hex') {
      if (color.length == 4) {
        color = hex3ToHex6(color);
      }
      if (theme === 'dark' && color === '#000000') {
        color = '#ffffff';
      }
      if (theme === 'light' && color === '#ffffff') {
        color = '#000000';
      }
      return getRgbaColorFromHex(color, trans);
    }
    if (formatColor == 'rgb') {
      const rgbNumbers = getNumbersColorsFromRgb(color);
      if (rgbNumbers && rgbNumbers.length == 3) {
        return 'rgba(' + rgbNumbers[0] + ',' + rgbNumbers[1] + ',' + rgbNumbers[2] + ', ' + trans + ')';
      }
    }
    if (formatColor == 'rgba') {
      return color;
    }
    if (formatColor == 'hsl') {
      const hexColor = hslToHex(color);
      if (hexColor.length == 4) {
        hexColor = hex3ToHex6(hexColor);
      }
      if (theme === 'dark' && hexColor === '#000000') {
        hexColor = '#ffffff';
      }
      if (theme === 'light' && hexColor === '#ffffff') {
        hexColor = '#000000';
      }
      return getRgbaColorFromHex(hexColor, trans);
    }
    if (formatColor == 'hsla') {
      return hslaToRgbaWithNewTransparence(color, trans);
    }
    return 'rgba(0,0,0,' + trans + ')';
  };

  const style = {
    color: color ? getRgbaColor(color, '1') : getRgbaColor('#000000', '1'),
    border: color ? '1px solid ' + getRgbaColor(color, '0.7') : '1px solid ' + getRgbaColor('#000000', 0.7),
    backgroundColor: bgColor ? (color ? getRgbaColor(color, '0.1') : getRgbaColor('#000000', '0.1')) : 'transparent',
    width: width || 'fit-content',
    maxWidth: maxWidth || '100%',
    display: display || 'flex',
    ...otherStyle,
  };
  return (
    <S.TagWrapper style={style} {...otherProps}>
      {title || 'No data'}
    </S.TagWrapper>
  );
};
